/**
 * @param {*} num is Number
 */
const MAX_NUMBER_VALUE = 1000000;
const MAX_STRING_NUMBER = 3;
const abbreviateNumber = (num) => {
  let data = parseFloat(num / MAX_NUMBER_VALUE)
    .toFixed(2)
    .toString();
  const position = data.indexOf(".");
  data = data.substring(0, position + MAX_STRING_NUMBER).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return data + " mi";
};

/**
 * @param {*} num is Number
 * @param {*} fixed
 * @returns string was format
 */
export const fixedNumber = (n = 0, prefix = "", fixed = 2) => {
  const num = parseFloat(n);
  if (!num || num === "NaN" || num === 0 || num < 0.000000001) {
    return `${prefix}0.00`;
  }

  let precision = 3;
  if (num < 0.000001) {
    precision = 9;
    // This is temporary workaround because numeral is bad at handling small numbers
    return `${prefix}${parseFloat(num.toFixed(precision)).toFixed(precision)}`;
  }
  if (num < 0.001) {
    precision = 6;
    return `${prefix}${parseFloat(num.toFixed(precision)).toFixed(precision)}`;
  }
  if (num < 1) {
    return `${prefix}${parseFloat(num.toFixed(precision)).toFixed(precision)}`;
  }

  if (num >= MAX_NUMBER_VALUE) {
    return prefix + abbreviateNumber(num);
  }

  const position = num.toString().indexOf(".");
  if (position === -1) {
    return `${prefix}${roundNumber(num)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
  }

  return `${prefix}${roundNumber(num)
    .toFixed(fixed)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

/**
 * @param {*} number is Number
 * @param {*} scale
 * @param {*} isCheckMilion
 * @returns string was format
 */
export function roundNumber(n = 0, scale = 3) {
  const num = parseFloat(n);
  if (!("" + num).includes("e")) {
    return +(Math.floor(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.floor(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}
