import "./styles.scss";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Select } from "antd";
import { setLanguage } from "modules/application/redux/actions";

const { Option } = Select;

const HeaderMain = ({ intl, className, ...props }) => {
  const dispatch = useDispatch();
  const { OPTIONS_LANG, activeLanguage } = useSelector((state) => state.application);

  const handleChangeLang = (lang) => {
    dispatch(setLanguage({ language: lang }));
  };

  return (
    <div className={`lang ${className}`} {...props}>
      <Select
        // open
        defaultValue={activeLanguage}
        onChange={handleChangeLang}
        dropdownClassName="dropdownClassName"
        dropdownMatchSelectWidth={150}
      >
        {OPTIONS_LANG.map((item, index) => (
          <Option value={item.id} key={`language-${index}`}>
            <div
              className="image-flag"
              style={{
                backgroundImage: `url(${item.image})`,
              }}
            />
            <span className="text-flag">{item.label}</span>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default injectIntl(HeaderMain);
