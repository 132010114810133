import React from "react";
import { Card } from "antd";
import { injectIntl } from "react-intl";
import "./style.scss";
import { Link } from "react-router-dom";
import CurrencyFormart from "react-currency-format";
import { iOSDevice } from "helpers/CommonHelper";

const Index = ({ intl, icon, title, des, totalStake, totalReStatke, link, className = "" }) => {
  return (
    <>
      <Card className={`${className} card-item-current`}>
        <div className="wrapper-card-body">
          <div className="card-item-cover">
            {icon ? (
              <img className="card-item-img" alt="" src={icon} />
            ) : (
              <img className="card-item-img" alt="" src={require("assets/images/icon/icon-level.png")} />
            )}
            <div className="des">
              <span>{des}</span>
            </div>
          </div>
          <div className={`card-item-body ${iOSDevice() ? "card-ios" : ""}`}>
            {" "}
            <div className="card-referals-title1">
              <span>
                {title !== undefined
                  ? title
                  : intl.formatMessage({
                      id: "invest.card.title.currentPackage",
                    })}
              </span>
            </div>
            {link && link !== "" ? (
              <Link to={link} className="card-referals-title2">
                <span>
                  <CurrencyFormart value={totalStake || 0} displayType="text" thousandSeparator />
                </span>
              </Link>
            ) : (
              <div className="card-referals-title2">
                <span>
                  <CurrencyFormart value={totalStake || 0} displayType="text" thousandSeparator />
                </span>
              </div>
            )}
            <div className="card-referals-des mt-5">
              <span>
                <CurrencyFormart value={totalReStatke || 0} displayType="text" thousandSeparator />
                {` ${intl.formatMessage({
                  id: "restake",
                })}`}
              </span>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default injectIntl(Index);
