// import { JSB } from "commons/constants";
// import { USJ } from "modules/profile/redux/reducer";

export const FILTER_HISTORY_DEFAULT = [
  { value: "ALL", label: "All" },
  { value: "DEPOSIT", label: "DEPOSIT" },
  { value: "WITHDRAW", label: "WITHDRAW" },
  { value: "TRANSFER", label: "TRANSFER" },
  { value: "SWAP", label: "SWAP" },
  { value: "BUY", label: "BUY" },
  { value: "COMMISSION_STAKE", label: "COMMISSION STAKE" },
  { value: "BONUS_STAKE", label: "BONUS STAKE" },
  { value: "INTEREST_STAKE", label: "MINING" },
  // { value: "INVESTMENT", label: "Investments" },
  // { value: "COMMISSION_RESTAKE", label: "Commission Restake" },
  // { value: "RETURN", label: "Return" },
];
// const GLOBAL_BONUS = { value: "JSB_GLOBAL_BONUS", label: "Global bonus" };
const REVENUE = { value: "REVENUE", label: "Turn-over" };
const STOCK_BONUS = { value: "STOCK", label: "Stock bonus" };
const SWAP = { value: "SWAP", label: "SWAP" };
const EARN = { value: "EARN", label: "EARN" };

export const FILTER_HISTORY_MAPPING = {
  // [JSB]: [GLOBAL_BONUS],
  // [USJ]: [REVENUE, SWAP, EARN],
};
