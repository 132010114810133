export const CONFIG_FIREBASE = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_NAME,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const NODE_HISTORIRES_INVEST = "histories_invest";
export const NODE_TRANDING_PRICE = "trading_price";
export const NODE_TRANDING_PRICE_USJ = "trading_price_usj";
export const NODE_TRANDING_PRICE_JSB = "jsb_price_header";
export const NODE_TRANDING_PRICE_HEADER = "list_price_header";
