import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Row, Col } from "antd";
import "./styles.scss";
import TreeVanilla from "modules/networks/components/TreeVanilla";
import WarningSteak from "modules/networks/components/WarningSteak";
import CarouselBinary from "../../components/CarouselBinary";
import AutoCompleteCustom from "../../components/AutoComplete";

const Index = () => {
  const [toggleToLeftToRight, setToggleToLeftToRight] = useState(false);
  const handleOnSuccessToLeftToRight = () => {
    setToggleToLeftToRight(!toggleToLeftToRight);
  };
  const [keyword, setKeyWord] = useState("haha");

  return (
    <section className="your-binary">
      <Row gutter={30}>
        <WarningSteak />
      </Row>
      <Row gutter={30}>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <CarouselBinary handleOnSuccessToLeftToRight={handleOnSuccessToLeftToRight} />
          <TreeVanilla isBinarySponor={true} toggleToLeftToRight={toggleToLeftToRight} keyword={keyword} />
        </Col>
      </Row>
    </section>
  );
};

export default injectIntl(Index);
