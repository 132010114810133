import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import Carousel from "react-elastic-carousel";
import { get } from "lodash";
import "./styles.scss";
import CardDetailPackage from "commons/components/CardDetailPackage";
import WrapperModal from "commons/components/ModalCommons/WrapperModal";
import { getProfile } from "modules/profile/redux/actions";
import { getListPackages, buyPackage } from "../../redux/actions";
import { openGenerateCodeModal } from "modules/package/redux/actions";

const PackagesCarousel = ({ onReLoadData }) => {
  const dispatch = useDispatch();
  const [viewModalBuy, setViewModalBuy] = useState({ toggle: false, data: null });
  const { packageList } = useSelector((state) => state.package);

  useEffect(() => {
    dispatch(getListPackages());
  }, [dispatch]);

  // const onChange = (currentItemObject, currentPageIndex) => {};

  const onBuyNow = (item) => {
    setViewModalBuy({ toggle: true, data: item });
  };

  const handleBuyConfirm = (packageItem) => {
    dispatch(
      buyPackage(
        { product_id: get(packageItem, "id") },
        (result) => {
          dispatch(getProfile());
          dispatch(getListPackages());
          if (get(packageItem, "id") !== 1) {
            dispatch(openGenerateCodeModal({ toggle: true, data: result }));
          }
          setViewModalBuy({ toggle: false, data: null });
          onReLoadData();
        },
        () => {
          setViewModalBuy({ toggle: false, data: null });
        }
      )
    );
  };

  return (
    <div className="package-carousel-list">
      <Carousel
        // enableAutoPlay
        autoPlaySpeed={3000}
        itemsToShow={7}
        showEmptySlots
        renderArrow={() => <div></div>}
        // isRTL={true} // flip right to left
        showArrows={false}
        enableTilt={true}
        // onChange={onChange}
        outerSpacing={40}
        breakPoints={[
          { width: 1, itemsToShow: 2, itemPadding: [0, 6, 0, 0] },
          { width: 450, itemsToShow: 3, itemPadding: [0, 10, 0, 0] },
          { width: 550, itemsToShow: 4, itemPadding: [0, 10, 0, 0] },
          { width: 650, itemsToShow: 5, itemPadding: [0, 10, 0, 0] },
          { width: 900, itemsToShow: 6, itemPadding: [0, 15, 0, 0] },
          { width: 950, itemsToShow: 7, itemPadding: [0, 15, 0, 0] },
          { width: 1150, itemsToShow: 8, itemPadding: [0, 15, 0, 0] },
          { width: 1450, itemsToShow: 9, itemPadding: [0, 15, 0, 0] },
          { width: 1750, itemsToShow: 10, itemPadding: [0, 15, 0, 0] },
          // { width: 1920, itemsToShow: 12, itemPadding: [0, 20, 0, 0], itemsToScroll: 1 },
        ]}
      >
        {get(packageList, "data", []).map((ele, index) => {
          return (
            <div key={"detail-package-" + index} className="package-carousel-item">
              <CardDetailPackage index={index} source={ele} onBuyNow={onBuyNow} />
            </div>
          );
        })}
      </Carousel>
      <WrapperModal
        destroyOnClose
        className="modal-buy-package"
        visible={viewModalBuy.toggle}
        title={<FormattedMessage id="modal.buyPackage" />}
        handleOk={() => handleBuyConfirm(viewModalBuy.data)}
        handleCancel={() => setViewModalBuy({ toggle: false, data: null })}
      >
        <div className="modal-package-detail">
          <div>
            <FormattedMessage id="modal.buy.PackageName" />:
          </div>
          <div>{viewModalBuy.data?.title || ""}</div>
        </div>
        <div className="modal-package-detail">
          <div>
            <FormattedMessage id="modal.buy.toPay" />:
          </div>
          <div>{viewModalBuy.data?.price || "-"}&nbsp;EUR</div>
        </div>
        {/* <div className="modal-package-detail">
          <div>
            <FormattedMessage id="modal.buy.activeNote" />:
          </div>
          <div>{viewModalBuy.data?.fee || "10"}</div>
        </div> */}
      </WrapperModal>
    </div>
  );
};

export default injectIntl(memo(PackagesCarousel));
