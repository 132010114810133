import * as types from "./constants";

export const getListInvest = (data, callbackSuccess) => ({
  type: types.GET_LIST_INVEST,
  data,
  callbackSuccess,
});

export const setListInvestSuccess = (payload) => {
  return {
    type: types.GET_LIST_INVEST_SUCCESS,
    payload,
  };
};

export const getInvestHistory = (payload) => ({
  type: types.GET_LIST_INVEST_HISTORY,
  payload,
});

export const getListInvestHistorySuccess = (payload) => {
  return {
    type: types.GET_LIST_INVEST_HISTORY_SUCCESS,
    payload,
  };
};
export const getListPackageUpgrade = (data, callbackSuccess) => ({
  type: types.GET_LIST_PACKAGE_UPGRAGE,
  data,
  callbackSuccess,
});

export const setListPackageUpgradeSuccess = (payload) => ({
  type: types.GET_LIST_PACKAGE_UPGRAGE_SUCCESS,
  payload,
});

export const updatePackageInvest = (payload, callbackSuccess) => ({
  type: types.UPDATE_PACKAGE_INVEST,
  payload,
  callbackSuccess,
});

export const getReviewTransaction = (payload) => ({
  type: types.GET_REVIEW_CONFIRM_TRANSACTION,
  payload,
});

export const getReviewTransactionSuccess = (payload) => ({
  type: types.GET_REVIEW_CONFIRM_TRANSACTION_SUCCESS,
  payload,
});

// New
export const stakeNow = (payload, callback) => ({
  type: types.POST_STAKE_NOW,
  payload,
  callback,
});
export const reStake = (payload, callback) => ({
  type: types.RESTAKE_NOW,
  payload,
  callback,
});

export const getStakeHistory = (payload) => ({
  type: types.GET_STAKE_HISTORY,
  payload,
});
export const getStakeHistorySuccess = (payload) => ({
  type: types.GET_STAKE_HISTORY_SUCCESS,
  payload,
});

export const getInterestHistory = (payload) => ({
  type: types.GET_INTEREST_HISTORY,
  payload,
});
export const getInterestHistorySuccess = (payload) => ({
  type: types.GET_INTEREST_HISTORY_SUCCESS,
  payload,
});
