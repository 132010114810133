export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SIGN_UP = "SIGN_UP";
export const ACTIVE_ACCOUNT = "ACTIVE_ACCOUNT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const UPDATED_AVATAR = "UPDATED_AVATAR";

export const TOGGLE_AUTHY_MODAL = "TOGGLE_AUTHY_MODAL";
export const UPDATE_AUTHY_VERIFY = "UPDATE_AUTHY_VERIFY";

export const GET_PROFILE = "GET_PROFILE";
