import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { injectIntl } from "react-intl";

import { ROUTE } from "commons/constants";
import useQuery from "helpers/useQuery";
import { verifySponsor } from "modules/networks/redux/actions";

const VerifySponsorPage = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    const binCode = query.get("sponsorKey") || query.get("sponsorkey");
    if (binCode) {
      dispatch(
        verifySponsor(
          {
            bin_code: binCode,
          },
          () => {
            history.push(ROUTE.HOME);
          }
        )
      );
    } else {
      history.push(ROUTE.HOME);
    }
  }, [dispatch, query, history]);

  return <></>;
};

export default injectIntl(VerifySponsorPage);
