import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { Row, Col } from "antd";
import { get } from "lodash";
import "./style.scss";
import HeaderPage from "commons/components/HeaderPage";
import CardDetailStats from "commons/components/CardDetailStats";
import ListPackageActive from "modules/package/components/ListPackageActive";
import ReferalsCard from "commons/components/CardCommons/ReferalsCard";
import ModalActiveCode from "commons/components/ModalCommons/ModalActiveCode";
import SuccessModal from "commons/components/ModalCommons/SuccessModal";
import ErrorModal from "commons/components/ModalCommons/ErrorModal";
import { CACHING_DATA, USDTTRON } from "commons/constants";
import { renderLogo } from "helpers/Ranks";
import { getTotalInfo } from "../redux/actions";
import MarqueeTokenBalance from "commons/components/MarqueeTokenBalance";

const TOOGLE_CALLBACK_GET_LIST = "TOOGLE_CALLBACK_GET_LIST";
CACHING_DATA[TOOGLE_CALLBACK_GET_LIST] = false;
const setToogleCallBack = () => {
  return (CACHING_DATA[TOOGLE_CALLBACK_GET_LIST] = !CACHING_DATA[TOOGLE_CALLBACK_GET_LIST]);
};

const Index = ({ intl }) => {
  const dispatch = useDispatch();
  let toggleCallBackGetList = CACHING_DATA[TOOGLE_CALLBACK_GET_LIST];
  const userInfo = useSelector((state) => state.profile.profileData);
  const { totalInfo } = useSelector((state) => state.dashboard);
  const [currencyData] = useState(USDTTRON);

  useEffect(() => {
    dispatch(getTotalInfo());
  }, [dispatch]);

  const data = [
    {
      title: intl.formatMessage({ id: "dashboard.statistic.LEVEL" }),
      description: intl.formatMessage({ id: "dashboard.statistic.your_level" }),
      balance: `LV ${get(userInfo, "levelInfoCommissions.level", 0)}`,
      level: `${get(userInfo, "levelInfoAchievement.level", 0)}`,
      levelPackageBuy: `${get(userInfo, "levelInfoPackage.level", 0)}`,
      icon: renderLogo(get(userInfo, "levelInfoCommissions.level", 0) ? get(userInfo, "levelInfoCommissions.level", 0) : 1),
      link: true,
    },
    {
      title: intl.formatMessage({ id: "dashboard.statistic.BONUS" }),
      description: intl.formatMessage({ id: "dashboard.statistic.from_commission" }),
      balance: get(totalInfo, "totalCommission", 0),
      icon: require("assets/images/icon/square-2.svg"),
    },
    {
      title: intl.formatMessage({ id: "dashboard.statistic.Package" }),
      description: intl.formatMessage({ id: "dashboard.statistic.total_buy" }),
      balance: get(totalInfo, "totalRevenue", 0),
      icon: require("assets/images/icon/square-3-4.svg"),
    },
    {
      title: intl.formatMessage({ id: "dashboard.statistic.activated" }),
      description: intl.formatMessage({ id: "dashboard.statistic.total_active_package" }),
      balance: get(totalInfo, "totalRevenueActive", 0),
      icon: require("assets/images/icon/square-3-4.svg"),
    },
    {
      title: intl.formatMessage({ id: "dashboard.statistic.MINING" }),
      description: intl.formatMessage({ id: "dashboard.statistic.from_your_active" }),
      balance: get(totalInfo, "totalHarvest", 0),
      icon: require("assets/images/icon/square-5.svg"),
    },
    {
      title: intl.formatMessage({ id: "dashboard.statistic.LEFT" }),
      description: intl.formatMessage({ id: "dashboard.statistic.total_volume_left" }),
      balance: get(totalInfo, "totalRevenueLeftRight.left", 0),
      icon: require("assets/images/icon/square-6.svg"),
    },
    {
      title: intl.formatMessage({ id: "dashboard.statistic.RIGHT" }),
      description: intl.formatMessage({ id: "dashboard.statistic.total_volume_right" }),
      balance: get(totalInfo, "totalRevenueLeftRight.right", 0),
      icon: require("assets/images/icon/square-7.svg"),
    },
  ];

  return (
    <div className="dashboard-container">
      <HeaderPage title={<FormattedMessage id="page.title.dashboard" />} />
      <Row gutter={[0, 40]}>
        <Col span={24}>
          <Row
            gutter={[
              { xs: 10, sm: 20 },
              { xs: 10, sm: 20 },
            ]}
          >
            {data.map((ele, index) => {
              return (
                <Col key={"detail-start-" + index} className="ant-col-custom-xl-7" lg={4} md={6} sm={8} xs={12}>
                  <CardDetailStats index={index} source={ele} />
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col span={24}>
          <ReferalsCard />
        </Col>
        <Col span={24}>
          <ListPackageActive showFilter={false} showActiveCode={true} currency={currencyData} isCall={toggleCallBackGetList} />
        </Col>
      </Row>
      <ModalActiveCode onLoadListPackagesActive={setToogleCallBack} />
      <SuccessModal />
      <ErrorModal />
      <MarqueeTokenBalance />
    </div>
  );
};

export default injectIntl(Index);
