/* eslint-disable */
import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./TableProfit.scss";
import RightModal from "commons/components/ModalCommons/rightModal";
import TableProfit from "./TableProfit";
import { onOpenDrawerProfit } from "modules/package/redux/actions";

const UserHistoryModal = () => {
  const dispatch = useDispatch();
  const { toggle } = useSelector((state) => state.package.openDrawerProfit);
  const { innerWidth: width } = window;
  return (
    <div className="pool-list-pool">
      <RightModal
        isShow={toggle}
        width={width < 768 ? "100%" : "75%"}
        padding={width < 768 ? "20px 15px 20px 15px" : "20px 35px 20px 50px"}
        handleClose={() => dispatch(onOpenDrawerProfit({ toggle: false, data: null }))}
        customContainerClass={"pool-detail-modal"}
      >
        <TableProfit />
      </RightModal>
    </div>
  );
};

export default injectIntl(UserHistoryModal);
