import "./styles.scss";
import React from "react";
import { injectIntl } from "react-intl";
import { MenuOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import Ring from "./Ring";
import TokenBalance from "./TokenBalance";
import ProfileAccount from "commons/components/ProfileAccount";

const { Header } = Layout;

const HeaderMain = ({ toggleSlider, visible, isMobile }) => {
  const onToggleSideBar = () => {
    toggleSlider();
  };

  return (
    <Header className="header-container">
      <div className="content-header">
        <div className="col-left w-100">
          <div className="menu-collapse-icon">
            {isMobile && <MenuOutlined onClick={onToggleSideBar} className="icon ml-10" />}
          </div>
        </div>
        <div className="col-right">
          <TokenBalance />
          <Ring />
          {!isMobile && <ProfileAccount className="custom-account" isMobile={isMobile} />}
        </div>
      </div>
    </Header>
  );
};

export default injectIntl(HeaderMain);
