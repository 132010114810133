/* eslint-disable */
import "./styles.scss";
import React from "react";
import { injectIntl } from "react-intl";
import { get } from "lodash";

// import RightModal from "commons/components/ModalDrawer/rightModal";
// import InterestHistory from "commons/components/TableCommon/InterestHistory";

const HistoryDetail = ({ intl, data = {}, isShow = false, handleClose = () => {}, params = {}, des }) => {
  const { innerWidth } = window;

  return "dsadas";
  // <RightModal
  //   width={innerWidth < 475 ? "100%" : "75%"}
  //   padding={0}
  //   isShow={isShow}
  //   handleClose={handleClose}
  //   customContainerClass={"table-history-interest"}
  // >
  //   <InterestHistory type={get(params, "type", "ALL")} currency={get(params, "currency", "USJ")} des={des} />
  // </RightModal>
};

export default injectIntl(HistoryDetail);
