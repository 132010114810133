import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { Row, Col } from "antd";
import { get } from "lodash";

import { roundNumber } from "helpers/numbers";
import { viewWalletDetail } from "modules/wallets/redux/actions";
import { MODAL_TYPES } from "commons/constants";
import { DEFAULT_SELECT_WALLET } from "commons/constants";
import { getInterestHistory } from "modules/staking/redux/actions";
import StakeHistory from "../components/StakeHistory";
import CurrentCard from "commons/components/CardCommons/CurrentCard";
import StakeButtonCard from "commons/components/CardCommons/StakeButtonCard";
import HistoryInterest from "../components/HistoryInterest";

const StakingPage = ({ intl }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.profile.profileData);
  const { infoStake, listStake, totalStats } = useSelector((state) => state.application);
  const { listInterestHis } = useSelector((state) => state.staking);
  const [showInterestHis, setShowInterestHis] = useState(false);
  const [des, setDes] = useState(null);

  const [searchParams] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  useEffect(() => {
    dispatch(getInterestHistory(searchParams));
  }, [dispatch, searchParams]);

  //=> Handle days pass and end day.
  useEffect(() => {
    const dateStartLockStake = get(userInfo, "start_lock_stake", null);
    const numberUnlockStake = get(userInfo, "number_unlock_stake", null);
    const totalInterest = get(listInterestHis, "pagination.total", 0);

    if (dateStartLockStake && numberUnlockStake) {
      const timeStampUnStake = new Date(dateStartLockStake).setDate(+new Date(dateStartLockStake).getDate() + +numberUnlockStake);

      //Day end
      const yearEnd = +new Date(timeStampUnStake).getFullYear();
      let monthEnd = +new Date(timeStampUnStake).getMonth() + 1;
      monthEnd = monthEnd < 10 ? "0" + monthEnd : monthEnd;
      let dateEnd = +new Date(timeStampUnStake).getDate();
      dateEnd = dateEnd < 10 ? "0" + dateEnd : dateEnd;

      setDes(
        `${totalInterest} ${intl.formatMessage({
          id: "pay pass, and end at",
        })} ${yearEnd}/${monthEnd}/${dateEnd}`
      );
    } else {
      setDes(
        `${totalInterest} ${intl.formatMessage({
          id: "pay pass",
        })}`
      );
    }
  }, [intl, userInfo, listInterestHis]);
  //<= End

  const itemCurrentPacage = [
    {
      id: 1,
      title: intl.formatMessage({ id: "total.stake" }),
      totalStake: roundNumber(userInfo.invest_stake || 0),
      totalReStatke: roundNumber(totalStats?.totalReStake || 0),
      des: des,
      icon: false,
      link: "",
      isShow: true,
    },
  ];

  const { wallets } = useSelector((state) => ({
    wallets: get(state, "profile.profileData.walletListFilter", []),
  }));

  const handleStake = () => {
    const wallet = selectWalet(DEFAULT_SELECT_WALLET);
    if (wallet === false) return;

    dispatch(
      viewWalletDetail({
        type: MODAL_TYPES.stake,
        data: {
          title: intl.formatMessage({ id: "Stake.Now" }),
          wallet: wallet,
          currentStake: get(userInfo, "invest_stake", 0),
          newStakeAmount: 0,
          infoStake: infoStake,
          listStake: listStake,
        },
      })
    );
  };

  const handleReStake = () => {
    const wallet = selectWalet(DEFAULT_SELECT_WALLET);
    if (wallet === false) return;

    dispatch(
      viewWalletDetail({
        type: MODAL_TYPES.reStake,
        data: {
          title: intl.formatMessage({ id: "Re-Stake" }),
          wallet: wallet,
          currentStake: get(userInfo, "invest_stake", 0),
          newStakeAmount: 0,
          infoStake: infoStake,
          listStake: listStake,
        },
      })
    );
  };

  const selectWalet = (unit) => {
    const selectWalet = wallets.filter((ele) => ele.unit === unit);

    if (selectWalet.length < 1) {
      return false;
    }

    return selectWalet[0];
  };

  const handleInterest = () => {
    setShowInterestHis(true);
  };

  return (
    <>
      <div className="stake-page">
        <Row className="stake-list" gutter={[25, 25]} justify="center">
          <Col xl={24} lg={24} xs={24} sm={24}>
            <h2 className="pega-content-title">
              <FormattedMessage id="menu.staking" />
            </h2>
          </Col>
          {itemCurrentPacage.map(
            (item, key) =>
              item.isShow && (
                <Col key={key} sm={12} xs={24}>
                  <CurrentCard {...item} />
                </Col>
              )
          )}
          <Col sm={12} xs={24}>
            {parseFloat(userInfo?.invest_stake || 0) > 0 ? (
              <StakeButtonCard
                title={intl.formatMessage({ id: "Re-Stake" })}
                onClick={handleReStake}
                handleInterest={handleInterest}
              />
            ) : (
              <StakeButtonCard
                title={intl.formatMessage({ id: "Stake.Now" })}
                onClick={handleStake}
                handleInterest={handleInterest}
              />
            )}
          </Col>
          <Col span={24}>
            <div className="stake-history-list">
              <StakeHistory />
            </div>
          </Col>
        </Row>
      </div>

      <HistoryInterest isShow={showInterestHis} handleClose={() => setShowInterestHis(false)} des={des} />
    </>
  );
};

export default injectIntl(StakingPage);
