import { all, call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";
import * as qs from "query-string";

import { ROOT_API_URL } from "commons/constants";
import fetchHelper from "helpers/FetchHelper";

import { GET_COMMISSION_LIST } from "./constants";
import { getCommissionListSuccess } from "./actions";

function* getCommissionList({ payload }) {
  const res = yield call(requestCommissionFromApi, payload);
  if (get(res, "status") === 200) {
    const data = get(res, "data", []);
    yield put(getCommissionListSuccess(data));
  }
}

function requestCommissionFromApi(payload) {
  const qsString = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/commissions/list?${qsString}`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

export default function* root() {
  yield all([takeLatest(GET_COMMISSION_LIST, getCommissionList)]);
}
