import { GET_COMMISSION_LIST, GET_COMMISSION_LIST_SUCCESS } from "./constants";

export const getCommissionList = (payload) => {
  return {
    type: GET_COMMISSION_LIST,
    payload,
  };
};
export const getCommissionListSuccess = (payload) => {
  return {
    type: GET_COMMISSION_LIST_SUCCESS,
    payload,
  };
};
