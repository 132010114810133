import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { get } from "lodash";
import { Form, Input } from "antd";
import "./styles.scss";
import WrapperCoppy from "commons/components/WrapperCoppy";
import WrapperModal from "commons/components/ModalCommons/WrapperModal";
import { openShareCodeModal, sharePackageCode } from "../../redux/actions";
import { regexEmail } from "modules/auth/pages/forgot";
import { submitActiveCode } from "modules/application/redux/actions";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";

const ModalGeneratePackageCode = ({ onReLoadData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { toggle, data } = useSelector((state) => state.package.modalSharePackageCode);

  const onSendEmail = () => {
    form.validateFields().then(({ email }) => {
      dispatch(
        sharePackageCode(
          { email, order_id: get(data, "id") },
          () => {
            dispatch(openShareCodeModal({ toggle: false, data: null }));
          },
          () => {
            dispatch(openShareCodeModal({ toggle: false, data: null }));
          }
        )
      );
    });
  };

  const handleActiveForMySelf = () => {
    executeRecaptcha((token_captcha) => {
      dispatch(
        submitActiveCode(
          {
            ...data,
            token_captcha,
          },
          (result) => {
            dispatch(openShareCodeModal({ toggle: false, data: null }));
            window.open(result.data.path_contract, "_blank");
            onReLoadData();
          }
        )
      );
    });
  };

  return (
    <WrapperModal
      destroyOnClose
      maskClosable={false}
      className="modal-share-package"
      visible={toggle}
      // visible
      title={<FormattedMessage id="modal.share.code.title" />}
      handleOk={handleActiveForMySelf}
      handleCancel={() => dispatch(openShareCodeModal({ toggle: false, data: null }))}
      footer="Active for myself!"
    >
      <div className="modal-share-content">
        <div style={{ color: "#002748" }}>
          <FormattedMessage id="modal.shareCode.note.1" />
        </div>
        <div className="d-flex space-between mt-5">
          <div style={{ color: "#047AE2" }}>
            <FormattedMessage id="modal.shareCode.sharedCode" />:
          </div>
          <div className="pointer" style={{ color: "#047AE2" }}>
            <WrapperCoppy onCopy={() => undefined} value={data?.public_code}>
              <>
                {data?.public_code || "--"}
                {/* <img className="ml-10" src={require("assets/images/icon/icon-copy.svg")} alt="" width={20} />{" "} */}
              </>
            </WrapperCoppy>
          </div>
        </div>
        <div className="d-flex space-between mt-5 mb-40">
          <div style={{ color: "#047AE2" }}>
            <FormattedMessage id="modal.shareCode.privateCode" />:
          </div>
          <div className="pointer" style={{ color: "#047AE2" }}>
            <WrapperCoppy onCopy={() => undefined} value={data?.private_code}>
              <>
                {data?.private_code || "--"}
                {/* <img className="ml-10" src={require("assets/images/icon/icon-copy.svg")} alt="" width={20} />{" "} */}
              </>
            </WrapperCoppy>
          </div>
        </div>
        <div className="mt-20" style={{ color: "#002748" }}>
          <FormattedMessage id="modal.shareCode.note.2" />:
        </div>
        <div className="modal-share-form mt-10 mb-10">
          <Form form={form}>
            <Form.Item
              label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Required email",
                },
                {
                  pattern: regexEmail(),
                  message: <FormattedMessage id="emailWrong" />,
                },
              ]}
            >
              <Input
                size="small"
                suffix={
                  <img
                    className="pointer"
                    onClick={onSendEmail}
                    src={require("assets/images/icon/fly-send.svg")}
                    width={26}
                    alt=""
                  />
                }
                placeholder="your_friend@email.com"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </WrapperModal>
  );
};

export default injectIntl(ModalGeneratePackageCode);
