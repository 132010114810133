export const GET_LIST_INVEST = "GET_LIST_INVEST";
export const GET_LIST_INVEST_SUCCESS = "GET_LIST_INVEST_SUCCESS";
export const GET_LIST_INVEST_HISTORY = "GET_LIST_INVEST_HISTORY";
export const GET_LIST_INVEST_HISTORY_SUCCESS = "GET_LIST_INVEST_HISTORY_SUCCESS";
export const GET_LIST_PACKAGE_UPGRAGE = "GET_LIST_PACKAGE_UPGRAGE";
export const GET_LIST_PACKAGE_UPGRAGE_SUCCESS = "GET_LIST_PACKAGE_UPGRAGE_SUCCESS";

export const UPDATE_PACKAGE_INVEST = "UPDATE_PACKAGE_INVEST";
export const GET_REVIEW_CONFIRM_TRANSACTION = "GET_REVIEW_CONFIRM_TRANSACTION";
export const GET_REVIEW_CONFIRM_TRANSACTION_SUCCESS = "GET_REVIEW_CONFIRM_TRANSACTION_SUCCESS";

// new
export const GET_STAKE_HISTORY = "GET_STAKE_HISTORY";
export const GET_STAKE_HISTORY_SUCCESS = "GET_STAKE_HISTORY_SUCCESS";

export const POST_STAKE_NOW = "POST_STAKE_NOW";
export const RESTAKE_NOW = "RESTAKE_NOW";

export const GET_INTEREST_HISTORY = "GET_INTEREST_HISTORY";
export const GET_INTEREST_HISTORY_SUCCESS = "GET_INTEREST_HISTORY_SUCCESS";
