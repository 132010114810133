export const CONFIRM_CURRENCY = "CONFIRM_CURRENCY";
export const GET_ALL_DETAIL_CHART = "GET_ALL_DETAIL_CHART";

export const GET_TOTAL_PV = "GET_TOTAL_PV";
export const GET_TOTAL_PV_SUCCESS = "GET_TOTAL_PV_SUCCESS";

export const GET_TOTAL_PUNK = "GET_TOTAL_PUNK";
export const GET_TOTAL_PUNK_SUCCESS = "GET_TOTAL_PUNK_SUCCESS";

export const GET_USJ_INVEST = "GET_USJ_INVEST";
export const GET_USJ_INVEST_SUCCESS = "GET_USJ_INVEST_SUCCESS";

export const EXCHANGE_CAL_RATE = "EXCHANGE_CAL_RATE";

export const GET_HISTORY_INVEST = "GET_HISTORY_INVEST";
export const GET_HISTORY_INVEST_SUCCESS = "GET_HISTORY_INVEST_SUCCESS";
export const APPEND_HISTORY_INVEST = "APPEND_HISTORY_INVEST";

export const GET_SETTING_ALL = "GET_SETTING_ALL";
export const GET_SETTING_ALL_SUCCESS = "GET_SETTING_ALL_SUCCESS";

export const SET_SHOWMODAL_NEW = "SET_SHOWMODAL_NEW";

//New

export const GET_LIST_TRADE_EXCHANGE = "GET_LIST_TRADE_EXCHANGE";
export const GET_LIST_TRADE_EXCHANGE_SUCCESS = "GET_LIST_TRADE_EXCHANGE_SUCCESS";

export const ACTIVE_AGENT = "ACTIVE_AGENT";
export const GET_LIST_STAKE_DASHBOARD = "GET_LIST_STAKE_DASHBOARD";

export const GET_RATE = "GET_RATE";
export const GET_RATE_SUCCESS = "GET_RATE_SUCCESS";

export const WITHDRAW = "WITHDRAW";

export const GET_TOTAL_INFO = "GET_TOTAL_INFO";
export const GET_TOTAL_INFO_SUCCESS = "GET_TOTAL_INFO_SUCCESS";
