import React, { useEffect, useState, useMemo } from "react";
import { Card, Form, Input, Button, Select } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./styles.scss";
import WrapperAuth from "../Wrapper";
import { ROUTE } from "commons/constants";
import * as actions from "modules/auth/redux/actions";
import { regexEmail } from "../forgot";
import { COUNTRY_LIST } from "commons/constants/countries";

const { Option } = Select;

const SignUpPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const url = new URLSearchParams(location.search);
  const sponsorKey = url.get("sponsorKey") || url.get("sponsorkey");

  useEffect(() => {
    if (!sponsorKey) return () => {};
    setFieldsValue({
      sponsorKey: sponsorKey.toLowerCase(),
    });
  }, [url, sponsorKey, setFieldsValue]);

  const isDisable = sponsorKey ? true : false;

  const onFinish = (values) => {
    const { password, confirm_password, phone_number, phone_code } = values;
    if (!password || !confirm_password) return;
    if (password !== confirm_password) return;
    delete values.confirm_password;
    values = {
      ...values,
      phone_number: phone_number ? phone_code + phone_number : "",
    };
    dispatch(
      actions.postSignup(values, () => {
        history.push(ROUTE.LOGIN);
      })
    );
  };

  const optionCountry = useMemo(() => {
    return COUNTRY_LIST.map((country) => ({
      label: country?.name || "",
      value: country?.name || "",
      code: country?.dialCode || "",
      isoCode: country?.isoCode || "",
    }));
  }, []);

  return (
    <WrapperAuth>
      <div className="register-container">
        <div className="register-box-container">
          <Card title={<FormattedMessage id="auth.signup.modal.title" />} className="card-register-custom">
            <Form form={form} name="normal_register" className="register-form" onFinish={onFinish}>
              {/* ---------------------------- */}
              <label className="input-label">
                <FormattedMessage id="auth.signup.modal.input.field.usernname" />
                <span className="input-mark">
                  <FormattedMessage id="auth.signin.modal.required.mark" />
                </span>
              </label>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="auth.signin.modal.empty.message.username" />,
                  },
                ]}
                // hasFeedback
              >
                <Input className="input-field" />
              </Form.Item>

              {/* first_name */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "45%" }}>
                  <label className="input-label">
                    <FormattedMessage id="auth.signup.modal.input.field.label.firstname" />
                    <span className="input-mark">
                      <FormattedMessage id="auth.signin.modal.required.mark" />
                    </span>
                  </label>
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="auth.signup.modal.empty.message.firstname" />,
                      },
                    ]}
                  >
                    <Input className="input-field-label" />
                  </Form.Item>
                </div>
                <div style={{ width: "45%" }}>
                  <label className="input-label">
                    <FormattedMessage id="auth.signup.modal.input.field.label.lastname" />
                    <span className="input-mark">
                      <FormattedMessage id="auth.signin.modal.required.mark" />
                    </span>
                  </label>
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="auth.signup.modal.empty.message.lastname" />,
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </div>
              </div>
              {/* email */}
              <label className="input-label">
                <FormattedMessage id="auth.signup.modal.input.field.label.email" />
                <span className="input-mark">
                  <FormattedMessage id="auth.signin.modal.required.mark" />
                </span>
              </label>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="auth.signin.modal.empty.message.email" />,
                  },
                  {
                    pattern: regexEmail(),
                    message: <FormattedMessage id="emailWrong" />,
                  },
                ]}
                hasFeedback
              >
                <Input className="input-field" />
              </Form.Item>
              {/* password */}
              <label className="input-label">
                <FormattedMessage id="auth.signup.modal.input.field.label.password" />
                <span className="input-mark">
                  <FormattedMessage id="auth.signin.modal.required.mark" />
                </span>
              </label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="auth.signup.modal.empty.message.password" />,
                  },
                ]}
              >
                <Input.Password
                  className="input-field"
                  type="password"
                  suffix={<img src={require("assets/images/icon/eye.png")} width={20} alt="" />}
                />
              </Form.Item>

              {/* confirm_password */}
              <label className="input-label">
                <FormattedMessage id="auth.signup.modal.input.field.label.confirm.password" />
                <span className="input-mark">
                  <FormattedMessage id="auth.signin.modal.required.mark" />
                </span>
              </label>
              <Form.Item
                name="confirm_password"
                dependencies={["password"]}
                // hasFeedback
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="auth.signup.modal.empty.message.confirm.password" />,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The two passwords that you entered do not match!"));
                    },
                  }),
                ]}
              >
                <Input.Password className="input-field" />
              </Form.Item>

              {/* country */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "45%" }}>
                  <label className="input-label">
                    <FormattedMessage id="setting.profile.label.country" />
                  </label>
                  <Form.Item name="country" rules={[{ whitespace: true }]}>
                    <Select optionLabelProp="label" style={{ width: "100%" }}>
                      {optionCountry.map(({ label, value }, index) => (
                        <Option key={index} label={label} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                {/* phone_number */}
                <div style={{ width: "45%" }}>
                  <label className="input-label">
                    <FormattedMessage id="auth.signup.modal.input.field.label.mobile" />
                  </label>
                  <Form.Item
                    name="phone_number"
                    rules={[
                      { whitespace: true },
                      // {
                      //   pattern: regexNumber(),
                      //   message: <FormattedMessage id="mobileWrong" />,
                      // },
                    ]}
                  >
                    <Input
                      addonBefore={
                        <Form.Item name="phone_code" noStyle initialValue="+31">
                          <Select style={{ width: "fit-content" }}>
                            {optionCountry.map(({ code }, index) => (
                              <Option key={index} label={code} value={code}>
                                {`+${code}`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      }
                      className="input-field"
                    />
                  </Form.Item>
                </div>
              </div>

              {/* sponsorKey */}
              <label className="input-label">
                <FormattedMessage id="auth.signup.modal.input.field.label.referral" />
                <span className="input-mark">
                  <FormattedMessage id="auth.signin.modal.required.mark" />
                </span>
              </label>
              <Form.Item
                name="sponsorKey"
                rules={[
                  {
                    required: true,
                    message: "Please enter referral",
                  },
                ]}
              >
                <Input className="input-field" disabled={isDisable} />
              </Form.Item>

              {/* action */}
              <Form.Item className="action">
                <Button size="middle" type="primary" htmlType="submit" className="primary-button">
                  <FormattedMessage id="auth.signin.modal.button.signup.label" />
                </Button>
              </Form.Item>

              {/* Link */}
              <Form.Item className="link-href">
                <Link className="forgot-button" to={`${ROUTE.FORGOT_PASSWORD}`}>
                  <FormattedMessage id="auth.signin.modal.forgot.password.label" />
                </Link>
                <Link className="sign-up-link" to={`${ROUTE.LOGIN}`}>
                  <FormattedMessage id="auth.signin.modal.login.label" />
                </Link>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </WrapperAuth>
  );
};

export default SignUpPage;
