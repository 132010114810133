import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./style.scss";

const Index = ({ intl, secondary, disabled, onCopy, value, icon, children, ...props }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const onHandleCopy = () => {
    onCopy && onCopy(value);
    setCopySuccess(true);
    if (!copySuccess) setInterval(() => setCopySuccess(false), 5000);
  };
  return (
    <CopyToClipboard
      onCopy={onHandleCopy}
      text={value}
      className={`wrapper-coppy pointer ${disabled ? "not-allowed" : ""}`}
      {...props}
    >
      <div>
        {children}
        {icon || (
          <img
            src={require("../../../assets/images/icon/icon-copy.svg")}
            alt=""
            width={secondary ? "14px" : "20px"}
            style={{ marginLeft: "6px" }}
          />
        )}
        {copySuccess && (
          <div className="copy-success" style={{ color: "#fff" }}>
            <FormattedMessage id="Success" />
          </div>
        )}
      </div>
    </CopyToClipboard>
  );
};

export default Index;
