import * as types from "./constants";

export const getWallets = (payload) => {
  return {
    type: types.GET_WALLET_LIST,
    payload,
  };
};
export const getWalletsSuccess = (payload) => {
  return {
    type: types.GET_WALLET_LIST_SUCCESS,
    payload,
  };
};

export const viewWalletDetail = (payload) => {
  return {
    type: types.VIEW_WALLET_DETAILS,
    payload,
  };
};

export const viewWalletDetailSuccess = (payload) => {
  return {
    type: types.VIEW_WALLET_DETAILS_SUCCESS,
    payload,
  };
};

export const withdrawWallet = (payload, callbackSuccess) => {
  return {
    type: types.WALLET_WITHDRAW_TRANSFER,
    payload,
    callbackSuccess,
  };
};

export const withdrawWalletSuccess = () => {
  return {
    type: types.WALLET_WITHDRAW_TRANSFER_SUCCESS,
  };
};

export const swapWallet = (payload, callbackSuccess) => {
  return {
    type: types.WALLET_SWAP_TRANSFER,
    payload,
    callbackSuccess,
  };
};

export const getIsInternal = (payload) => {
  return {
    type: types.GET_IS_INTERNAL,
    payload,
  };
};

export const verifyTransaction = (data, callbackSuccess = null, callbackError = null) => {
  return {
    type: types.VERIFY_TRACSACTION,
    payload: data,
    callbackSuccess,
    callbackError,
  };
};

export const verifyTransactionSuccess = (payload) => ({
  type: types.VERIFY_TRACSACTION_SUCCESS,
  payload,
});

export const verifyTransactionFail = (payload) => ({
  type: types.VERIFY_TRACSACTION_FAIL,
  payload,
});

export const cancelTransaction = (payload, callbackSuccess) => ({
  type: types.CANCEL_TRANSACTION,
  payload,
  callbackSuccess,
});

export const walletTransWithdraw = (payload, callbackSuccess) => ({
  type: types.WALLET_TRAN_WITHDRAW,
  payload,
  callbackSuccess,
});

export const setFilterCode = (payload) => ({
  type: types.FILTER_TABLE,
  payload,
});

export const getMoreUsjInvest = (payload, callbackSuccess) => ({
  type: types.GET_MORE_USJ_INVEST,
  payload,
  callbackSuccess,
});

export const triggerHistoryModal = (payload) => ({
  type: types.HISTORY_MODAL,
  payload,
});

export const checkIfWalletAddressExist = (payload, callbackSuccess) => ({
  type: types.CHECK_WALLET_ADDRESS,
  payload,
  callbackSuccess,
});

export const setTradingPrice = (payload) => ({
  type: types.SET_TRADING_PRICE,
  payload,
});

export const getPaymentCode = (payload, callback) => ({
  type: types.GET_PAYMENT_CODE,
  payload,
  callback,
});
export const getPaymentCodeSuccess = (payload) => ({
  type: types.GET_PAYMENT_CODE_SUCCESS,
  payload,
});

export const verifyPayment = (payload, callback) => ({
  type: types.VERIFY_PAYMENT,
  payload,
  callback,
});

export const withdrawPayment = (payload, callback = () => {}) => ({
  type: types.WITHDRAW_PAYMENT,
  payload,
  callback,
});
export const loadDeposit = (payload, callbackSuccess) => ({
  type: types.LOAD_DEPOSIT,
  payload,
  callbackSuccess,
});

export const walletDeposit = (payload, CBSuccess, CBError) => ({
  type: types.WALLET_DEPOSIT,
  payload,
  CBSuccess,
  CBError,
});

export const getWalletHistoryList = (payload) => ({
  type: types.GET_WALLET_HISTORY_LIST,
  payload,
});
export const getWalletHistoryListSuccess = (payload) => ({
  type: types.GET_WALLET_HISTORY_LIST_SUCCESS,
  payload,
});
export const openWalletHistoryModal = (payload) => ({
  type: types.OPEN_WALLET_HISTORY_MODAL,
  payload,
});

export const getWalletBcdList = (payload) => ({
  type: types.GET_WALLET_BCD_LIST,
  payload,
});
export const getWalletBcdListSuccess = (payload) => ({
  type: types.GET_WALLET_BCD_LIST_SUCCESS,
  payload,
});

export const updateWallet = (payload, CBSuccess) => ({
  type: types.UPDATE_WALLET,
  payload,
  CBSuccess,
});

export const openModalWalletEdit = (payload, CBSuccess) => ({
  type: types.OPEN_MODAL_WALLET_EDIT,
  payload,
  CBSuccess,
});
