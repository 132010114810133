import { GET_COMMISSION_LIST_SUCCESS } from "./constants";
import produce from "immer";

const initialState = {
  commissionList: {
    data: [],
    total: 0,
  },
};

export default function CommissionReducer(state = initialState, action) {
  const { payload } = action;
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_COMMISSION_LIST_SUCCESS:
        draft.commissionList.data = payload?.data || [];
        draft.commissionList.total = payload?.pagination?.total;
        break;
      default:
        break;
    }
  });
}
