import * as types from "./constants";

export const onConfirmSubscribe = (payload, callbackSuccess) => ({
  type: types.CONFIRM_CURRENCY,
  payload,
  callbackSuccess,
});

export const getAllDetailChart = (payload) => ({
  type: types.GET_ALL_DETAIL_CHART,
  payload,
});

export const getTotalPV = (payload) => ({
  type: types.GET_TOTAL_PV,
  payload,
});
export const getTotalPVSuccess = (payload) => ({
  type: types.GET_TOTAL_PV_SUCCESS,
  payload,
});

export const getTotalPunk = (payload) => ({
  type: types.GET_TOTAL_PUNK,
  payload,
});
export const getTotalPunkSuccess = (payload) => ({
  type: types.GET_TOTAL_PUNK_SUCCESS,
  payload,
});

export const getUsjInvest = (payload) => ({
  type: types.GET_USJ_INVEST,
  payload,
});

export const getUsjInvestSuccess = (payload) => ({
  type: types.GET_USJ_INVEST_SUCCESS,
  payload,
});

export const exchangeCalRate = (payload) => ({
  type: types.EXCHANGE_CAL_RATE,
  payload,
});

export const getInvestHistoryDashboard = (payload) => ({
  type: types.GET_HISTORY_INVEST,
  payload,
});

export const getInvestHistoryDashboardSuccess = (payload) => ({
  type: types.GET_HISTORY_INVEST_SUCCESS,
  payload,
});

export const appendInvestHistory = (payload) => ({
  type: types.APPEND_HISTORY_INVEST,
  payload,
});

export const getSettingAll = (payload) => ({
  type: types.GET_SETTING_ALL,
  payload,
});
export const getSettingAllSuccess = (payload) => ({
  type: types.GET_SETTING_ALL_SUCCESS,
  payload,
});

export const setShowModalNew = (payload) => ({
  type: types.SET_SHOWMODAL_NEW,
  payload,
});

//New
export const getListTradeExchange = (payload) => ({
  type: types.GET_LIST_TRADE_EXCHANGE,
  payload,
});
export const getListTradeExchangeSuccess = (payload) => ({
  type: types.GET_LIST_TRADE_EXCHANGE_SUCCESS,
  payload,
});

export const onActiveAgent = (payload, callbackSuccess) => ({
  type: types.ACTIVE_AGENT,
  payload,
  callbackSuccess,
});

export const getListStakeDashboard = (payload, callbackSuccess) => ({
  type: types.GET_LIST_STAKE_DASHBOARD,
  payload,
  callbackSuccess,
});

export const getRate = () => ({
  type: types.GET_RATE,
});

export const getRateSuccess = (payload) => ({
  type: types.GET_RATE_SUCCESS,
  payload,
});

export const withdraw = (payload, redirect) => ({
  type: types.WITHDRAW,
  payload,
  redirect,
});

export const getTotalInfo = (payload) => ({
  type: types.GET_TOTAL_INFO,
  payload,
});
export const getTotalInfoSuccess = (payload) => ({
  type: types.GET_TOTAL_INFO_SUCCESS,
  payload,
});
