export const GET_WALLET_LIST = "GET_WALLET_LIST";
export const GET_WALLET_LIST_SUCCESS = "GET_WALLET_LIST_SUCCESS";
export const VIEW_WALLET_DETAILS = "VIEW_WALLET_DETAILS";
export const VIEW_WALLET_DETAILS_SUCCESS = "VIEW_WALLET_DETAILS_SUCCESS";
export const WALLET_WITHDRAW_TRANSFER_SUCCESS = "WALLET_WITHDRAW_TRANSFER_SUCCESS";
export const WALLET_WITHDRAW_TRANSFER = "WALLET_WITHDRAW_TRANSFER";
export const WALLET_SWAP_TRANSFER = "WALLET_SWAP_TRANSFER";
export const GET_IS_INTERNAL = "GET_IS_INTERNAL";
export const VERIFY_TRACSACTION = "VERIFY_TRANSACTION";
export const VERIFY_TRACSACTION_SUCCESS = "VERIFY_TRACSACTION_SUCCESS";
export const VERIFY_TRACSACTION_FAIL = "VERIFY_TRACSACTION_FAIL";
export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION";
export const WALLET_TRAN_WITHDRAW = "WALLET_TRAN_WITHDRAW";
export const FILTER_TABLE = "FILTER_TABLE";
export const WALLET_GET_BY_EMAIL_OR_ADDRESS = "WALLET_GET_BY_EMAIL_OR_ADDRESS";
export const GET_MORE_USJ_INVEST = "GET_MORE_USJ_INVEST";
export const HISTORY_MODAL = "HISTORY_MODAL";
export const CHECK_WALLET_ADDRESS = "CHECK_WALLET_ADDRESS";
export const SET_TRADING_PRICE = "SET_TRADING_PRICE";

export const GET_PAYMENT_CODE = "GET_PAYMENT_CODE";
export const GET_PAYMENT_CODE_SUCCESS = "GET_PAYMENT_CODE";
export const VERIFY_PAYMENT = "VERIFY_PAYMENT";
export const WITHDRAW_PAYMENT = "WITHDRAW_PAYMENT";
export const GET_LIST_BANK = "GET_LIST_BANK";

export const LOAD_DEPOSIT = "LOAD_DEPOSIT";

export const WALLET_DEPOSIT = "WALLET_DEPOSIT";
export const WALLET_DEPOSIT_SUCCESS = "WALLET_DEPOSIT_SUCCESS";

export const GET_WALLET_HISTORY_LIST = "GET_WALLET_HISTORY_LIST";
export const GET_WALLET_HISTORY_LIST_SUCCESS = "GET_WALLET_HISTORY_LIST_SUCCESS";
export const OPEN_WALLET_HISTORY_MODAL = "OPEN_WALLET_HISTORY_MODAL";

export const GET_WALLET_BCD_LIST = "GET_WALLET_BCD_LIST";
export const GET_WALLET_BCD_LIST_SUCCESS = "GET_WALLET_BCD_LIST_SUCCESS";

export const CREATE_WALLET = "CREATE_WALLET";
export const UPDATE_WALLET = "UPDATE_WALLET";

export const OPEN_MODAL_WALLET_EDIT = "OPEN_MODAL_WALLET_EDIT";
