import React from "react";
import { roundNumber } from "helpers/numbers";
export default class NodeLabel extends React.PureComponent {
  render() {
    const { className, nodeData } = this.props;
    return (
      <div className={`${className}`}>
        <div className="label-name">
          <div className="hover">
            <p>{nodeData.name}</p>
            <p>{nodeData.email}</p>
            <p>
              {nodeData.revenue
                ? roundNumber(nodeData.revenue)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                : 0}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
