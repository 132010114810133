import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import "./styles.scss";
import { ROUTE } from "commons/constants";

const ActiveCode = ({ intl, ...props }) => {
  const { path } = useRouteMatch();

  return (
    <div className="tabs-binref-wrapper d-flex" {...props}>
      <NavLink className="tabs-binref-btn" activeClassName="tabs-binref-btn-active" to={`${path}${ROUTE.NETWORKS_BINARY}`}>
        <FormattedMessage id="Binary" />
      </NavLink>
      <NavLink className="tabs-binref-btn" activeClassName="tabs-binref-btn-active" to={`${path}${ROUTE.NETWORKS_REFERRALS}`}>
        <FormattedMessage id="Referrals" />
      </NavLink>
    </div>
  );
};

export default injectIntl(ActiveCode);
