import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { Col, Row } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";

import "./style.scss";
import HeaderPage from "commons/components/HeaderPage";
import TabBinRef from "../components/TabsBinRef";
import Referrals from "./referrals";
import Binary from "./binary";
// import General from "./general";
// import BinaryPV from "./BinaryPV";
// import Revenue from "./revenue";
// import BinReferals from "./binReferals";
import { ROUTE } from "commons/constants";

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <section className="networks-page">
      <HeaderPage title={<FormattedMessage id="Referrals" />} rightNode={<TabBinRef />} />
      <div className="networks-body">
        <Row className="networks-content" gutter={0}>
          <Col xl={24} lg={24} md={24} xs={24} sm={24}>
            <Switch>
              {/* <Route exact path={`${path}${ROUTE.NETWORKS_GENERAL}`}>
                <General />
              </Route> */}
              <Route exact path={`${path}${ROUTE.NETWORKS_REFERRALS}`}>
                <Referrals />
              </Route>
              <Route exact path={`${path}${ROUTE.NETWORKS_BINARY}`}>
                <Binary />
              </Route>
              {/* <Route exact path={`${path}${ROUTE.NETWORKS_BINARY_PV}`}>
                <BinaryPV />
              </Route>
              <Route exact path={`${path}${ROUTE.NETWORKS_REVENUE}`}>
                <Revenue />
              </Route>
              <Route exact path={`${path}${ROUTE.NETWORKS_BIN_REFERALS}`}>
                <BinReferals />
              </Route> */}
            </Switch>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default injectIntl(Index);
