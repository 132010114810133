import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Tag, Table, Button, Popconfirm } from "antd";
import { trim, get } from "lodash";
import "./styles.scss";
import { fixedNumber } from "helpers/numbers";
import { formatCode } from "helpers/CommonHelper";
import { TRANSACTION } from "commons/constants";
import FilterCustom from "commons/components/FilterCustom";
import { cancelTransactionTypeWithDraw, cancelTransactionTypeTransfer } from "modules/transactions/redux/actions";
import { FILTER_HISTORY_DEFAULT, FILTER_HISTORY_MAPPING } from "modules/staking/const";
import { getWalletHistoryList, openWalletHistoryModal } from "modules/wallets/redux/actions";
import { updateToggleAuthyModal } from "modules/auth/redux/actions";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";
import { getProfile } from "modules/profile/redux/actions";

const title = "Wallet History";
const STATUS = "status";
const AMOUNT = "amount";

const UserWalletHistoryList = ({ intl }) => {
  const dispatch = useDispatch();
  const {
    openWalletHistoryDrawer: { data: dataModal },
    walletHistoryList: { data, total, perPage },
  } = useSelector((state) => state.wallets);

  const [count, setCount] = useState(1);
  const [searchParams, setSearchParams] = useState({
    pageIndex: 1,
    pageSize: 30,
    type: "ALL",
    currency: dataModal?.unit,
  });

  useEffect(() => {
    if (dataModal?.unit) {
      dispatch(getWalletHistoryList({ ...searchParams, currency: dataModal?.unit }));
    }
  }, [dispatch, searchParams, dataModal]);

  const handleCancelTransaction = (val) => {
    executeRecaptcha((token_captcha) => {
      const payload = {
        token_captcha,
        transactionCode: val?.code,
      };
      switch (val?.type) {
        case "WITHDRAW":
          dispatch(
            cancelTransactionTypeWithDraw(payload, () => {
              dispatch(getWalletHistoryList(searchParams));
              dispatch(getProfile());
            })
          );
          break;
        case "TRANSFER":
          dispatch(
            cancelTransactionTypeTransfer(payload, () => {
              dispatch(getWalletHistoryList(searchParams));
              dispatch(getProfile());
            })
          );
          break;
        default:
          break;
      }
    });
  };

  const verifyTransaction = ({ id = "" }) => {
    dispatch(
      updateToggleAuthyModal({
        id,
        typeCallApi: TRANSACTION,
        CBSuccess: () => {
          dispatch(getWalletHistoryList(searchParams));
        },
      })
    );
  };

  const renderAmount = (_, item) => {
    return (
      <span className={`${item[AMOUNT] > 0 ? "text-success" : "text-danger"}`}>
        {item.action !== "OUT" ? `+${fixedNumber(item[AMOUNT])}` : `-${fixedNumber(Math.abs(item[AMOUNT]))}`}
      </span>
    );
  };

  const renderStatus = (value, item) => {
    switch (value) {
      case 0:
      case "0":
      case "CANCEL":
        return (
          <Tag color="default">
            <FormattedMessage id="transaction.list.cancel" />
          </Tag>
        );
      case 1:
      case "1":
      case "COMPLETED":
      case "SUCCESS":
        return (
          <Tag color="green">
            <FormattedMessage id="transaction.list.success" />
          </Tag>
        );
      case 2:
      case "2":
      case "VERIFIED":
        return (
          <Tag color="blue">
            <FormattedMessage id="transaction.list.verified" />
          </Tag>
        );
      case 3:
      case "3":
      case "PENDING":
        return (
          <>
            <Tag
              color="#108ee9"
              className="pointer"
              onClick={() => verifyTransaction(item)}
              style={{ minWidth: "70px", textAlign: "center" }}
            >
              <FormattedMessage id="transaction.list.Verify" />
            </Tag>
            <Popconfirm title="Cancel" onConfirm={() => handleCancelTransaction(item)} okText=" Yes " cancelText=" No ">
              <Tag color="#f50" className="pointer">
                <FormattedMessage id="transaction.list.cancel" />
              </Tag>
            </Popconfirm>
          </>
        );
      case "FAILED":
        return (
          <Tag color="default">
            <FormattedMessage id="transaction.list.failed" />
          </Tag>
        );
      default:
        break;
    }
  };
  const header = [
    {
      key: "code",
      dataIndex: "code",
      title: <FormattedMessage id="Code" />,
      render: (text, record) => {
        const code = formatCode(text, 4, 4, "...");
        const txHashUrl = get(record, "scan_url");
        if (txHashUrl) {
          return (
            <a href={txHashUrl} target="_blank" rel="noopener noreferrer">
              {code}
            </a>
          );
        }
        return <span>{code}</span>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 30,
      render: (text) => (
        <span>{text === "OUT" ? <span className="amount-out">OUT</span> : <span className="amount-in">IN</span>}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: AMOUNT,
      render: (text, data) => renderAmount(text, data),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text) => <div>{text === "INTEREST_STAKE" ? "MINING" : text}</div>,
    },
    {
      title: "Status",
      dataIndex: STATUS,
      render: (text, data) => renderStatus(text, data),
    },
    {
      title: "Time",
      dataIndex: "created_at",
      // render: (text) => moment(text).format("YYYY/MM/DD HH:mm"),
    },
  ];

  const loadMore = () => {
    const perPageUpdate = perPage + count * searchParams.pageSize;
    setSearchParams((prev) => ({ ...prev, pageSize: perPageUpdate }));
    setCount(count + 1);
  };

  const { innerWidth: width } = window;
  const closeModal = () => {
    dispatch(openWalletHistoryModal({ value: false, data: null }));
  };

  const renderFilter = (value) => {
    const getFilterMapping = FILTER_HISTORY_MAPPING[value];
    if (getFilterMapping) {
      let result = [...FILTER_HISTORY_DEFAULT, ...getFilterMapping];

      return result;
    }
    return [...FILTER_HISTORY_DEFAULT];
  };

  const handleChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      pageSize: 10,
      type: value,
    }));
  };

  return (
    <div className="wallets-list-container">
      <div className="table-wrapper">
        <div className="section-title border-bottom-title d-flex space-between mb-20">
          <div className="mr-10" style={{ whiteSpace: "nowrap" }}>
            {dataModal?.unit}:&nbsp;
            <span className="icon-flex-end pointer" onClick={closeModal}>
              <FormattedMessage id="Transactions History" />
            </span>
          </div>
          <div className="pointer" onClick={closeModal}>
            <FormattedMessage id="Close" />
          </div>
        </div>
        <FilterCustom intl={intl} data={renderFilter(dataModal?.unit)} defaultValue="ALL" handleChange={handleChange} />
        <Table
          rowKey={(record) => `${trim(title)}-${get(record, "id")}`}
          className={`report-table`}
          dataSource={data}
          columns={header}
          pagination={false}
          scroll={{ x: 0 }}
          size={width < 480 ? "middle" : ""}
        />
        {total > perPage && (
          <div className="btn-load-more">
            <Button className="btn-primary small bordered" type="primary" onClick={loadMore}>
              <FormattedMessage id="stake.loadMore" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(UserWalletHistoryList);
