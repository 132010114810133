import { getWaitingList, bringTree } from "modules/networks/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import { renderLogo } from "helpers/Ranks";
import { GRAY_NODE_SHAPE } from "../TreeVanilla";
// import { ImageExist } from "modules/dashboard/components/DashBoardMission/CircleCard";

export const ImageExist = (url) => {
  var img = new Image();
  img.src = url;
  return img.height != 0;
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const bottom = [
  { value: "left", name: "carousel.left" },
  { value: "right", name: "carousel.right" },
];

const { confirm } = Modal;
const CarouselBinary = ({ intl, handleOnSuccessToLeftToRight = () => {} }) => {
  const dispatch = useDispatch();
  const { waitingList } = useSelector((state) => state.networks);

  useEffect(() => {
    dispatch(getWaitingList());
  }, []);

  const openConfirmBringTree = (info, position) => {
    let formData = {
      customer_id: info?.id,
      position: position,
    };
    // let name = `${info?.first_name} ${info?.last_name}`;
    confirm({
      className: "custom-confirm",
      title: intl.formatMessage({
        id: "Confirm",
      }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({
        id: "networks.confirmBringTree.description",
      }),
      onOk() {
        handleOnSuccessToLeftToRight();
        dispatch(
          bringTree(formData, () => {
            dispatch(getWaitingList());
          })
        );
      },
      onCancel() {},
    });
  };

  // const renderUrl = (url) => {
  //   if (!url) return require("assets/images/tree/ICON_KIWI_SCAN_10.svg");
  //   if (ImageExist(url)) {
  //     return url;
  //   }
  //   return require("assets/images/tree/ICON_KIWI_SCAN_10.svg");
  // };

  return (
    <Carousel
      responsive={responsive}
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {waitingList.data.map((item, key) => (
        <div key={"waiting-" + key} className="carousel-wrapper">
          {item.level_id === 0 ? (
            <div className="carousel-circle" style={{ backgroundColor: GRAY_NODE_SHAPE }} />
          ) : (
            <div className="carousel-circle" style={{ backgroundImage: `url(${renderLogo(item.level_id)})` }} />
          )}
          <div className="carousel-name">{item?.username || "n/a"}</div>
          <div className="carousel-bottom">
            {bottom.map((position, k) => (
              <div
                key={k}
                className="carousel-item pointer color-secondary"
                onClick={() => {
                  openConfirmBringTree(item, position.value);
                }}
              >
                {position ? <FormattedMessage id={position.name} /> : ""}
              </div>
            ))}
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default injectIntl(CarouselBinary);
