import { all } from "redux-saga/effects";

import { ApplicationSaga } from "modules/application";
import { AuthSaga } from "modules/auth";
import { NetworksSaga } from "modules/networks";
import { WalletSaga } from "modules/wallets";
import { TransactionSaga } from "modules/transactions";
import { PackageSaga } from "modules/package";
import { SettingSaga } from "modules/settings";
import { ProfileSaga } from "modules/profile";
import { CommissionSaga } from "modules/commissions";
import { DashboardSaga } from "modules/dashboard";
import { StakingSaga } from "modules/staking";

export default function* rootSaga() {
  yield all([
    ApplicationSaga(),
    AuthSaga(),
    WalletSaga(),
    NetworksSaga(),
    TransactionSaga(),
    PackageSaga(),
    ProfileSaga(),
    CommissionSaga(),
    DashboardSaga(),
    StakingSaga(),
    SettingSaga(),
  ]);
}
