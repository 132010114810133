import * as types from "./constants";
import produce from "immer";

const initialState = {
  systemPv: [],
  myPv: [],
  usjInvest: [],
  ratePv: [],
  listInvestDashboard: {
    data: [],
    total: 0,
  },
  settingAll: {},
  showModalNew: true,
  listTradeExchange: null,
  rateValue: {},
  totalInfo: {},
};

export default (state = initialState, action) => {
  const { payload } = action;
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_TOTAL_PV_SUCCESS:
        const systemPv = payload.map((o, key) => ({
          data1: o?.totalPv || 0,
          data2: o?.pv || 0,
          data3: o?.ratePv || 0,
          ...o,
        }));
        draft.systemPv = systemPv.reverse();
        break;

      case types.GET_TOTAL_PUNK_SUCCESS:
        const myPv = payload.map((o, key) => ({
          data: o?.fund || 0,
          ...o,
        }));
        draft.myPv = myPv.reverse();
        break;

      case types.GET_USJ_INVEST_SUCCESS: {
        const usjInvest = payload.map((o, key) => ({
          data: o?.valueIn || 0,
          ...o,
        }));
        draft.usjInvest = usjInvest;
        break;
      }
      case types.GET_HISTORY_INVEST_SUCCESS:
        draft.listInvestDashboard = payload;
        break;
      case types.APPEND_HISTORY_INVEST:
        let listInvestData = draft.listInvestDashboard.data;
        let totalInvest = draft.listInvestDashboard.total;
        let firstData = listInvestData[0] || {};
        if (firstData && payload && firstData.id !== payload.id) {
          totalInvest = totalInvest + 1;
          listInvestData.unshift(payload);
        }
        draft.listInvestDashboard = {
          data: listInvestData,
          total: totalInvest,
        };
        break;
      case types.GET_SETTING_ALL_SUCCESS:
        draft.settingAll = payload || {};
        break;
      case types.SET_SHOWMODAL_NEW:
        draft.showModalNew = payload || false;
        break;

      //New

      case types.GET_LIST_TRADE_EXCHANGE_SUCCESS:
        const data = payload !== null ? payload : [];
        draft.listTradeExchange = data.map((ele, index) => {
          return {
            id: index,
            title: ele?.title || "",
            icon: ele?.logo || require("assets/images/icon/icon-round-white.png"),
            link: ele?.link || null,
          };
        });
        break;
      case types.GET_RATE_SUCCESS:
        draft.ratePv = payload;
        break;
      case types.GET_TOTAL_INFO_SUCCESS:
        draft.totalInfo = payload;
        break;
      default:
        break;
    }
  });
};
