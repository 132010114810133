import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import { get } from "lodash";
import { verifySponsorFail, verifySponsorSuccess } from "modules/networks/redux/actions";

const AlertVerifySponsorModal = ({ intl }) => {
  const dispatch = useDispatch();
  const { success, fail } = useSelector((state) => get(state, "networks.alert"));

  const onClose = () => {
    dispatch(verifySponsorFail(false));
    dispatch(
      verifySponsorSuccess({
        value: false,
        data: null,
      })
    );
  };
  return (
    <>
      <SweetAlert
        error
        showCancel
        show={Boolean(fail)}
        showConfirm={false}
        cancelBtnText={intl.formatMessage({
          id: "networks.verify.sponsor.close",
        })}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={intl.formatMessage({ id: "networks.verify.sponsor.fail" })}
        onCancel={onClose}
        onConfirm={onClose}
      />
      <SweetAlert
        success
        showCancel
        show={Boolean(success)}
        onConfirm={onClose}
        showConfirm={false}
        cancelBtnText={intl.formatMessage({
          id: "networks.verify.sponsor.close",
        })}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={intl.formatMessage({ id: "networks.verify.sponsor.success" })}
        onCancel={onClose}
      />
    </>
  );
};

export default injectIntl(AlertVerifySponsorModal);
