import React from "react";
import { Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { updateWallet } from "../../redux/actions";
import { formatCode, formatDate } from "helpers/CommonHelper";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";
import WrapperCoppy from "commons/components/WrapperCoppy";

const moreAction = (type, record, dispatch, _, fetchListData) => {
  const onUpdateWallet = () => {
    executeRecaptcha((token_captcha) => {
      dispatch(
        updateWallet({ id: record.id, address: record.account_address, token_captcha }, () => {
          fetchListData();
        })
      );
    });
  };

  switch (type) {
    case "EDIT":
      return (
        <EditOutlined
        // onClick={() => {
        //   handleOpenModalEdit();
        // }}
        />
      );
    case "DISABLE":
      if (record.status === 1) {
        return (
          <Tag color="rgb(219 148 12)" className="pointer" onClick={onUpdateWallet}>
            Disable
          </Tag>
        );
      } else {
        return "";
      }
    default:
      break;
  }
};

const renderStatus = (value) => {
  switch (value) {
    case 0:
    case "0":
      return <Tag color="yellow">Processing</Tag>;
    case 1:
    case "1":
      return <Tag color="blue">Active</Tag>;
    case 2:
    case "2":
      return <Tag color="default">Cancel</Tag>;
    default:
      break;
  }
};

export const headers = (dispatch, intl, fetchListData) => [
  {
    title: "#",
    dataIndex: "index",
    width: 50,
    render: (_, record, index) => (record?.sum === "Total" ? record?.sum : <span>{index + 1}</span>),
  },
  {
    title: "Title",
    dataIndex: "account_name",
    width: 130,
  },
  {
    title: "Address",
    dataIndex: "account_address",
    width: 130,
    render: (text) =>
      text ? (
        <WrapperCoppy value={text} secondary>
          {formatCode(text, 4, 4)}
        </WrapperCoppy>
      ) : (
        "-"
      ),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    render: (text) => (text !== undefined ? renderStatus(text) : "-"),
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    width: 130,
    render: (text, record) => (text ? formatDate(record) : "-"),
  },
  {
    title: "Updated At",
    dataIndex: "updated_at",
    width: 130,
    render: (text, record) => (text ? formatDate(record) : "-"),
  },
  {
    title: "Actions",
    dataIndex: "operation",
    fixed: "right",
    width: 50,
    render: (_, record) => moreAction("DISABLE", record, dispatch, intl, fetchListData),
  },
];
