import React from "react";
import { injectIntl } from "react-intl";
import { Select } from "antd";
import "./style.scss";

const { Option } = Select;

const Index = ({ data = [], className = "", defaultValue = "ALL", handleChange = () => {}, intl, ...otherProps }) => {
  return (
    <div className={`filter-custom ${className}`}>
      <Select className="filter-type" defaultValue={defaultValue} onChange={handleChange} {...otherProps}>
        {data.map((item, key) => (
          <Option key={key} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default injectIntl(Index);
