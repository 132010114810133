import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { openSuccessModal } from "modules/application/redux/actions";
import WrapperModal from "../WrapperModal";

const SuccessModal = (props) => {
  const dispatch = useDispatch();
  const { toggle, data } = useSelector((state) => state.application.modalSuccess);
  const handleClose = () => {
    dispatch(openSuccessModal({ toggle: false, data: null }));
  };
  return (
    <WrapperModal
      destroyOnClose
      visible={toggle}
      title={<FormattedMessage id={"wallet.actions.Success"} />}
      handleCancel={handleClose}
      footer={false}
    >
      <div className="modal-content-success">
        <img src={require("assets/images/icon/successIcon.png")} alt="" width={90} />
        {props?.message ||
          (data?.message && (
            <div className="modal-message-success mt-20">
              <div>{props?.message || ""}</div>
              <div>{data?.message || ""}</div>
            </div>
          ))}
      </div>
    </WrapperModal>
  );
};

export default injectIntl(SuccessModal);
