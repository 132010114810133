import React from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

const WarningSteak = () => {
  const { waitingList } = useSelector((state) => ({ waitingList: state.networks.waitingList }));

  return (
    <>
      <div className="section-title dashboard warning-steak color-primary">
        YOU HAVE {waitingList?.total} IN WAITING LIST
        <div className="border-hr"></div>
      </div>
    </>
  );
};

export default WarningSteak;
