import { all, call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";
import * as qs from "query-string";

import { ROOT_API_URL, MODAL_TYPES } from "commons/constants";
import fetchHelper from "helpers/FetchHelper";
import * as types from "./constants";

import {
  getWalletsSuccess,
  viewWalletDetailSuccess,
  withdrawWalletSuccess,
  getPaymentCodeSuccess,
  getWalletHistoryListSuccess,
  getWalletBcdListSuccess,
} from "./actions";
import { openErrorModal, openSuccessModal } from "modules/application/redux/actions";

function* getWallets() {
  const res = yield call(requestWalletFromApi);
  if (get(res, "status") === 200) {
    const data = get(res, "data", []);
    yield put(getWalletsSuccess(data));
  }
}

function requestWalletFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/get`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function* getIsInternal({ payload, callbackSuccess }) {
  const res = yield call(requestGetIsInternalFromApi, payload);
  if (get(res, "status") === 200) {
    callbackSuccess();
  }
}

function requestGetIsInternalFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/withdraw/is-internal?${payload}`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* viewWalletDetails({ payload }) {
  const type = get(payload, "type");
  switch (type) {
    case MODAL_TYPES.commission:
      yield put(viewWalletDetailSuccess({ ...payload, data: true }));
      break;
    case MODAL_TYPES.details:
      yield put(viewWalletDetailSuccess(payload));
      break;
    default:
      yield put(viewWalletDetailSuccess(payload));
      break;
  }
}

function* onWithdrawWallet({ payload, callbackSuccess }) {
  const { isViewItem } = payload;
  if (isViewItem === MODAL_TYPES.transfer || isViewItem === MODAL_TYPES.send) {
    const { data } = yield call(requestWalletWithdrawInternal, {
      wallet_addr: get(payload, "toAddress", ""),
      amount: get(payload, "amount", 0),
      currency: get(payload, "currency", ""),
      ...payload,
    });
    if (get(data, "status_code") === 200) {
      yield put(withdrawWalletSuccess());
      callbackSuccess(data);
      return;
    }
    yield put(openErrorModal({ toggle: true, data }));
  } else if (isViewItem === MODAL_TYPES.withdraw) {
    const { data } = yield call(requestWalletWithdraw, payload);
    if (get(data, "status_code") === 200) {
      yield put(withdrawWalletSuccess());
      callbackSuccess(data);
      yield put(openSuccessModal({ toggle: true, data }));
      return;
    }
    yield put(openErrorModal({ toggle: true, data }));
  }
}

function requestWalletWithdrawInternal(payload) {
  return fetchHelper
    .fetch(
      `${ROOT_API_URL}/wallet-trans/transfer`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
      {
        isToastSuccess: false,
        isToastFailed: false,
      }
    )
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function requestWalletWithdraw(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/withdraw`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* onSwapWallet({ payload, callbackSuccess }) {
  const { data } = yield call(requestWalletSwap, payload);
  if (get(data, "status_code") === 200) {
    callbackSuccess();
    yield put(openSuccessModal({ toggle: true, data }));
    return;
  }
  yield put(openErrorModal({ toggle: true, data }));
  return;
}

function requestWalletSwap(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-trans/swap`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* verifyTransaction({ payload, callbackSuccess, callbackError }) {
  const { data } = yield call(verifyTransactionFromApi, payload);
  if (get(data, "status_code") === 200) {
    callbackSuccess(data);
    return;
  }
  callbackError();
}

function* cancelTransaction({ payload, callbackSuccess }) {
  const { data } = yield call(confirmTransactionFromApi, payload);
  if (get(data, "status_code") === 200) {
    callbackSuccess();
    return;
  }
}

const verifyTransactionFromApi = (data) => {
  return fetchHelper
    .fetch(
      `${ROOT_API_URL}/wallet/withdraw/verify-transaction`,
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      { isShowMessages: false }
    )
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
};

function confirmTransactionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/withdraw/cancel-transaction`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function* walletTranTransfer({ payload, callbackSuccess }) {
  const { data } = yield call(requestWalletTransTrade, payload);
  if (get(data, "status_code") === 200) {
    callbackSuccess();
    return;
  }
}

function requestWalletTransTrade(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-trans/earn`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* walletGetMoreUsjInvest({ payload, callbackSuccess }) {
  const { data } = yield call(requestGetMoreInvest, payload);
  if (get(data, "status_code") === 200) {
    callbackSuccess(data.data);
    return;
  }
}

function requestGetMoreInvest(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-trans/exchange/usj-in-vest`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* getPaymentCode({ payload, callback }) {
  const results = yield call(getPaymentCodeFromApi, payload);
  if (get(results, "data.status_code") === 200) {
    callback({ status: true, message: "" });
    yield put(getPaymentCodeSuccess(get(results, "data.data", {})));
  } else {
    callback({ status: false, message: "" });
  }
}

function getPaymentCodeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/merchant-order/make-payment`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* verifyPayment({ payload, callback }) {
  try {
    const results = yield call(verifyPaymentFromApi, payload);
    if (get(results, "data.status_code") === 200) {
      callback({ status: true, message: "" });
    } else {
      callback({ status: false, message: "" });
    }
  } catch (error) {
    callback({ status: false, message: error?.message || "" });
  }
}

function verifyPaymentFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/merchant-order/verify-payment`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* requestWithdrawPayment({ payload, callback }) {
  try {
    const data = yield call(requestWithdrawPaymentApi, payload);
    if (get(data, "status") === 200) {
      callback(data.data);
      return;
    }
  } catch (error) {
    callback({ status: false, message: error?.message || "" });
  }
}
function requestWithdrawPaymentApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-partner/withdraw`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* requestLoadDeposit({ payload, callbackSuccess }) {
  try {
    const results = yield call(requestLoadDepositFromApi, payload);
    if (get(results, "status") === 200) {
      callbackSuccess();
      return;
    }
  } catch (error) {}
}

function requestLoadDepositFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/load-deposit`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* walletDeposit(payload, CBSuccess, CBError) {
  const { data } = yield call(walletDepositFromApi, payload);
  if (data.status_code === 200) {
    CBSuccess(data.data);
  } else {
    CBError(data.data);
  }
}
function walletDepositFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet/deposit`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* getWalletHistory({ payload }) {
  const { data } = yield call(requestGetWalletHistoryListFromApi, payload);
  if (get(data, "status_code") === 200) {
    yield put(getWalletHistoryListSuccess(data.data));
    return;
  }
}
function requestGetWalletHistoryListFromApi(payload) {
  const qsString = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-trans/list?${qsString}`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function getWalletBcdListFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/bcd-address/list`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}
function* getWalletBcdList({ payload }) {
  const {
    data: { data, status_code },
  } = yield call(getWalletBcdListFromApi, payload);
  if (status_code === 200) {
    yield put(getWalletBcdListSuccess(data));
    return;
  }
}

function createWalletFromApi(payload) {
  const qStr = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/bcd-address/create?${qStr}`, {
      method: "POST",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}
function updateWalletFromApi(payload) {
  const qStr = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/customer/bcd-address/inActive?${qStr}`, {
      method: "POST",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}
function* updateWallet({ payload, CBSuccess }) {
  if (payload.id) {
    const {
      data: { data, status_code },
    } = yield call(updateWalletFromApi, payload);
    if (status_code === 200) {
      CBSuccess(data);
      return;
    }
  } else {
    const {
      data: { data, status_code },
    } = yield call(createWalletFromApi, payload);
    if (status_code === 200) {
      CBSuccess(data);
      return;
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(types.GET_WALLET_LIST, getWallets),
    takeLatest(types.VIEW_WALLET_DETAILS, viewWalletDetails),
    takeLatest(types.WALLET_WITHDRAW_TRANSFER, onWithdrawWallet),
    takeLatest(types.WALLET_SWAP_TRANSFER, onSwapWallet),
    takeLatest(types.GET_IS_INTERNAL, getIsInternal),
    takeLatest(types.VERIFY_TRACSACTION, verifyTransaction),
    takeLatest(types.CANCEL_TRANSACTION, cancelTransaction),
    takeLatest(types.WALLET_TRAN_WITHDRAW, walletTranTransfer),
    takeLatest(types.GET_MORE_USJ_INVEST, walletGetMoreUsjInvest),
    takeLatest(types.GET_PAYMENT_CODE, getPaymentCode),
    takeLatest(types.VERIFY_PAYMENT, verifyPayment),
    takeLatest(types.WITHDRAW_PAYMENT, requestWithdrawPayment),
    takeLatest(types.LOAD_DEPOSIT, requestLoadDeposit),
    takeLatest(types.WALLET_DEPOSIT, walletDeposit),
    takeLatest(types.GET_WALLET_HISTORY_LIST, getWalletHistory),
    takeLatest(types.GET_WALLET_BCD_LIST, getWalletBcdList),
    takeLatest(types.UPDATE_WALLET, updateWallet),
  ]);
}
