import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { Dropdown, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import * as Cookies from "js-cookie";
import { get } from "lodash";
import "./styles.scss";
import { ROUTE, CACHE_TOKEN, CACHE_USER_INFO } from "commons/constants";

const ProfileAccount = ({ isMobile, className = "" }) => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.profile.profileData);

  const handleLogout = () => {
    Cookies.remove(CACHE_TOKEN);
    localStorage.removeItem(CACHE_USER_INFO);
    window.location.href = window.location.origin + ROUTE.LOGIN;
  };

  const menu = (
    <Menu>
      <Menu.Item key="profile-account">
        <div className="logout" onClick={handleLogout}>
          <FormattedMessage id="logout" /> &nbsp;
          <LogoutOutlined />
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={`${className} profile-account mr-10`}>
      {isMobile ? (
        <div className="profile-mobile">
          <Dropdown overlay={menu} placement="bottomLeft">
            <div className="wrapper-image-avatar">
              <img
                width={40}
                height={40}
                className="image-avatar"
                src={get(userInfo, "image", null) ? get(userInfo, "image", null) : require("assets/images/logo/nonAvatar.svg")}
                alt={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
                title={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
                onClick={() => history.push("/setting/personal")}
              />
            </div>
          </Dropdown>
          <div className="styleName ml-10">
            <span>
              <FormattedMessage id="hi" /> {`${userInfo?.username || ""}`}
            </span>
            <div className="profile-logout" onClick={handleLogout}>
              <FormattedMessage id="logout" />
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-desktop">
          <div className="ml-10">
            <span className="styleName">
              <FormattedMessage id="hi" /> {`${userInfo?.username || ""}`}
            </span>
          </div>
          <Dropdown overlay={menu} placement="bottomLeft">
            <div className="wrapper-image-avatar">
              <img
                width={40}
                height={40}
                className="image-avatar"
                src={require("assets/images/logo/nonAvatar.svg")}
                // src={get(userInfo, "image", null) ? get(userInfo, "image", null) : require("assets/images/logo/nonAvatar.svg")}
                alt={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
                title={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
                onClick={() => history.push("/setting/personal")}
              />
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default injectIntl(ProfileAccount);
