import messages_vi from "./translations/vi.json";
import messages_en from "./translations/en.json";

export const messagesListLanguage = {
  en: messages_en,
  vi: messages_vi,
};

export const CACHE_LANGUAGE = "lang";
export const DEFAULT_LANGUAGE = "en";

export const OPTIONS_LANG = [
  { id: "en", label: "English", image: require("assets/images/flags/en.png") },
  // {
  //   id: "vi",
  //   label: "Tiếng việt",
  //   image: require("assets/images/flags/vn.png"),
  // },
];
