import React from "react";
import { Card, Button } from "antd";
import { injectIntl } from "react-intl";
import { get, isEmpty } from "lodash";
import "./style.scss";

const { Meta } = Card;

const LIST_COLOR = ["#ffffff", "#ff00f9", "#8d40ff", "#007dff", "#08faff", "#0bff6e", "#ff8100", "red", "yellow"];
const DetailStats = ({ intl, index, source, onBuyNow, ...props }) => {
  return (
    <>
      <Card
        // hoverable
        bordered={false}
        // onClick={onClick}
        className="card-package-item"
        cover={
          <div className="card-package-logo" style={{ backgroundColor: LIST_COLOR?.[index] || "#fff" }}>
            <img className={`card-img`} alt="..." src={require("assets/images/logo/logo.png")} style={{ width: "60%" }} />
          </div>
        }
        actions={[
          <Button className="card-action-1" onClick={() => onBuyNow(source)}>
            BUY NOW
          </Button>,
        ]}
        {...props}
      >
        {!isEmpty(source) && (
          <div className="card-price">
            {get(source, "id") === 1 ? (
              <>
                {`${get(source, "price", "-")} EUR`}
                <Meta title={source?.title || ""} description={""} />
              </>
            ) : (
              <>
                {source?.title || ""}
                <Meta
                  title={`${source?.price || "-"} EUR` || ""}
                  description={`(Upto lv ${get(source, "commission.value_upgrade", "")})`}
                />
              </>
            )}
          </div>
        )}
      </Card>
    </>
  );
};

export default injectIntl(DetailStats);
