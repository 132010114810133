// Medical
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_VERIFY_CODE = "GET_VERIFY_CODE";
export const GET_VERIFY_CODE_SUCCESS = "GET_VERIFY_CODE_SUCCESS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

// Dialog Modal
export const OPEN_UPDATE_PROFILE_DIALOG = "OPEN_UPDATE_PROFILE_DIALOG";
export const OPEN_UPDATE_PASSWORD_DIALOG = "OPEN_UPDATE_PASSWORD_DIALOG";
export const OPEN_UPDATE_EMAIL_DIALOG = "OPEN_UPDATE_EMAIL_DIALOG";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const OPEN_BANK_DIALOG = "OPEN_BANK_DIALOG";

// Bank
export const GET_LIST_BANK_MANAGER = "GET_LIST_BANK_MANAGER";
export const GET_LIST_BANK_MANAGER_SUCCESS = "GET_LIST_BANK_MANAGER_SUCCESS";
export const CREATE_BANK_MANAGER = "CREATE_BANK_MANAGER";
export const CREATE_BANK_MANAGER_SUCCESS = "CREATE_BANK_MANAGER_SUCCESS";
export const UPDATE_BANK_MANAGER = "UPDATE_BANK_MANAGER";
export const UPDATE_BANK_MANAGER_SUCCESS = "UPDATE_BANK_MANAGER_SUCCESS";
export const DELETE_BANK_MANAGER = "DELETE_BANK_MANAGER";
export const DELETE_BANK_MANAGER_SUCCESS = "DELETE_BANK_MANAGER_SUCCESS";
export const UPDATE_STATE_USER = "UPDATE_STATE_USER";

export const VERIFY_CHANGE_EMAIL = "VERIFY_CHANGE_EMAIL";

export const GET_WALLET_LIST_FILTER = "GET_WALLET_LIST_FILTER";
export const GET_WALLET_LIST_FILTER_SUCCESS = "GET_WALLET_LIST_FILTER_SUCCESS";
