import "./style.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Form, Input, Button } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { get } from "lodash";

import InputCopy from "../../FielCopy/InputCopy";
import { inviteFriend } from "modules/networks/redux/actions";
import WithdrawModal from "modules/wallets/components/WithdrawModal";
import { Link } from "react-router-dom";
import { MIN_STAKE_OF_REF } from "commons/constants";

const Index = ({ intl }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.profile.profileData);
  const [showModalInvite, setShowModalInvite] = useState(false);

  // Handle ref
  const link = `${process.env.REACT_APP_USER_URL}/signup?sponsorkey=${get(userInfo, "sponsorKey")}`;
  const [refCode, setRefCode] = useState("");
  const [orderCode, setOrderCode] = useState(get(userInfo, "sponsorKey"));

  useEffect(() => {
    if (userInfo.invest_stake < MIN_STAKE_OF_REF) {
      setRefCode(intl.formatMessage({ id: "you.need.min.100.referals" }));
      setOrderCode(intl.formatMessage({ id: "you.need.min.100.referals" }));
    } else {
      setRefCode(link);
      setOrderCode(get(userInfo, "sponsorKey"));
    }
  }, [intl, link, userInfo]);
  // End Handle ref

  const onCloseModalInvite = () => {
    setShowModalInvite(false);
  };

  const onInviteFriend = (values) => {
    dispatch(
      inviteFriend(values, () => {
        onCloseModalInvite();
      })
    );
  };

  const copyReferralLink = (code) => {
    navigator.clipboard.writeText(code);
    toast(
      `${intl.formatMessage({
        id: "setting.invite.modal.messageCopyLink",
      })} ${code}`
    );
  };

  return (
    <>
      <Card className="card-ref">
        <Row gutter={[25, 25]} style={{ marginBottom: 0 }}>
          <Col className="networks-ref" sm={12} xs={24}>
            <InputCopy
              title={<FormattedMessage id="networks.card.title.ref.link" />}
              setShowModalInvite={setShowModalInvite}
              // accessCopy={userInfo?.invest_stake >= MIN_STAKE_OF_REF}
              code={refCode}
              link={userInfo?.invest_stake < MIN_STAKE_OF_REF ? "/stake" : null}
              icon={require("assets/images/fly.png")}
            />
          </Col>
          <Col className="networks-ref" sm={12} xs={24}>
            <InputCopy
              title={<FormattedMessage id="networks.card.title.ref.code" />}
              setShowModalInvite={setShowModalInvite}
              // accessCopy={userInfo?.invest_stake >= MIN_STAKE_OF_REF}
              link={userInfo?.invest_stake < MIN_STAKE_OF_REF ? "/stake" : null}
              code={orderCode}
              icon={require("assets/images/fly.png")}
            />
          </Col>
        </Row>
      </Card>

      <WithdrawModal
        destroyOnClose
        visible={showModalInvite}
        title={<FormattedMessage id="setting.invite.modal.inviteYourFriend" />}
        footer={
          <Button form="formInvite" key="submit" htmlType="submit">
            {userInfo?.invest_stake >= MIN_STAKE_OF_REF ? (
              <FormattedMessage id="setting.invite.modal.inviteYourFriend.btn" />
            ) : (
              <Link to="/stake">
                <FormattedMessage id="Stake.Now" />
              </Link>
            )}
          </Button>
        }
        width={450}
        handleCancel={onCloseModalInvite}
        className="custom-modal modal-invite"
      >
        {userInfo?.invest_stake >= MIN_STAKE_OF_REF ? (
          <Form id="formInvite" onFinish={onInviteFriend} layout="vertical" className="mt-10">
            <div className="form-body">
              <p className="form-input-label">
                <FormattedMessage id="setting.invite.modal.inviteYourFriend.email.input.label" />
              </p>
              <Form.Item
                label=""
                name="email"
                className="modal-input-field"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="setting.invite.messCheckEmail" />,
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: "setting.invite.modal.placeCheckEmail",
                  })}
                />
              </Form.Item>
              <div className="content">
                <span className="icon-copy" onClick={() => copyReferralLink(link)}>
                  <a href="/#">
                    <span className="copy-link mr-5">
                      <FormattedMessage id="setting.invite.modal.inviteYourFriend.copy.link.label" />
                    </span>
                    <img className="icon-down" src={require("assets/images/copy.png")} alt="icon copy" />
                  </a>
                </span>
              </div>
            </div>
          </Form>
        ) : (
          <div className="center">
            <div className="mb-10">{refCode}</div>
            <Link
              to="/stake"
              style={{
                fontSize: "20px",
                fontWeight: 700,
                color: "#6C1025",
                textTransform: "uppercase",
              }}
            >
              {intl.formatMessage({ id: "Stake.Now" })}
            </Link>
          </div>
        )}
      </WithdrawModal>
    </>
  );
};

export default injectIntl(Index);
