import { all, takeLatest, call, put } from "redux-saga/effects";
import fetchHelper from "helpers/FetchHelper";
import { get } from "lodash";
import qs from "query-string";
import * as types from "./constants";
import * as actions from "./actions";
import { ROOT_API_URL } from "commons/constants";
import { openErrorModal, openSuccessModal } from "modules/application/redux/actions";

function requestGetPackageListFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/product/list-stake`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}
function* getPackagesList({ payload }) {
  const { data } = yield call(requestGetPackageListFromApi, payload);
  if (get(data, "status_code") === 200) {
    yield put(actions.getListPackageSuccess(data.data));
    return;
  }
}

function* buyPool({ payload, callbackSuccess = () => {} }) {
  const { data } = yield call(buyPoolFromApi, payload);
  if (get(data, "status_code") === 200) {
    callbackSuccess();
    return;
  }
}

function buyPoolFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/buy/pool?object=${payload.productId}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function* getGlobalCurrency({ payload }) {
  const { data } = yield call(requestGetGlobalCurrencyFromApi, payload);
  if (get(data, "status_code") === 200) {
    yield put(actions.getGlobalCurrencySuccess(data.data));
    return;
  }
}

function requestGetGlobalCurrencyFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/get-global-config`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function buyPackageFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/lock-stake`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}
function* buyPackage({ payload, CBSuccess, CBError }) {
  const { data } = yield call(buyPackageFromApi, payload);
  if (get(data, "status_code") === 200) {
    CBSuccess && CBSuccess(data.data);
    if (get(payload, "product_id") === 1) {
      yield put(openSuccessModal({ toggle: true, data }));
    }
    return;
  }
  yield put(openErrorModal({ toggle: true, data }));
  CBError && CBError(data);
}

function* sharePackageCode({ payload, CBSuccess, CBError }) {
  const { data } = yield call(sharePackageCodeFromApi, payload);
  if (get(data, "status_code") === 200) {
    CBSuccess && CBSuccess(data.data);
    yield put(openSuccessModal({ toggle: true, data }));
    return;
  }
  CBError && CBError(data);
  yield put(openErrorModal({ toggle: true, data }));
  return;
}
function sharePackageCodeFromApi(payload) {
  const queryStr = qs.stringify(payload);
  return fetchHelper
    .fetch(
      `${ROOT_API_URL}/order/send-email-code?${queryStr}`,
      {
        method: "GET",
      },
      { isToastSuccess: false, isToastFailed: false }
    )
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}

function getListPackageActiveFromApi(payload) {
  const query = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/list-actived?${query}`, {
      method: "GET",
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}
function* getListPackageActive({ payload }) {
  try {
    const { data } = yield call(getListPackageActiveFromApi, payload);
    if (data.status_code === 200) {
      yield put(actions.getListPackagesActiveSuccess(data.data));
      return;
    }
    return;
  } catch (error) {}
}

function getListMyStakeFromApi(payload) {
  const query = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/list-my-stake?${query}`, {
      method: "GET",
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}
function* getListMyStake({ payload }) {
  try {
    const { data } = yield call(getListMyStakeFromApi, payload);
    if (data.status_code === 200) {
      yield put(actions.getListMyStakeSuccess(data.data));
      return;
    }
    return;
  } catch (error) {}
}

function generateCodeFromApi(payload) {
  const query = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/get-code?${query}`, {
      method: "GET",
    })
    .then(([{ data, status_code }]) => ({
      data,
      status_code,
    }));
}
function* generateCode({ payload, CBSuccess, CBError }) {
  const { data, status_code } = yield call(generateCodeFromApi, payload);
  if (status_code === 200) {
    CBSuccess && CBSuccess(data);
    return;
  }
  CBError && CBError(data);
  yield put(openErrorModal({ toggle: true, data }));
}

function getInterestPackageHistoryFromApi(payload) {
  const query = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-trans/get-interest-stake-history?${query}`, {
      method: "GET",
    })
    .then(([{ data, status_code }]) => ({
      data,
      status_code,
    }));
}
function* getInterestPackageHistory({ payload }) {
  const { data, status_code } = yield call(getInterestPackageHistoryFromApi, payload);
  if (status_code === 200) {
    yield put(actions.getInterestPackageHistorySuccess(data));
  }
}

function getContractOrderFromApi(payload) {
  const query = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/generate-contract-order?${query}`, {
      method: "GET",
    })
    .then(([{ data, status_code }]) => ({
      data,
      status_code,
    }));
}
function* getContractOrder({ payload, cbs }) {
  const { data, status_code } = yield call(getContractOrderFromApi, payload);
  if (status_code === 200) {
    yield put(actions.pushDataContractOrder(data));
    if (cbs) {
      cbs(data);
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_LIST_PACKAGES, getPackagesList),
    takeLatest(types.BUY_POOL, buyPool),
    takeLatest(types.GET_GLOBAL_CURRENCY, getGlobalCurrency),
    takeLatest(types.BUY_PACKAGE, buyPackage),
    takeLatest(types.SHARE_PACKAGE_CODE, sharePackageCode),
    takeLatest(types.GET_LIST_ACTIVE, getListPackageActive),
    takeLatest(types.GET_LIST_MY_STAKE, getListMyStake),
    takeLatest(types.GENERATE_CODE, generateCode),
    takeLatest(types.GET_INTEREST_PACKAGE_HISTORY, getInterestPackageHistory),
    takeLatest(types.GET_CONTRACT_ORDER, getContractOrder),
  ]);
}
