import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { CACHE_USER_INFO, CACHE_TOKEN } from "commons/constants";
import { NODE_TRANDING_PRICE } from "configs/const";
import FirebaseService from "../../services/firebase";
import { setRateFromFirebase } from "./redux/actions";
import { useConfigRecaptcha } from "helpers/GoogleRecaptcha";

const defaultData = [
  { currency: "BTC", price: 0, logo: require("../../assets/images/coin/btc.svg") },
  { currency: "ETH", price: 0, logo: require("../../assets/images/coin/eth.svg") },
  { currency: "USDT.BEP20", price: 1, logo: require("../../assets/images/coin/usdt.svg") },
  { currency: "XRP", price: 0, logo: require("../../assets/images/coin/xrp.svg") },
  { currency: "EUR", price: 0, logo: require("../../assets/images/coin/eur.svg") },
  { currency: "BCD2", price: 0, logo: require("../../assets/images/coin/bcd.svg") },
];

const Application = ({ intl }) => {
  const dispatch = useDispatch();

  useConfigRecaptcha();

  useEffect(() => {
    console.log("Start project...");
    const userInfo = localStorage.getItem(CACHE_USER_INFO);
    const token = Cookies.get(CACHE_TOKEN);
    if (!userInfo && token) {
      Cookies.remove(CACHE_TOKEN);
      toast(intl.formatMessage({ id: "login.again" }));
    }
  }, [intl]);

  /**
   * Get rate firebase
   */
  useEffect(() => {
    const fetchAllData = () => {
      FirebaseService.getTradingPrice(NODE_TRANDING_PRICE)
        .once("value")
        .then((snapshot) => {
          let parseData = snapshot.val();
          parseData = parseData
            .map((result) => {
              const defaultItem = defaultData.find((item) => item.currency === result.currency);
              if (defaultItem) {
                return {
                  ...defaultItem,
                  ...result,
                };
              }
              return undefined;
            })
            .filter((valid) => !!valid);

          dispatch(setRateFromFirebase(parseData));
        });
    };
    FirebaseService.listenEventTradingPrice(NODE_TRANDING_PRICE, () => {
      fetchAllData();
    });
    fetchAllData();
  }, [dispatch]);

  return null;
};

export default injectIntl(Application);
