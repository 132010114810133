export const MAPPING_LOGO = {
  0: require("assets/images/logo/logo.png"),
  1: require("assets/images/level/level-1.svg"),
  2: require("assets/images/level/level-2.svg"),
  3: require("assets/images/level/level-3.svg"),
  4: require("assets/images/level/level-4.svg"),
  5: require("assets/images/logo/logo-sapphire.svg"),
  6: require("assets/images/logo/logo-ruby.svg"),
  7: require("assets/images/logo/logo-president.svg"),
  8: require("assets/images/logo/logo-president.svg"),
};
export const renderLogo = (rankValue = 0, level = 0, levelPackage = 0) => {
  if (level === null || level === undefined || levelPackage === null || levelPackage === undefined) return MAPPING_LOGO[0];

  if ((levelPackage === 9 && level >= 9) || (level === 9 && levelPackage >= 9)) {
    return MAPPING_LOGO[8];
  }

  if ((levelPackage === 8 && level >= 8) || (level === 8 && levelPackage >= 8)) {
    return MAPPING_LOGO[7];
  }

  if ((levelPackage === 7 && level >= 7) || (level === 7 && levelPackage >= 7)) {
    return MAPPING_LOGO[6];
  }

  if ((levelPackage === 6 && level >= 6) || (level === 6 && levelPackage >= 6)) {
    return MAPPING_LOGO[5];
  }
  if (rankValue > 4) return MAPPING_LOGO[4];
  if (rankValue === "0") return MAPPING_LOGO[1];
  return MAPPING_LOGO[rankValue];
};
