import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Row, Col } from "antd";
import "./styles.scss";
import { CACHING_DATA } from "commons/constants";
import { WalletList } from "../components";
import HeaderPage from "commons/components/HeaderPage";
import HistoryDetailDrawer from "modules/wallets/components/HistoryDetailDrawer";
import SuccessModal from "commons/components/ModalCommons/SuccessModal";
import ErrorModal from "commons/components/ModalCommons/ErrorModal";

export const TOOGLE_CALLBACK_GET_LIST = "TOOGLE_CALLBACK_GET_LIST";
CACHING_DATA[TOOGLE_CALLBACK_GET_LIST] = false;
export const setToogleCallBack = () => {
  return (CACHING_DATA[TOOGLE_CALLBACK_GET_LIST] = !CACHING_DATA[TOOGLE_CALLBACK_GET_LIST]);
};

const WalletPage = () => {
  let toggleCallBackGetList = CACHING_DATA[TOOGLE_CALLBACK_GET_LIST];

  const handleCallApi = (value) => {
    setToogleCallBack();
  };

  return (
    <div className="wallet-container">
      <HeaderPage title={<FormattedMessage id="page.title.wallets" />} />
      <Row>
        <Col span={24}>
          <WalletList
            isCall={toggleCallBackGetList}
            callBackGetListetListTransaction={(value) => {
              handleCallApi(value);
            }}
          />
        </Col>
      </Row>
      <HistoryDetailDrawer />
      <SuccessModal />
      <ErrorModal />
    </div>
  );
};

export default injectIntl(WalletPage);
