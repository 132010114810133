import React from "react";
import { PLUS, SUBTRACT } from "modules/networks/components/TreeCollapse";
const ActivityText = ({ hasChild = [], id }) => {
  return (
    <>
      {" "}
      <span id={`${PLUS}-${id}`} style={{ display: hasChild ? "" : "none" }}>
        +{" "}
      </span>
      <span id={`${SUBTRACT}-${id}`} style={{ display: hasChild ? "none" : "" }}>
        -{" "}
      </span>
    </>
  );
};

export default ActivityText;
