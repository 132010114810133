import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { CACHE_TOKEN, ROUTE } from "commons/constants";
import { injectIntl } from "react-intl";
import { verifyTransaction, verifyTransactionSuccess, verifyTransactionFail } from "../redux/actions";
import { getParamFromURL } from "helpers/CommonHelper";

const TRON = "TRON";
const WITHRAW = "withdraw";

const VerifyTransactionPage = ({ history, intl }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = getParamFromURL(CACHE_TOKEN);
    const payload = {
      type: WITHRAW,
      network: TRON,
      token: queryParams,
    };
    dispatch(
      verifyTransaction(
        payload,
        (data) => {
          dispatch(
            verifyTransactionSuccess({
              value: true,
              data,
            })
          );
          history.push(ROUTE.HOME);
        },
        () => {
          dispatch(verifyTransactionFail(true));
          history.push(ROUTE.HOME);
        }
      )
    );
  }, [dispatch, history]);
  return <></>;
};

export default injectIntl(VerifyTransactionPage);
