export const OPEN_MODAL_SWAP = "OPEN_MODAL_SWAP";
export const GET_LIST_PACKAGES = "GET_LIST_PACKAGES";
export const GET_LIST_PACKAGES_SUCCESS = "GET_LIST_PACKAGES_SUCCESS";
export const BUY_POOL = "BUY_POOL";
export const GET_GLOBAL_CURRENCY = "GET_GLOBAL_CURRENCY";
export const GET_GLOBAL_CURRENCY_SUCCESS = "GET_GLOBAL_CURRENCY_SUCCESS";

export const BUY_PACKAGE = "BUY_PACKAGE";
export const SHARE_PACKAGE_CODE = "SHARE_PACKAGE_CODE";

export const OPEN_SHARE_CODE_MODAL = "OPEN_SHARE_CODE_MODAL";
export const OPEN_GENERATE_CODE_MODAL = "OPEN_GENERATE_CODE_MODAL";

export const GET_LIST_ACTIVE = "GET_LIST_ACTIVE";
export const GET_LIST_ACTIVE_SUCCESS = "GET_LIST_ACTIVE_SUCCESS";

export const GET_LIST_MY_STAKE = "GET_LIST_MY_STAKE";
export const GET_LIST_MY_STAKE_SUCCESS = "GET_LIST_MY_STAKE_SUCCESS";

export const GENERATE_CODE = "GENERATE_CODE";
export const GENERATE_CODE_SUCCESS = "GENERATE_CODE_SUCCESS";

export const OPEN_DRAWER_PROFIT = "OPEN_DRAWER_PROFIT";

export const GET_INTEREST_PACKAGE_HISTORY = "GET_INTEREST_PACKAGE_HISTORY";
export const GET_INTEREST_PACKAGE_HISTORY_SUCCESS = "GET_INTEREST_PACKAGE_HISTORY_SUCCESS";

export const PUSH_DATA_PACKAGE = "PUSH_DATA_PACKAGE";
export const GET_CONTRACT_ORDER = "GET_CONTRACT_ORDER";
export const PUSH_DATA_CONTRACT_ORDER = "PUSH_DATA_CONTRACT_ORDER";
