import React, { useState } from "react";
import { Tag, Button, Table } from "antd";
import { trim, get } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { getUserWalletHistoryList, openUserHistoryModal } from "modules/networks/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { roundNumber } from "helpers/numbers";
import { formatCode } from "helpers/CommonHelper";
import "./styles.scss";
import { TRONSCAN_TRANSACTION } from "commons/constants";
import { fixedNumber } from "helpers/numbers";

const renderStatus = (value) => {
  switch (value) {
    case 0:
    case "0":
    case "CANCEL":
      return (
        <Tag color="default">
          <FormattedMessage id="transaction.list.cancel" />
        </Tag>
      );
    case 1:
    case "1":
    case "COMPLETED":
    case "SUCCESS":
      return (
        <Tag color="green">
          <FormattedMessage id="transaction.list.success" />
        </Tag>
      );
    case 2:
    case "2":
    case "VERIFIED":
      return (
        <Tag color="blue">
          <FormattedMessage id="transaction.list.verified" />
        </Tag>
      );
    case 3:
    case "3":
    case "PENDING":
      return (
        <Tag color="orange">
          <FormattedMessage id="transaction.list.pending" />
        </Tag>
      );
    case "FAILED":
      return (
        <Tag color="default">
          <FormattedMessage id="transaction.list.failed" />
        </Tag>
      );
    default:
      break;
  }
};

const AMOUNT = "total_pay";
const title = "Wallet History";

const UserWalletHistoryList = () => {
  const dispatch = useDispatch();
  const modalUserHistory = useSelector((state) => state.networks.modalUserHistory);
  const { referalDetailData } = useSelector((state) => ({ referalDetailData: state.networks.referalDetailData }));
  const [count, setCount] = useState(1);
  const { data, total, perPage, totalPay } = referalDetailData || {
    data: [],
    total: 0,
    perPage: 10,
    totalPay: 0,
  };

  const renderAmount = (text, item) => {
    return (
      <span className={`${item[AMOUNT] > 0 ? "text-success" : "text-danger"}`}>
        {item[AMOUNT] > 0 ? `+${fixedNumber(item[AMOUNT])}` : `-${fixedNumber(Math.abs(item[AMOUNT]))}`}
      </span>
    );
  };

  const header = [
    {
      title: <FormattedMessage id="transaction.Package" />,
      key: "code",
      dataIndex: "order_code",
      width: 150,
      render: (text, record) => {
        const code = text !== null ? formatCode(text, 4, 4, "...") : "";
        const type = get(record, "type");
        if (type === "DEPOSIT" || type === "WITHDRAW")
          return (
            <a target="_blank" rel="noopener noreferrer" href={`${TRONSCAN_TRANSACTION}/${text}`}>
              {code}
            </a>
          );
        return <span>{code}</span>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 30,
      render: (text) => (
        <span>{text === "OUT" ? <span className="amount-out">OUT</span> : <span className="amount-in">IN</span>}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: AMOUNT,
      width: 120,
      render: (text, record) => renderAmount(text, record),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 120,
      render: (text, record) => renderStatus(text, record),
    },
    {
      title: "Time",
      dataIndex: "created_at",
      width: 150,
      align: "right",
      // render: (text) => moment(text).format("YYYY/MM/DD HH:mm"),
    },
  ];

  const loadMore = () => {
    let perPageUpdate = perPage + count * 10;
    setCount(count + 1);
    const query = {
      page: 1,
      limit: perPageUpdate,
      customer_id: modalUserHistory?.data?.id,
    };
    dispatch(getUserWalletHistoryList(query));
  };

  const closeModal = () => {
    dispatch(openUserHistoryModal({ value: false, data: null }));
  };

  const { innerWidth: width } = window;
  return (
    <div className="network-list-container">
      <div className="table-wrapper">
        <div className="section-title border-bottom-title d-flex space-between">
          <div className="mr-10" style={{ whiteSpace: "nowrap" }}>
            <span className="mr-5">{modalUserHistory?.data?.username || ("" && modalUserHistory.data.username) || ""}:</span>
            <span className="icon-flex-end pointer" onClick={closeModal}>
              Total buy{" "}
              {roundNumber(totalPay)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
              Euro
            </span>
          </div>
          <div className="pointer" onClick={closeModal}>
            Close
          </div>
        </div>
        <Table
          rowKey={(record) => `${trim(title)}-${get(record, "id")}`}
          className={`report-table`}
          dataSource={data}
          columns={header}
          pagination={false}
          scroll={{ x: 0 }}
          size={width < 480 ? "middle" : ""}
        />
        {total > perPage && (
          <div className="btn-load-more">
            <Button className="btn-primary small bordered" type="primary" onClick={loadMore}>
              <FormattedMessage id="stake.loadMore" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(UserWalletHistoryList);
