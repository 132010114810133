import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import "./styles.scss";

import { headers } from "./header";
import ItemListing from "commons/components/ItemListing";
import { getWalletBcdList, openModalWalletEdit } from "../../redux/actions";
import EditModal from "./editModal";

const WalletListTable = ({ intl }) => {
  const dispatch = useDispatch();

  const walletBcdList = useSelector((state) => state.wallets.walletBcdList);

  const fetchListData = useCallback(() => dispatch(getWalletBcdList()), [dispatch]);

  useEffect(() => {
    fetchListData();
  }, [fetchListData]);

  const openDrawerCreateWallet = useCallback(() => {
    dispatch(openModalWalletEdit({ toggle: true, data: {} }));
  }, [dispatch]);

  const columnsTable = headers(dispatch, intl, fetchListData);

  return (
    <div className="bcd-wallet-list">
      {/* <button className="bcd-wallet-create" style={{ cursor: "auto" }}>
        Whitelist BCD Address
      </button> */}
      {/* <ItemListing columns={columnsTable} dataSource={walletBcdList} /> */}
      <EditModal fetchListData={fetchListData} />
    </div>
  );
};

export default injectIntl(WalletListTable);
