import React, { useCallback, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Card, Button } from "antd";
import { get } from "lodash";

import "./styles.scss";
import { MODAL_TYPES } from "commons/constants";
import { setFilterCode, viewWalletDetail, triggerHistoryModal, openWalletHistoryModal } from "modules/wallets/redux/actions";
import FormatAmount from "commons/components/FormatAmount";
import { openModalWalletEdit } from "../../redux/actions";

const Wallet = ({ intl, item, onloadWallet = () => {} }) => {
  const dispatch = useDispatch();

  const openHistoryModal = () => {
    dispatch(openWalletHistoryModal({ toggle: true, data: item }));
  };

  const handViewDetails = (item, type) => {
    dispatch(
      viewWalletDetail({
        type,
        data: item,
      })
    );
  };

  const openDrawerCreateWallet = useCallback(() => {
    dispatch(openModalWalletEdit({ toggle: true, data: {} }));
  }, [dispatch]);

  const handleChangeUnit = (item) => {
    const { unit } = item || { unit: "" };
    onloadWallet(unit);
    dispatch(
      triggerHistoryModal({
        isShow: true,
        currency: item.unit,
      })
    );
    dispatch(setFilterCode("transaction"));
  };

  return (
    <div className="wallet-wrapper">
      <Card className="wallet-item">
        <div className="wallet-info">
          <div
            className="wallet-name"
            onClick={() => {
              handleChangeUnit(item);
            }}
          >
            <div>
              <img
                className="wallet-image mr-10"
                src={item?.flat || require("assets/images/JSB-27.png")}
                alt={intl.formatMessage({ id: "wallet.title" })}
                width={50}
              />
            </div>
            <div>
              <span className="wallet-currency">{get(item, "label")}</span>
              &nbsp;
              <div className="wallet-history">
                <a href="#;" type="role" onClick={openHistoryModal}>
                  <FormattedMessage id="wallet.view.seeHistory" />
                </a>
              </div>
            </div>
          </div>
          <div className="wallet-price">
            <div className="wallet-balance">
              <FormatAmount amount={get(item, "amount", 0)} />
            </div>
            <div className="wallet-dollar" title={`$${get(item, "amount", 0) || 0}`}>
              <FormatAmount amount={get(item, "amount", 0)} rate={get(item, "price", 0)} prefix="$" />
            </div>
          </div>
        </div>
        <div className="wallet-actions">
          {item.btnDeposit?.show && (
            <Button
              className="action-link"
              onClick={() => {
                if (item.btnDeposit?.disable) return;
                handViewDetails(item, MODAL_TYPES.deposit);
              }}
              style={{
                order: item.btnDeposit.order,
                background: item.btnDeposit?.disable ? "#B7B7B7" : "",
                cursor: item.btnDeposit?.disable ? "not-allowed" : "",
              }}
            >
              <img alt="" src={require("assets/images/Icon-arrow-down.png")} style={{ marginRight: "4px" }} />
              <FormattedMessage id="wallet.actions.DEPOSIT" />
            </Button>
          )}
          {item.btnSwap?.show && (
            <Button
              className="action-link"
              onClick={() => {
                if (item.btnSwap?.disable) return;
                handViewDetails(item, MODAL_TYPES.swap);
              }}
              style={{
                order: item.btnSwap.order,
                background: item.btnSwap?.disable ? "#B7B7B7" : "",
                cursor: item.btnSwap?.disable ? "not-allowed" : "",
              }}
            >
              <img alt="" src={require("assets/images/Icon-swap.png")} style={{ marginRight: "4px" }} />
              <FormattedMessage id="wallet.actions.SWAP" />
            </Button>
          )}
          {item.btnTransfer?.show && (
            <Button
              className="action-link"
              onClick={() => {
                if (item.btnTransfer?.disable) return;
                handViewDetails(item, MODAL_TYPES.transfer);
              }}
              style={{
                order: item.btnTransfer.order,
                background: item.btnTransfer?.disable ? "#B7B7B7" : "",
                cursor: item.btnTransfer?.disable ? "not-allowed" : "",
              }}
            >
              <img alt="" src={require("assets/images/Icon-arrow-up.png")} style={{ marginRight: "4px" }} />
              <FormattedMessage id="wallet.actions.TRANSFER" />
            </Button>
          )}

          {item.btnWithdraw?.show && (
            <Button
              className="action-link"
              onClick={() => {
                if (item.btnWithdraw?.disable) return;
                handViewDetails(item, MODAL_TYPES.withdraw);
              }}
              style={{
                order: item.btnWithdraw.order,
                background: item.btnWithdraw?.disable ? "#B7B7B7" : "",
                cursor: item.btnWithdraw?.disable ? "not-allowed" : "",
              }}
            >
              <img alt="" src={require("assets/images/Icon-arrow-up.png")} style={{ marginRight: "4px" }} />
              <FormattedMessage id="wallet.actions.WITHDRAW" />
            </Button>
          )}
          {item.isShowGetMore && (
            <Button className="action-link" onClick={handViewDetails(item, MODAL_TYPES.getMore)}>
              <img alt="" src={require("assets/images/Icon-arrow-down.png")} style={{ marginRight: "4px" }} />
              <FormattedMessage id="wallet.actions.getMore" />
            </Button>
          )}
          {/* {item.label == "BCD" && (
            <button style={{ cursor: "pointer" }} className="bcd-wallet-create" onClick={openDrawerCreateWallet}>
              Create a wallet
            </button>
          )} */}
        </div>
      </Card>
    </div>
  );
};

export default injectIntl(Wallet);
