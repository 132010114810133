import produce from "immer";
import * as types from "./constants";

const initialState = {
  investment: {},
  items: [],
  stakeHistory: {
    data: [],
    pagination: {
      total: 0,
      perPage: 0,
      page: 0,
      lastPage: 0,
    },
  },
  packageUpgrade: [],
  reviewConfirmData: null,
  listInterestHis: null,
};

export default function PoolReducer(state = initialState, action) {
  const { payload } = action;

  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_LIST_INVEST_SUCCESS:
        draft.investment = payload || {};
        break;
      case types.GET_LIST_INVEST_HISTORY_SUCCESS:
        draft.items = payload || [];
        break;
      case types.GET_LIST_PACKAGE_UPGRAGE_SUCCESS:
        draft.packageUpgrade = payload || [];
        break;
      case types.GET_REVIEW_CONFIRM_TRANSACTION_SUCCESS:
        draft.reviewConfirmData = payload || null;
        break;

      //new

      case types.GET_STAKE_HISTORY_SUCCESS:
        const { params, data, pagination } = payload;

        draft.stakeHistory.params = params;
        draft.stakeHistory.data = data.reverse();
        draft.stakeHistory.pagination = pagination;
        break;
      case types.GET_INTEREST_HISTORY_SUCCESS:
        draft.listInterestHis = payload || null;
        break;
      default:
        break;
    }
  });
}
