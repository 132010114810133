import React from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
import { fixedNumber } from "helpers/numbers";

const MarqueeTokenBalance = () => {
  const { rateFromFirebase } = useSelector((state) => state.application);

  const { innerWidth } = window;
  return (
    <div
      className="marquee-token"
      style={{
        width: innerWidth < 1000 ? "100%" : "calc(100% - 230px)",
      }}
    >
      <marquee
        id="mymarquee"
        behavior="scroll" // scroll, alternate, slide
        direction="right"
        // scrolldelay={10000}
        onMouseOver={() => document.getElementById("mymarquee").stop()}
        onMouseOut={() => document.getElementById("mymarquee").start()}
      >
        {rateFromFirebase.map((ele, index) => {
          const currency = ele.currency === "BCD2" ? "BCD" : ele.currency;
          return (
            <span className="token-item ml-20" key={"token-stash" + index}>
              <img className="token-logo mr-10" src={ele.logo} alt={currency} width={24} heigh={24} />
              <span>{`${currency}: `} </span>
              {fixedNumber(+ele.price, "$")}
              {ele.rate && ele.type && (
                <span
                  style={{
                    color: ele.type === "UP" ? "#31c45b" : "red",
                  }}
                >
                  {` (${ele.rate}%)`}
                </span>
              )}
            </span>
          );
        })}
      </marquee>
    </div>
  );
};

export default MarqueeTokenBalance;
