import React from "react";

const TokenBalance = () => {
  return (
    <div className="ring-wrapper mr-20">
      {/* <img src={require("assets/images/icon/ring.svg")} width={20} alt="" /> */}
      {/* <div className="new-message" /> */}
    </div>
  );
};

export default TokenBalance;
