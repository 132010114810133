import React, { useState } from "react";
import { Button } from "antd";
import "./styles.scss";

const SelectNumberOption = ({ options = [25, 50, 75, 100], handleSelect, value, ...props }) => {
  const [selectValue, setSelectValue] = useState(value);
  const handleSelectNumber = (num) => {
    handleSelect(num);
    setSelectValue(num);
  };
  return (
    <div className="button-action-amount" {...props}>
      {(options || []).map((num) => (
        <Button
          key={`option-percent-${num}`}
          className={selectValue === num ? "active-amount" : ""}
          onClick={() => handleSelectNumber(num)}
        >
          {`${num}%`}
        </Button>
      ))}
    </div>
  );
};

export default SelectNumberOption;
