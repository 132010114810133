/* eslint-disable */
import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import RightModal from "commons/components/ModalCommons/rightModal";
import WalletHistoryListTable from "modules/wallets/components/WalletHistoryListTable";
import { openWalletHistoryModal } from "modules/wallets/redux/actions";

const UserHistoryModal = () => {
  const dispatch = useDispatch();
  const { toggle } = useSelector((state) => state.wallets.openWalletHistoryDrawer);
  const handleClose = () => {
    dispatch(openWalletHistoryModal({ toggle: false, data: null }));
  };
  const { innerWidth: width } = window;
  return (
    <div className="pool-list-pool">
      <RightModal
        isShow={toggle}
        width={width < 768 ? "100%" : "75%"}
        padding={width < 768 ? "20px 15px 20px 15px" : "20px 35px 20px 50px"}
        handleClose={handleClose}
        customContainerClass="pool-detail-modal"
      >
        <WalletHistoryListTable />
      </RightModal>
    </div>
  );
};

export default injectIntl(UserHistoryModal);
