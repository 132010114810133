import produce from "immer";
import * as types from "./constants";

const initialState = {
  globalCurrency: null,
  packageList: {
    data: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
    total: 0,
  },
  modalSwap: {
    toggle: false,
    data: null,
  },
  modalSharePackageCode: {
    data: null,
    total: 0,
  },
  modalGeneratePackageCode: {
    data: null,
    total: 0,
  },
  listPackageActive: {
    data: null,
    pagination: {},
  },
  listMyStake: {
    data: null,
    pagination: {},
  },
  openDrawerProfit: {
    toggle: false,
    data: null,
  },
  interestPackageList: {
    data: [],
    pagination: {
      total: 0,
      perPage: 10,
    },
  },
  dataPackageViewContract: {
    data: null,
  },
  dataContractOder: {
    data: null,
  },
};

export default function PackageReducer(state = initialState, action) {
  const { payload } = action;
  return produce(state, (draft) => {
    switch (action.type) {
      case types.OPEN_MODAL_SWAP:
        draft.modalSwap.toggle = payload.value;
        draft.modalSwap.data = payload.data;
        break;
      case types.OPEN_SHARE_CODE_MODAL:
        draft.modalSharePackageCode = payload;
        break;
      case types.OPEN_GENERATE_CODE_MODAL:
        draft.modalGeneratePackageCode = payload;
        break;
      case types.GET_LIST_PACKAGES_SUCCESS:
        draft.packageList.data = payload;
        draft.packageList.total = payload.length;
        break;
      case types.GET_GLOBAL_CURRENCY_SUCCESS:
        draft.globalCurrency = payload?.curreny_buy_pool;
        break;
      case types.GET_LIST_ACTIVE_SUCCESS:
        draft.listPackageActive = payload;
        break;
      case types.GET_LIST_MY_STAKE_SUCCESS:
        draft.listMyStake = payload;
        break;
      case types.OPEN_DRAWER_PROFIT:
        draft.openDrawerProfit = payload;
        break;
      case types.GET_INTEREST_PACKAGE_HISTORY_SUCCESS:
        draft.interestPackageList = payload;
        break;
      case types.PUSH_DATA_PACKAGE:
        draft.dataPackageViewContract.data = payload.data;
        break;
      case types.PUSH_DATA_CONTRACT_ORDER:
        draft.dataContractOder.data = payload.path_contract;
        break;
      default:
        break;
    }
  });
}
