import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Tag, Table, Button } from "antd";
import { trim, get } from "lodash";
import "./styles.scss";
import { fixedNumber } from "helpers/numbers";
import { getInterestPackageHistory, onOpenDrawerProfit } from "modules/package/redux/actions";

const title = "Wallet History";
const STATUS = "status";
const AMOUNT = "amount";

const renderStatus = (value) => {
  switch (value) {
    case 0:
    case "0":
    case "CANCEL":
      return (
        <Tag color="default">
          <FormattedMessage id="transaction.list.cancel" />
        </Tag>
      );
    case 1:
    case "1":
    case "COMPLETED":
    case "SUCCESS":
      return (
        <Tag color="green">
          <FormattedMessage id="transaction.list.success" />
        </Tag>
      );
    case 2:
    case "2":
    case "VERIFIED":
      return (
        <Tag color="blue">
          <FormattedMessage id="transaction.list.verified" />
        </Tag>
      );
    case 3:
    case "3":
    case "PENDING":
      return (
        <Tag color="orange">
          <FormattedMessage id="transaction.list.pending" />
        </Tag>
      );
    case "FAILED":
      return (
        <Tag color="default">
          <FormattedMessage id="transaction.list.failed" />
        </Tag>
      );
    default:
      break;
  }
};

const UserWalletHistoryList = () => {
  const dispatch = useDispatch();
  const { data: dataPackage } = useSelector((state) => state.package.openDrawerProfit);
  const {
    interestPackageList: {
      data,
      pagination: { total, perPage },
    },
  } = useSelector((state) => state.package);

  const [count, setCount] = useState(1);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 10,
    order_id: dataPackage?.id,
  });

  useEffect(() => {
    if (dataPackage?.id) {
      dispatch(getInterestPackageHistory({ ...searchParams, order_id: dataPackage?.id }));
    }
  }, [dispatch, searchParams, dataPackage]);

  const renderAmount = (text, item) => {
    return (
      <span className={`${item[AMOUNT] > 0 ? "text-success" : "text-danger"}`}>
        {item.action !== "OUT" ? `+${fixedNumber(item[AMOUNT])}` : `-${fixedNumber(Math.abs(item[AMOUNT]))}`}
      </span>
    );
  };

  const header = [
    {
      key: "id",
      dataIndex: "id",
      title: <FormattedMessage id="No" />,
      render: (_, ___, index) => index + 1,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 30,
      render: (text) => (
        <span>{text === "OUT" ? <span className="amount-out">OUT</span> : <span className="amount-in">IN</span>}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: AMOUNT,
      render: (text, data) => renderAmount(text, data),
    },
    {
      title: "Status",
      dataIndex: STATUS,
      render: (text, data) => renderStatus(text, data),
    },
    {
      title: "Time",
      dataIndex: "created_at",
      // render: (text) => moment(text).format("YYYY/MM/DD HH:mm"),
    },
  ];

  const loadMore = () => {
    const perPageUpdate = perPage + count * 10;
    setSearchParams((prev) => ({ ...prev, limit: perPageUpdate }));
    setCount(count + 1);
  };

  const closeModal = () => {
    dispatch(onOpenDrawerProfit({ value: false, data: null }));
  };

  const { innerWidth } = window;
  return (
    <div className="wallets-list-container">
      <div className="table-wrapper">
        <div className="section-title border-bottom-title d-flex space-between mb-20">
          <div className="mr-10" style={{ whiteSpace: "nowrap" }}>
            <span className="icon-flex-end pointer" onClick={closeModal}>
              <FormattedMessage id="drawer.transaction.PackageHistory" />: {dataPackage?.date ?? ""} days
              {/* $5000 &nbsp;
              <FormattedMessage id="drawer.transaction.InMonth" /> &nbsp; - 15%/
              <FormattedMessage id="drawer.transaction.month" /> */}
            </span>
          </div>
          <div className="pointer" onClick={closeModal}>
            <FormattedMessage id="Close" />
          </div>
        </div>
        <Table
          className={`report-table`}
          size={innerWidth < 480 ? "middle" : ""}
          scroll={{ x: 0 }}
          columns={header}
          dataSource={data}
          rowKey={(record) => `${trim(title)}-${get(record, "id")}`}
          pagination={false}
        />
        {total > perPage && (
          <div className="btn-load-more">
            <Button className="btn-primary small bordered" type="primary" onClick={loadMore}>
              <FormattedMessage id="stake.loadMore" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(UserWalletHistoryList);
