import {
  CREATE_BANK_MANAGER_SUCCESS,
  DELETE_BANK_MANAGER_SUCCESS,
  GET_LIST_BANK_MANAGER_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_VERIFY_CODE_SUCCESS,
  OPEN_BANK_DIALOG,
  OPEN_UPDATE_PASSWORD_DIALOG,
  OPEN_UPDATE_PROFILE_DIALOG,
  UPDATE_BANK_MANAGER_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_STATE_USER,
  OPEN_UPDATE_EMAIL_DIALOG,
  UPDATE_EMAIL_SUCCESS,
} from "./constants";
import { map } from "lodash";
import { CACHE_USER_INFO } from "../../../commons/constants";
import { uniqBy, orderBy, cloneDeep } from "lodash";

const initialState = {
  profileData: {},
  customerCode: undefined,
  verifyCode: undefined,
  openEditProfileDialog: false,
  isOpenUpdatePasswordDialog: false,
  isOpenEmailDialog: false,

  // geography
  countries: [],
  cities: [],
  // bank
  banks: [],
  isOpenBankDialog: false,
};

const updateUserInfoToLocal = (userInfo) => {
  localStorage.setItem(CACHE_USER_INFO, JSON.stringify(userInfo));
};

export const WALLET_1 = "BTC";
export const WALLET_2 = "ETH";
export const WALLET_3 = "USDT.BEP20";
export const WALLET_4 = "XRP";
export const WALLET_5 = "EUR";
export const WALLET_6 = "BCD2";

const LIST_WALLET_FILTER = {
  [WALLET_1]: {
    btnSwap: {
      show: true,
      disable: false,
    },
    btnDeposit: {
      show: true,
      disable: false,
    },
    btnWithdraw: {
      show: false,
      disable: false,
    },
    btnTransfer: {
      show: false,
      disable: false,
    },
    swapOptionUnit: [],
    logo: require("../../../assets/images/coin/btc.svg"),
  },
  [WALLET_2]: {
    btnSwap: {
      show: true,
      disable: false,
    },
    btnDeposit: {
      show: true,
      disable: false,
    },
    btnWithdraw: {
      show: false,
      disable: false,
    },
    btnTransfer: {
      show: false,
      disable: false,
    },
    swapOptionUnit: [],
    logo: require("../../../assets/images/coin/eth.svg"),
  },
  [WALLET_3]: {
    btnSwap: {
      show: true,
      disable: false,
    },
    btnDeposit: {
      show: true,
      disable: false,
    },
    btnWithdraw: {
      show: false,
      disable: false,
    },
    btnTransfer: {
      show: false,
      disable: false,
    },
    swapOptionUnit: [],
    logo: require("../../../assets/images/coin/usdt.svg"),
  },
  [WALLET_4]: {
    btnSwap: {
      show: true,
      disable: false,
    },
    btnDeposit: {
      show: true,
      disable: false,
    },
    btnWithdraw: {
      show: false,
      disable: false,
    },
    btnTransfer: {
      show: false,
      disable: false,
    },
    swapOptionUnit: [],
    logo: require("../../../assets/images/coin/xrp.svg"),
  },
  [WALLET_5]: {
    btnSwap: {
      order: 2,
      show: true,
      disable: false,
    },
    btnDeposit: {
      order: 0,
      show: true,
      disable: false,
    },
    btnWithdraw: {
      show: false,
      disable: false,
    },
    btnTransfer: {
      order: 1,
      show: true,
      disable: false,
    },
    swapOptionUnit: [],
    logo: require("../../../assets/images/coin/eur.svg"),
  },
  [WALLET_6]: {
    btnSwap: {
      order: 1,
      show: true,
      disable: false,
    },
    btnDeposit: {
      show: false,
      disable: false,
    },
    btnWithdraw: {
      order: 0,
      show: true,
      disable: false,
    },
    btnTransfer: {
      show: false,
      disable: false,
    },
    swapOptionUnit: [],
    // swapOptionUnit: [
    //   {
    //     label: WALLET_5,
    //     value: WALLET_5,
    //     logo: require("../../../assets/images/coin/eur.svg"),
    //   },
    // ],
    logo: require("../../../assets/images/coin/bcd.svg"),
  },
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_PROFILE: {
      return {
        ...state,
        customerCode: payload,
      };
    }
    case GET_PROFILE_SUCCESS: {
      const { wallet: wallets } = payload || { wallet: [] };
      const walletListFilter = orderBy(
        uniqBy(
          cloneDeep(wallets).map((wallet) => {
            const walletDefault = LIST_WALLET_FILTER[wallet.unit];

            /**
             * Swap
             * Override swapOptionUnit default
             */
            const swapOptionUnit = [];
            if (wallet?.swap_send?.length > 0) {
              wallet.swap_send.forEach((unit) => {
                const walletIsSwap = wallets.find((o) => o.unit === unit);
                if (walletIsSwap) {
                  swapOptionUnit.push({
                    label: walletIsSwap.unit,
                    value: walletIsSwap.unit,
                    logo: walletIsSwap.flat,
                  });
                }
              });
            }

            /**
             * Swap
             * swapOptionUnit is empty so Not accept swap => disable it
             */
            if (swapOptionUnit.length === 0) {
              walletDefault.btnSwap.show = false;
            }

            /**
             * Deposit
             * Show when deposit = 1 or transfer = 1
             */
            if (wallet?.deposit === 1 || wallet?.transfer === 1) {
              walletDefault.btnDeposit.show = true;
            } else {
              walletDefault.btnDeposit.show = false;
            }

            /**
             * Transfer
             */
            if (wallet?.transfer === 1) {
              walletDefault.btnTransfer.show = true;
            } else {
              walletDefault.btnTransfer.show = false;
            }

            /**
             * Withdraw
             */
            if (wallet?.withdraw === 1) {
              walletDefault.btnWithdraw.show = true;
            } else {
              walletDefault.btnWithdraw.show = false;
            }

            if (walletDefault) {
              return {
                ...wallet,
                ...walletDefault,
                swapOptionUnit,
              };
            } else {
              return {
                ...wallet,
              };
            }
          }),
          "unit"
        ),
        ["order"],
        ["asc"]
      );

      return {
        ...state,
        profileData: {
          ...payload,
          walletListFilter,
        },
      };
    }
    case UPDATE_STATE_USER: {
      updateUserInfoToLocal(payload);
      return {
        ...state,
        profileData: payload,
      };
    }
    case GET_VERIFY_CODE_SUCCESS: {
      return {
        ...state,
        verifyCode: payload,
      };
    }

    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        openEditProfileDialog: false,
        profileData: payload,
      };
    }

    case OPEN_UPDATE_PROFILE_DIALOG: {
      return {
        ...state,
        openEditProfileDialog: payload,
      };
    }

    case OPEN_UPDATE_PASSWORD_DIALOG: {
      return {
        ...state,
        isOpenUpdatePasswordDialog: payload,
      };
    }

    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isOpenUpdatePasswordDialog: false,
      };
    }

    //Update Email
    case OPEN_UPDATE_EMAIL_DIALOG: {
      return {
        ...state,
        isOpenEmailDialog: payload,
      };
    }

    case UPDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        isOpenEmailDialog: false,
      };
    }

    // Bank
    case OPEN_BANK_DIALOG:
      return {
        ...state,
        isOpenBankDialog: payload,
      };

    case GET_LIST_BANK_MANAGER_SUCCESS:
      return {
        ...state,
        banks: payload,
      };
    case CREATE_BANK_MANAGER_SUCCESS: {
      return {
        ...state,
        banks: [...state.banks, payload],
        isOpenBankDialog: false,
      };
    }
    case UPDATE_BANK_MANAGER_SUCCESS: {
      const banks = map(state.banks, (item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        banks,
        isOpenBankDialog: false,
      };
    }
    case DELETE_BANK_MANAGER_SUCCESS: {
      const banks = state.banks.filter((item) => item.id !== payload);
      return {
        ...state,
        banks,
      };
    }
    default:
      return { ...state };
  }
};
