import "./style.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import { Row, Col } from "antd";
import { get, cloneDeep } from "lodash";
import { listToTree } from "helpers/CommonHelper";
import { renderLogo } from "helpers/Ranks";
import { getReferral, getCountCommission, openUserHistoryModal, getReferalDetail } from "modules/networks/redux/actions";
import TreeCollapse from "modules/networks/components/TreeCollapse";
import UserHistoryModal from "../../components/UserHistoryModal";

export const REFERRAL = "referrals";

const Index = () => {
  const dispatch = useDispatch();
  const [treeDataRender, setTreeData] = useState([]);
  const [showTree, setShowTree] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);
  const { listRef, listFetchApi } = useSelector((state) => state.networks);
  const levelUser = get(userInfo, "levelInfoCommissions.level", 0);

  const handleCollapse = (key, isCall = false) => {
    if (listFetchApi[key] || !isCall) return;

    dispatch(
      getReferral({
        customer_id: key,
        type: REFERRAL,
      })
    );
  };

  const handleCustomer = (userAuth) => {
    let infoUser = {
      full_name: `${userAuth?.first_name || ""} ${userAuth?.last_name || ""}`,
      email: userAuth?.email || "",
      username: userAuth?.username || "",
      id: userAuth?.id || 0,
      pocket_active: userAuth?.pocket_active || 0,
    };

    handleOpenModel(infoUser);
  };

  const handleOpenModel = (item) => {
    const { id } = item;
    const query = {
      page: 1,
      limit: 10,
      customer_id: id,
    };
    dispatch(getReferalDetail(query));
    dispatch(openUserHistoryModal({ value: true, data: item }));
  };

  /**
   * Get data
   */
  useEffect(() => {
    const params = {
      type: REFERRAL,
      customer_id: userInfo.id,
      isRefresh: true,
    };
    dispatch(getReferral(params));
    dispatch(getCountCommission());
  }, [userInfo, dispatch]);

  /**
   * Handle tree data
   */
  useEffect(() => {
    const data = listToTree(cloneDeep(listRef));
    setTreeData(data);
  }, [listRef]);

  console.log(treeDataRender);

  if (!userInfo) return <></>;
  return (
    <>
      <section className="your-refs">
        <Row gutter={30}>
          <Col className="your-refs-wrapper" md={24} xs={24} sm={24}>
            <div className="tree-container your-refs-tree">
              <section className="management-ref">
                <Row gutter={10}>
                  <Col className="" span={24}>
                    <div className="full-name-customer color-primary">
                      <div style={{ whiteSpace: "nowrap" }}>
                        <span onClick={() => setShowTree(!showTree)} className="icon-plus">
                          {!showTree ? "+" : "-"}
                        </span>
                        <span onClick={() => handleCustomer(userInfo)}>{userInfo?.username || ""}</span>
                      </div>
                      <div className="ref-level">
                        <img
                          title={`Level ${levelUser}`}
                          width="45"
                          height="45"
                          src={renderLogo(levelUser, +userInfo?.levelInfoAchievement?.level, userInfo?.levelInfoPackage?.level)}
                          alt="icon"
                          onClick={() => setShowTree(!showTree)}
                        />
                      </div>
                    </div>
                  </Col>
                  {showTree && (
                    <Col className="your-refs-list" span={24}>
                      <TreeCollapse data={treeDataRender} handleCollapse={handleCollapse} handleOpenModel={handleOpenModel} />
                    </Col>
                  )}
                </Row>
              </section>
            </div>
          </Col>
        </Row>
      </section>
      <UserHistoryModal />
    </>
  );
};

export default injectIntl(Index);
