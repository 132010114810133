import "./style.scss";
import React from "react";
import { injectIntl } from "react-intl";
import { Card, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import ButtonMain from "commons/components/Buttons/ButtonMain";

const Index = ({
  intl,
  title,
  className = "",
  onClick = () => undefined,
  handleInterest = () => undefined,
  textAlign = "center",
}) => {
  return (
    <>
      <Card className={`${className} card-item-current`} style={{ textAlign: textAlign }}>
        <ButtonMain onClick={onClick} className="btn-custom">
          {title}
        </ButtonMain>
        <div className="interest-his mt-5">
          <ArrowRightOutlined />
          <Button type="text" color="#fff" onClick={handleInterest}>
            {intl.formatMessage({ id: "Interest.history" })}
          </Button>
        </div>
      </Card>
    </>
  );
};

export default injectIntl(Index);
