import React, { useMemo } from "react";
import { Form, Select, AutoComplete } from "antd";
import { get, map } from "lodash";

import "./styles.scss";

const { Item } = Form;
const { Option } = Select;

const AutoCompleteCustom = (props) => {
  const {
    name = "",
    label = "",
    rules = [],
    options = [],
    optionProps = {},
    formItemProps = {},
    className = "",
    placeholder = "Please chose one option",
    onSearch = () => {},
    onSelect = () => {},
    ...inputProps
  } = props;
  const renderOptions = useMemo(() => {
    if (options && get(options, "length") > 0) {
      return map(options, (item, index) => {
        return (
          <Option {...optionProps} key={`select-${name}-${index}`} value={JSON.stringify(item)}>
            {get(item, "label")}
          </Option>
        );
      });
    }
    return <></>;
  }, [options, name, optionProps]);

  return (
    <Item name={name} label={label} rules={rules} {...formItemProps}>
      <AutoComplete
        onSelect={(value) => onSelect(value)}
        onSearch={onSearch}
        placeholder={placeholder}
        className={`form-select ${className}`}
        {...inputProps}
      >
        {renderOptions}
      </AutoComplete>
    </Item>
  );
};

export default AutoCompleteCustom;
