import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input } from "antd";
import "./styles.scss";
import WrapperModal from "../WrapperModal";
import { PATTERN } from "commons/constants/pattern";
import { openActiveCodeModal, submitActiveCode } from "modules/application/redux/actions";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";

const ActiveModal = ({ intl, onLoadListPackagesActive }) => {
  const dispatch = useDispatch();
  const [formActive] = Form.useForm();
  const { toggle } = useSelector((state) => state.application.modalActiveCode);

  const handleClose = () => {
    dispatch(openActiveCodeModal({ toggle: false, data: null }));
  };

  const handleConfirm = () => {
    formActive.validateFields().then((fields) => {
      executeRecaptcha((token_captcha) => {
        dispatch(
          submitActiveCode(
            { ...fields, token_captcha },
            (result) => {
              handleClose();
              onLoadListPackagesActive();
              window.open(result.data.path_contract, "_blank");
            },
            () => {
              // handleClose()
            }
          )
        );
      });
    });
  };

  return (
    <WrapperModal
      title="Active Code!"
      visible={toggle}
      destroyOnClose
      maskClosable={false}
      className={"modal-active-code"}
      handleOk={handleConfirm}
      handleCancel={handleClose}
      footer="Confirm!"
    >
      <Form preserve={false} name="normal_login" form={formActive} className="">
        <div className="label-form">
          <label>Shared Code:</label>
        </div>
        <Form.Item
          name="public_code"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "Please enter code",
              }),
            },
            {
              pattern: PATTERN.code,
              message: intl.formatMessage({
                id: "Code is not match",
              }),
            },
          ]}
        >
          <Input name="share_code" size="large" />
        </Form.Item>
        <div className="label-form">
          <label>Private Code:</label>
        </div>
        <Form.Item
          name="private_code"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "Please enter code",
              }),
            },
            {
              pattern: PATTERN.code,
              message: intl.formatMessage({
                id: "Code is not match",
              }),
            },
          ]}
        >
          <Input size="large" name="amount" />
        </Form.Item>
      </Form>
    </WrapperModal>
  );
};

export default injectIntl(React.memo(ActiveModal));
