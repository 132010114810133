import { TRON, USDTTRON } from "commons/constants";
import * as types from "./constants";

const initialState = {
  items: [],
  viewItem: {
    type: null,
    data: null,
  },
  alert: {
    success: false,
    fail: false,
    data: null,
  },
  filterCode: "transaction",
  history: {
    isShow: false,
    type: "ALL",
    currency: "USJ",
  },
  tradingPrice: 1,
  infoPaymentOrder: {},
  walletHistoryList: {
    data: [],
    total: 0,
    perPage: 10,
  },
  openWalletHistoryDrawer: {
    toggle: false,
    data: null,
  },
  walletBcdList: [],
  modalWalletEdit: {
    toggle: false,
    data: null,
  },
};

const OPTION_SHOW_WALLET = [USDTTRON];

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_WALLET_LIST_SUCCESS: {
      return {
        ...state,
        items: [payload].filter((item) => {
          return item?.unit && OPTION_SHOW_WALLET.indexOf(item?.unit) !== -1 && item.network === TRON;
        }),
      };
    }
    case types.VIEW_WALLET_DETAILS_SUCCESS: {
      return {
        ...state,
        viewItem: payload,
      };
    }
    case types.VERIFY_TRACSACTION_SUCCESS:
      return {
        ...state,
        alert: {
          ...state.alert,
          success: payload.value,
          data: payload.data,
        },
      };
    case types.VERIFY_TRACSACTION_FAIL:
      return {
        ...state,
        alert: {
          ...state.alert,
          fail: payload,
        },
      };
    case types.FILTER_TABLE:
      return {
        ...state,
        filterCode: payload,
      };
    case types.HISTORY_MODAL:
      return {
        ...state,
        history: payload,
      };
    case types.SET_TRADING_PRICE:
      return {
        ...state,
        tradingPrice: payload,
      };
    case types.GET_PAYMENT_CODE_SUCCESS:
      return {
        ...state,
        infoPaymentOrder: payload,
      };
    case types.GET_WALLET_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        walletHistoryList: {
          data: payload?.data || [],
          total: payload?.total,
          perPage: payload?.perPage,
        },
      };
    case types.OPEN_WALLET_HISTORY_MODAL:
      return {
        ...state,
        openWalletHistoryDrawer: payload,
      };
    case types.GET_WALLET_BCD_LIST_SUCCESS:
      return {
        ...state,
        walletBcdList: payload,
      };
    case types.OPEN_MODAL_WALLET_EDIT:
      return {
        ...state,
        modalWalletEdit: payload,
      };

    default:
      return { ...state };
  }
};
