import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyTransactionFail, verifyTransactionSuccess } from "../redux/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";

const AlertVerifyTransactionModal = ({ intl }) => {
  const dispatch = useDispatch();
  const { success, fail, data } = useSelector((state) => state.wallets.alert);

  const onClose = () => {
    dispatch(verifyTransactionFail(false));
    dispatch(
      verifyTransactionSuccess({
        value: false,
        data: null,
      })
    );
  };
  return (
    <>
      <SweetAlert
        show={fail}
        error
        showCancel
        showConfirm={false}
        cancelBtnText={intl.formatMessage({
          id: "walletTransaction.verify.close",
        })}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={intl.formatMessage({ id: "walletTransaction.verify.fail" })}
        onCancel={onClose}
        onConfirm={() => {}}
      />
      <SweetAlert
        show={success}
        onConfirm={() => {}}
        success
        showCancel
        showConfirm={false}
        cancelBtnText={intl.formatMessage({
          id: "walletTransaction.verify.close",
        })}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={intl.formatMessage({ id: "walletTransaction.verify.success" })}
        onCancel={onClose}
      >
        {data && <a href={`https://tronscan.org/#/transaction/${data.txid}`}>{data.txid}</a>}
      </SweetAlert>
    </>
  );
};

export default injectIntl(AlertVerifyTransactionModal);
