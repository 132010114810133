import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input } from "antd";
import Tree from "react-d3-tree";
import { get, isEmpty } from "lodash";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./styles.scss";
import { ReactComponent as NodeShapeLevel1 } from "assets/images/level/level-1.svg";
import { ReactComponent as NodeShapeLevel2 } from "assets/images/level/level-2.svg";
import { ReactComponent as NodeShapeLevel3 } from "assets/images/level/level-3.svg";
import { ReactComponent as NodeShapeLevel4 } from "assets/images/level/level-4.svg";
import { ReactComponent as NodeShapeLevel6 } from "assets/images/logo/logo-sapphire.svg";
import { ReactComponent as NodeShapeLevel7 } from "assets/images/logo/logo-ruby.svg";
import { ReactComponent as NodeShapeLevel8 } from "assets/images/logo/logo-president.svg";
import { ReactComponent as NodeShapeLevel9 } from "assets/images/logo/logo-president.svg";
import NodeLabel from "./NodeLabel";
import { isMobile, uuidv4 } from "helpers/CommonHelper";
import { getYourNetworkFromAPI } from "../../redux/saga";
import { getYourNetwork } from "modules/networks/redux/actions";

export const GRAY_NODE_SHAPE = "#d8d8d8";
const MAPPING_LOGO = {
  0: "circle",
  1: NodeShapeLevel1,
  2: NodeShapeLevel2,
  3: NodeShapeLevel3,
  4: NodeShapeLevel4,
  5: NodeShapeLevel6,
  6: NodeShapeLevel7,
  7: NodeShapeLevel8,
  8: NodeShapeLevel9,
};
const renderLogo = (rankValue = 0, level = 0, packageLevel = 0) => {
  if (level === null || level === undefined || packageLevel === null || packageLevel === undefined) return MAPPING_LOGO[0];

  if ((packageLevel === 9 && level >= 9) || (level === 9 && packageLevel >= 9)) {
    return MAPPING_LOGO[8];
  }

  if ((packageLevel === 8 && level >= 8) || (level === 8 && packageLevel >= 8)) {
    return MAPPING_LOGO[7];
  }

  if ((packageLevel === 7 && level >= 7) || (level === 7 && packageLevel >= 7)) {
    return MAPPING_LOGO[6];
  }

  if ((packageLevel === 6 && level >= 6) || (level === 6 && packageLevel >= 6)) {
    return MAPPING_LOGO[5];
  }

  if (rankValue > 4) return MAPPING_LOGO[4];

  return MAPPING_LOGO[rankValue];
};

const w = 80;
const h = 80;
const VALUE_ZOOM = 0.1;
const MAX_VALUE_ZOOM = 1.5;

const KEY_VALUE_PRESS = "control";

function searchTree(element, matchingTitle) {
  if (element.uuid == matchingTitle) {
    return element;
  } else if (element.children != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], matchingTitle);
    }
    return result;
  }
  return null;
}

export const mapit = (node = { data: [] }) => {
  if (!node.data || !node.data.length) return { data: [] };

  if (node.data.length > 0) {
    node.data.map((item, i) => {
      const child = node.data[i];
      item.children = child.data;
      item.customerId = item.id;
      item.name = item?.username || "Empty";
      // item.name = item.full_name ? item.full_name : "Empty";
      item.email = item.email ? item.email : "";
      item.revenue = item.revenue != "" ? item.revenue : "";
      item.uuid = uuidv4();
      item.nodeSvgShape = {
        shape: renderLogo(+item.level_id, +item.level, +item.package_level), // child 3
        shapeProps: {
          width: w + 0,
          height: h + 0,
          x: -40,
          y: -40,
          // color,
          r: 40,
          fill: GRAY_NODE_SHAPE,
        },
      };
      return mapit(child);
    });
  }
};

let toogleZoom = false;

const TreeVanilla = ({ isBinarySponor = false, toggleToLeftToRight }) => {
  const dispatch = useDispatch();
  const treeContainer = useRef(null);

  const { binaryTreeData, auth } = useSelector((state) => ({
    binaryTreeData: state.networks.binaryTreeData || { data: [] },
    auth: state.auth,
  }));

  const [translate, setTranslate] = useState({});
  const [zoomMax] = useState(0.7);
  const [zoom, setZoom] = useState(1);
  const checkIsMobile = isMobile();

  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  const [keyword, setKeyWord] = useState();

  const [dataTree, setDataTree] = useState({
    customerId: auth.userInfo.id,
    name: auth?.userInfo?.username || "n/a",
    email: auth?.userInfo?.email,
    level: auth?.userInfo?.level,
    packageLevel: auth?.userInfo?.package_level,
    revenue: 0,
    isRoot: true, // ROOT ( child 1 )
    nodeSvgShape: {
      // shape: NodeRootShape,renderLogo
      shape: renderLogo(
        get(auth, "userInfo.level_commissions", 0),
        get(auth, "userInfo.levelInfoAchievement.level", 0),
        get(auth, "userInfo.levelInfoPackage.level", 0)
      ), // child 1
      shapeProps: {
        width: w + 0,
        height: h + 0,
        x: -40,
        y: -40,
        // color,
        r: 40,
        fill: GRAY_NODE_SHAPE,
      },
    },
    children: [],
  });

  const mappingDataTree = (data = []) => {
    const rootRevenue = data.reduce((memo, current) => (memo += current?.revenue || 0), 0);
    const result = {
      ...dataTree,
      revenue: rootRevenue,
      children: data.map((item) => {
        item.customerId = item.id;
        item.children = item.children;
        item.name = item.username || "Empty";
        item.email = item.email ? item.email : "";
        item.revenue = item.revenue != "" ? item.revenue : null;
        item.nodeSvgShape = {
          // shape: item.id ? NodeShape : NodeWhiteShape,
          shape: renderLogo(+item.level_id, +item.level, +item.package_level), // child 2
          shapeProps: {
            width: w + 0,
            height: h + 0,
            x: -40,
            y: -40,
            // color,
            r: 40,
            fill: GRAY_NODE_SHAPE,
          },
        };

        return item;
      }),
    };
    setDataTree(result);
  };

  const mappingDataTreeSearch = (treeData = []) => {
    const data = treeData.data;
    const result = {
      ...dataTree,
      customerId: treeData.customerId,
      name: treeData.name || "n/a",
      email: treeData.email,
      revenue: treeData.revenue,
      children: data.map((item) => {
        item.customerId = item.id;
        item.children = item.children;
        item.name = item.username || "Empty";
        item.email = item.email ? item.email : "";
        item.revenue = item.revenue != "" ? item.revenue : null;
        item.nodeSvgShape = {
          // shape: item.id ? NodeShape : NodeWhiteShape,
          shape: renderLogo(+item.level_id, +item.level, +item.package_level), // child 2
          shapeProps: {
            width: w + 0,
            height: h + 0,
            x: -40,
            y: -40,
            // color,
            r: 40,
            fill: GRAY_NODE_SHAPE,
          },
        };
        return item;
      }),
    };

    setDataTree(result);
  };

  const renderDataTree = (treeData, isSearch = false) => {
    mapit(treeData);
    if (isSearch) {
      mappingDataTreeSearch(treeData, false);
    } else {
      mappingDataTree(treeData.data, false);
    }
  };

  useEffect(() => {
    if (binaryTreeData) renderDataTree(binaryTreeData);
  }, [binaryTreeData]);

  useEffect(() => {
    const { userInfo } = auth;
    if (userInfo?.id) {
      dispatch(getYourNetwork({ customerId: userInfo.id, isBinarySponor }));
    }
  }, [auth, dispatch, isBinarySponor, toggleToLeftToRight]);

  /**
   * Handle UI
   */
  useEffect(() => {
    if (checkIsMobile) {
      setZoom(0.3);
    } else {
      setZoom(1);
    }
  }, [checkIsMobile]);

  const handleZoom = (value) => {
    const number = zoomMax + value;
    if (number > MAX_VALUE_ZOOM || number < VALUE_ZOOM) return;
    setZoom((prev) => prev + value);
  };

  // If pressed key is our target key then set to true
  const downHandler = ({ key }) => {
    if (key && key.toLocaleLowerCase() === KEY_VALUE_PRESS) {
      toogleZoom = !toogleZoom;
      setKeyPressed(toogleZoom);
      handleZoom(-VALUE_ZOOM);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);

  useLayoutEffect(() => {
    const windowInner = window.innerWidth;
    const dimensions = treeContainer.current.getBoundingClientRect();
    if (dimensions) {
      setTranslate({
        // x: (dimensions.width - (windowInner < 1000 ? 0 : 230)) / 2,
        x: (windowInner - (windowInner < 1000 ? 0 : 350)) / 2,
        y: 80,
      });
    }
  }, [treeContainer]);

  const handleOnClick = async (content) => {
    let dataOld = { ...dataTree };
    dataOld.data = dataOld.children;
    const { data, isRoot, customerId, uuid } = content || { data: [] };
    if (isRoot || !customerId || (data && data.length)) return;

    if (!data) {
      const response = await getYourNetworkFromAPI({
        payload: {
          customerId,
          isBinarySponor,
        },
      });
      if (response && response.data && response.data.data) {
        const childData = searchTree(dataOld, uuid);
        childData.data = response.data.data;
        if (keyword) {
          renderDataTree(dataOld, true);
        } else {
          renderDataTree(dataOld);
        }
      }
    }
  };

  const handleOnClick2 = async (content) => {
    let dataOld = { ...dataTree };
    dataOld.data = dataOld.children;

    const { uuid } = content || { data: [] };
    const response = await getYourNetworkFromAPI({
      payload: {
        keyword,
        isBinarySponor: 1,
      },
    });
    if (response && response.data && response.data.data) {
      const childData = searchTree(dataOld, uuid);
      if (response.data.data.length > 2) {
        childData.data = [response.data.data[0], response.data.data[1]];

        let customerFind = response.data.data[2].customerFind;

        dataOld.customerId = customerFind.id;
        dataOld.name = customerFind.username;
        dataOld.email = customerFind.email;
        dataOld.revenue = customerFind.revenue;
      }
      renderDataTree(dataOld, true);
    }
  };

  const onChange = (e) => {
    setKeyWord(e.target.value);
  };

  return (
    <div className="treeVanilla-container your-refs-tree" ref={treeContainer}>
      <Row gutter={30} justify="center">
        <Col className="search-tree" xl={8} lg={12} md={24} xs={24} sm={24}>
          <Input onChange={onChange} placeholder="Search..." onPressEnter={handleOnClick2} />
          <button onClick={handleOnClick2}>search</button>
        </Col>
      </Row>
      <div className="wrapper-zoom">
        <MinusCircleOutlined
          onClick={() => {
            handleZoom(-VALUE_ZOOM);
          }}
        />
        <PlusCircleOutlined
          onClick={() => {
            handleZoom(VALUE_ZOOM);
          }}
        />
      </div>
      {!isEmpty(dataTree) && (
        <Tree
          data={dataTree}
          scaleExtent={{ min: 0.1, max: zoomMax }}
          zoomable={keyPressed}
          zoom={zoom}
          separation={{ siblings: 1, nonSiblings: 1 }} // Sets space between nodes
          allowForeignObjects
          orientation={"vertical"}
          translate={isEmpty(translate) ? undefined : translate}
          nodeLabelComponent={{
            render: <NodeLabel className="custom-node-label" />,
            foreignObjectWrapper: {
              className: "foreign-object-wrapper",
              x: -96,
              y: -123,
              width: 200,
              height: 150,
            },
          }}
          pathFunc={"step"}
          onClick={handleOnClick}
        />
      )}
    </div>
  );
};

export default TreeVanilla;
