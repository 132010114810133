import React, { useEffect } from "react";
import { Col, Row, Form, Button, Input } from "antd";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import "./styles.scss";
import { updateWallet, openModalWalletEdit } from "../../redux/actions";
import RightModal from "commons/components/ModalCommons/rightModal";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";
import { removeEmpty } from "helpers/CommonHelper";

const EditModal = ({ fetchListData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { data, toggle } = useSelector((state) => state.wallets.modalWalletEdit);

  useEffect(() => {
    form.setFieldsValue({
      id: get(data, "id", ""),
      title: get(data, "title", ""),
      address: get(data, "account_address", ""),
    });
  }, [data]);

  const handleClose = () => {
    form.resetFields();
    dispatch(openModalWalletEdit({ toggle: false, data: {} }));
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      executeRecaptcha((token_captcha) => {
        dispatch(
          updateWallet(removeEmpty({ ...values, token_captcha }), () => {
            handleClose();
            fetchListData();
          })
        );
      });
    });
  };

  return (
    <RightModal
      isShow={toggle}
      className="drawer-wallet-detail"
      width={window.innerWidth < 768 ? "100%" : "75%"}
      handleClose={handleClose}
    >
      <div className="drawer-title">{data?.id ? "Update a wallet" : "Create a wallet"}</div>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={16} className="view-product-detail">
          {data?.id && (
            <Col md={24} span={24}>
              <Form.Item
                label="Id"
                name="id"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" label="Id" name="id" placeholder="Input id" readOnly disabled />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Row justify="end" span={24} gutter={16} className="w-100pc">
            <div className="button-custom">
              <Button danger htmlType="submit" type="primary">
                {data?.id ? "Update" : "Create"}
              </Button>
            </div>
          </Row>
        </Row>
      </Form>
    </RightModal>
  );
};

export default injectIntl(EditModal);
