import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import "./styles.scss";
import { generateCode, openGenerateCodeModal, openShareCodeModal } from "modules/package/redux/actions";
import WrapperModal from "commons/components/ModalCommons/WrapperModal";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";

const ModalGeneratePackageCode = () => {
  const dispatch = useDispatch();
  const { toggle, data } = useSelector((state) => state.package.modalGeneratePackageCode);

  const handleOk = () => {
    executeRecaptcha((token_captcha) => {
      dispatch(
        generateCode(
          {
            order_id: data.id,
            token_captcha,
          },
          (resultCode) => {
            dispatch(openShareCodeModal({ toggle: true, data: resultCode }));
          }
        )
      );
    });

    dispatch(openGenerateCodeModal({ toggle: false, data: null }));
  };

  return (
    <WrapperModal
      destroyOnClose
      className="modal-generate-package"
      visible={toggle}
      title={<FormattedMessage id="modal.package.generate.title" />}
      handleOk={() => handleOk(data)}
      handleCancel={() => dispatch(openGenerateCodeModal({ toggle: false, data: null }))}
      footer={<FormattedMessage id="modal.actions.confirmToGenerate" />}
    >
      <div className="modal-package-content">
        <div className="d-flex space-between">
          <div>
            <FormattedMessage id="modal.generate.sharedCode" />:
          </div>
          <div>{data?.public_code || "--"}</div>
        </div>
        <div className="" style={{ color: "#047AE2" }}>
          <FormattedMessage id="modal.generate.note.1" />
        </div>
        <div className="" style={{ color: "#047AE2" }}>
          <FormattedMessage id="modal.generate.note.2" />
        </div>
      </div>
    </WrapperModal>
  );
};

export default injectIntl(ModalGeneratePackageCode);
