// import Cookies from "js-cookie";
import { all, takeLatest, call, put } from "redux-saga/effects";
import * as qs from "query-string";
import { get } from "lodash";

import fetchHelper from "helpers/FetchHelper";
import * as types from "./constants";
import * as actions from "./actions";
import { ROOT_API_URL } from "commons/constants";

function* getListInvestData({ data, callbackSuccess = () => {} }) {
  const res = yield call(requestListInvestListFromApi, data);
  if (get(res, "status") === 200) {
    const data = get(res, "data", []);
    yield put(actions.setListInvestSuccess(data));
    callbackSuccess();
    return;
  }
}

function* getListPackageUpgrade({ data, callbackSuccess = () => {} }) {
  const res = yield call(requestListUpgradeListFromApi, data);
  if (get(res, "status") === 200) {
    const data = get(res, "data", []);
    yield put(actions.setListPackageUpgradeSuccess(data));
    callbackSuccess();
    return;
  }
}

function* onConfirmUpdateInvest({ payload, callbackSuccess }) {
  try {
    const { data } = yield call(confirmUpdateInvest, payload);
    if (get(data, "status_code") === 200) {
      callbackSuccess(data);
      return;
    }
  } catch (error) {}
}

function* getListInvestHistory({ payload }) {
  const res = yield call(requestGetListInvestHistoryFromApi, payload);
  if (get(res, "status") === 200) {
    const data = get(res, "data", []);
    yield put(actions.getListInvestHistorySuccess(data));
    return;
  }
}

function* getReviewTransactionData({ payload }) {
  const res = yield call(getReviewTransactionFromApi, payload);
  if (get(res, "status") === 200) {
    const data = get(res, "data", []);
    yield put(actions.getReviewTransactionSuccess(data?.data));
    return;
  }
}

function* postStakeNow({ payload, callback }) {
  const res = yield call(postStakeNowFromApi, payload);
  if (get(res, "status") === 200) {
    const data = get(res, "data", null);
    callback({ status: true, data: data });
    return;
  }
}

function* getStakeHistory({ payload }) {
  const res = yield call(getStakeHistoryFromApi, payload);
  if (get(res, "status") === 200) {
    const data = get(res, "data", []);
    yield put(actions.getStakeHistorySuccess({ ...data, params: payload }));
    return;
  }
}

function* reStakeNow({ payload, callback }) {
  const res = yield call(reStakeNowFromApi, payload);
  if (get(res, "status") === 200) {
    const data = get(res, "data", null);
    callback({ status: true, data: data });
    return;
  }
}

function* getInterestHistory({ payload }) {
  const res = yield call(getInterestHistoryFromApi, payload);
  if (get(res, "status") === 200) {
    const data = get(res, "data", null);
    yield put(actions.getInterestHistorySuccess(data));
    return;
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_LIST_INVEST, getListInvestData),
    takeLatest(types.GET_LIST_PACKAGE_UPGRAGE, getListPackageUpgrade),
    takeLatest(types.UPDATE_PACKAGE_INVEST, onConfirmUpdateInvest),
    takeLatest(types.GET_LIST_INVEST_HISTORY, getListInvestHistory),
    takeLatest(types.GET_REVIEW_CONFIRM_TRANSACTION, getReviewTransactionData),
    //new
    takeLatest(types.GET_STAKE_HISTORY, getStakeHistory),
    takeLatest(types.POST_STAKE_NOW, postStakeNow),
    takeLatest(types.RESTAKE_NOW, reStakeNow),
    takeLatest(types.GET_INTEREST_HISTORY, getInterestHistory),
  ]);
}

function getInterestHistoryFromApi(payload) {
  const qsString = payload ? qs.stringify(payload) : null;
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-trans/get-interest-history${qsString ? "?" + qsString : ""} `, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function reStakeNowFromApi(payload) {
  const qsString = payload ? qs.stringify(payload) : null;
  return fetchHelper
    .fetch(`${ROOT_API_URL}/stake/re-lock${qsString ? "?" + qsString : ""} `, {
      method: "POST",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function getStakeHistoryFromApi(payload) {
  const qsString = payload ? qs.stringify(payload) : null;
  return fetchHelper
    .fetch(`${ROOT_API_URL}/stake/history${qsString ? "?" + qsString : ""} `, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function postStakeNowFromApi(payload) {
  const qsString = payload ? qs.stringify(payload) : null;
  return fetchHelper
    .fetch(`${ROOT_API_URL}/stake/lock${qsString ? "?" + qsString : ""} `, {
      method: "POST",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function requestListInvestListFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/get-investment`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function requestListUpgradeListFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/list-product-investment`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function confirmUpdateInvest(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/buy/package-investment`, {
      method: "post",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function requestGetListInvestHistoryFromApi(payload) {
  const qsString = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/wallet-trans/list?${qsString}`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp.data,
        status,
      };
    });
}

function getReviewTransactionFromApi(payload) {
  return fetchHelper
    .fetch(
      `${ROOT_API_URL}/buy/investment/review`,
      {
        method: "post",
        body: JSON.stringify(payload),
      },
      { isToastSuccess: false, isToastFailed: false }
    )
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}
