import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { roundNumber } from "helpers/numbers";
import "./styles.scss";

export default function FormatAmount({ amount = 1, rate = 1, prefix = "", suffix = "" }) {
  const [showAmount, setShowAmount] = useState(false);
  useEffect(() => {
    if (!showAmount) {
      const count = setInterval(() => {
        setShowAmount(false);
      }, 8000);
      return () => clearInterval(count);
    }
  }, []);
  return (
    <div className="amount-box">
      <span onClick={() => setShowAmount(!showAmount)}>
        <CurrencyFormat
          value={roundNumber(amount * rate, 8)}
          prefix={prefix}
          suffix={suffix}
          displayType="text"
          thousandSeparator
        />
      </span>
      <div className="tooltip-amount" style={{ display: showAmount ? "block" : "none" }}>
        <CurrencyFormat value={amount * rate} prefix={prefix} suffix={suffix} displayType="text" thousandSeparator />
      </div>
    </div>
  );
}
