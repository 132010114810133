import React from "react";
import { Card } from "antd";
import { injectIntl } from "react-intl";
import "./style.scss";
import FormatAmount from "../FormatAmount";

// const LIST_COLOR = ["#ffffff", "#ff00f9", "#8d40ff", "#007dff", "#08faff", "#0bff6e", "#ff8100", "red", "yellow"];

const LIST_COLOR = [
  {
    level: 6,
    color: "#0bff6e",
  },
  {
    level: 7,
    color: "#ff8100",
  },
  {
    level: 8,
    color: "red",
  },
  {
    level: 9,
    color: "yellow",
  },
];

const DetailStats = ({ intl, index, source, ...props }) => {
  const checkLevel = () => {
    if (index === 0) {
      let color = null;
      LIST_COLOR.map((item) => {
        if (
          (+source.levelPackageBuy === item.level && +source.level >= item.level) ||
          (+source.level === item.level && +source.levelPackageBuy >= item.level)
        ) {
          color = item.color;
        }
      });
      return color;
    }
  };

  return (
    <>
      <Card
        // hoverable
        bordered={false}
        // onClick={onClick}
        className="card-stats-item"
        cover={
          <div
            style={{
              width: "50px",
              height: "50px",
            }}
          >
            {index != 0 || checkLevel() === null || checkLevel() === undefined ? (
              <img className={`card-item-img`} alt="..." src={source.icon} width="100%" />
            ) : (
              <div className="avatar-color" style={{ backgroundColor: checkLevel() }}>
                <img className={`card-item-img`} alt="..." src={require("assets/images/logo/logo.png")} width="100%" />
              </div>
            )}
          </div>
        }
        {...props}
      >
        <div className="ant-card-price" title={source.balance || ""}>
          {isNaN(source.balance) ? source.balance : <FormatAmount amount={source.balance} />}
        </div>
        <div>
          <div className="ant-card-meta-title">{source.title || ""}</div>

          {index !== 0 ? (
            <div className="ant-card-meta-description">{source.description || ""}</div>
          ) : (
            <div className="ant-card-meta-description" style={{ textDecoration: "underline" }}>
              {source.description || ""}
            </div>
          )}
        </div>
        {/* <Meta title={source.title || ""} description={source.description || ""} /> */}
      </Card>
    </>
  );
};

export default injectIntl(DetailStats);
