import * as types from "./constants";

export const openModalSwap = (payload) => ({
  type: types.OPEN_MODAL_SWAP,
  payload,
});
export const openShareCodeModal = (payload) => ({
  type: types.OPEN_SHARE_CODE_MODAL,
  payload,
});
export const openGenerateCodeModal = (payload) => ({
  type: types.OPEN_GENERATE_CODE_MODAL,
  payload,
});

export const getListPackages = (payload) => ({
  type: types.GET_LIST_PACKAGES,
  payload,
});
export const getListPackageSuccess = (payload) => ({
  type: types.GET_LIST_PACKAGES_SUCCESS,
  payload,
});

export const buyPool = (payload, callbackSuccess = null) => ({
  type: types.BUY_POOL,
  payload,
  callbackSuccess,
});

export const getGlobalCurrency = (payload) => ({
  type: types.GET_GLOBAL_CURRENCY,
  payload,
});
export const getGlobalCurrencySuccess = (payload) => ({
  type: types.GET_GLOBAL_CURRENCY_SUCCESS,
  payload,
});

export const buyPackage = (payload, CBSuccess, CBError) => ({
  type: types.BUY_PACKAGE,
  payload,
  CBSuccess,
  CBError,
});

export const sharePackageCode = (payload, CBSuccess, CBError) => ({
  type: types.SHARE_PACKAGE_CODE,
  payload,
  CBSuccess,
  CBError,
});

export const getListPackagesActive = (payload) => ({
  type: types.GET_LIST_ACTIVE,
  payload,
});
export const getListPackagesActiveSuccess = (payload) => ({
  type: types.GET_LIST_ACTIVE_SUCCESS,
  payload,
});

export const getListMyStake = (payload) => ({
  type: types.GET_LIST_MY_STAKE,
  payload,
});
export const getListMyStakeSuccess = (payload) => ({
  type: types.GET_LIST_MY_STAKE_SUCCESS,
  payload,
});

export const generateCode = (payload, CBSuccess, CBError) => ({
  type: types.GENERATE_CODE,
  payload,
  CBSuccess,
  CBError,
});
export const generateCodeSuccess = (payload) => ({
  type: types.GENERATE_CODE_SUCCESS,
  payload,
});

export const onOpenDrawerProfit = (payload) => ({
  type: types.OPEN_DRAWER_PROFIT,
  payload,
});

export const getInterestPackageHistory = (payload) => ({
  type: types.GET_INTEREST_PACKAGE_HISTORY,
  payload,
});
export const getInterestPackageHistorySuccess = (payload) => ({
  type: types.GET_INTEREST_PACKAGE_HISTORY_SUCCESS,
  payload,
});

export const pushDataPackage = (payload) => ({
  type: types.PUSH_DATA_PACKAGE,
  payload,
});

export const getContractOrder = (payload, cbs) => ({
  type: types.GET_CONTRACT_ORDER,
  payload,
  cbs,
});

export const pushDataContractOrder = (payload) => ({
  type: types.PUSH_DATA_CONTRACT_ORDER,
  payload,
});
