import React from "react";
import { injectIntl } from "react-intl";
import "./styles.scss";

const HeaderPage = ({ intl, title, rightNode, ...props }) => {
  return (
    <div className="header-page" {...props}>
      <div className="flex-page">
        <h2 className="header-page-title">{title || ""}</h2>
        <div>{rightNode || ""}</div>
      </div>
    </div>
  );
};

export default injectIntl(HeaderPage);
