import { useEffect } from "react";
import { G_SITE_KEY } from "commons/constants";

export function executeRecaptcha(CBSuccess, CBError) {
  try {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(G_SITE_KEY, { action: "submit" })
        .then((token_captcha) => {
          CBSuccess && CBSuccess(token_captcha);
        })
        .catch((error) => {
          CBError && CBError(error);
        });
    });
  } catch (error) {
    CBError && CBError(error);
  }
}

export function useConfigRecaptcha() {
  /**
   * @Googlerecapcha
   * @param
   * @G_SITE_KEY string
   */
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${G_SITE_KEY}`, () => {
      console.log("recaptcha config success!");
    });
  }, []);
}
