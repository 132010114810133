import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import "./styles.scss";
import { openErrorModal } from "modules/application/redux/actions";
import WrapperModal from "../WrapperModal";

const ErrorModal = (props) => {
  const dispatch = useDispatch();
  const { toggle, data } = useSelector((state) => state.application.modalError);
  const handleClose = () => {
    dispatch(openErrorModal({ toggle: false, data: null }));
  };
  return (
    <WrapperModal
      destroyOnClose
      visible={toggle}
      title={<FormattedMessage id={"wallet.actions.Wrong"} />}
      handleCancel={handleClose}
      footer={false}
    >
      <div className="modal-content-error">
        <img src={require("assets/images/icon/errorIcon.png")} alt="" width={90} />
        {props?.message ||
          (data?.message && (
            <div className="modal-message-error mt-20">
              <div>{props?.message || ""}</div>
              <div>{data?.message || ""}</div>
            </div>
          ))}
      </div>
    </WrapperModal>
  );
};

export default injectIntl(ErrorModal);
