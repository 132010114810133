import { all, call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";
import * as actions from "./actions";
import { ROOT_API_URL } from "commons/constants";
import {
  CONFIRM_CURRENCY,
  GET_ALL_DETAIL_CHART,
  GET_USJ_INVEST,
  GET_HISTORY_INVEST,
  GET_SETTING_ALL,
  GET_LIST_TRADE_EXCHANGE,
  ACTIVE_AGENT,
  GET_LIST_STAKE_DASHBOARD,
  GET_RATE,
  GET_TOTAL_INFO,
} from "./constants";
import fetchHelper from "helpers/FetchHelper";
import * as qs from "query-string";

function* onConfirmSubscribe({ payload, callbackSuccess }) {
  try {
    const { data } = yield call(confirmSubscribe, payload);
    if (get(data, "status_code") === 200) {
      callbackSuccess();
      return;
    }
  } catch (error) {}
}

function* getAllDetailChart({ payload }) {
  const { day, ...newQStr } = payload;
  try {
    const [sysData, myPVData, UsjInvestData] = yield all([
      call(getTotalPVFromApi, newQStr),
      call(getTotalPunkFromApi, newQStr),
      call(getUsjInvestFromApi, { day: day }),
    ]);
    if (get(sysData, "status") === 200 && get(myPVData, "status") === 200) {
      yield all([
        put(actions.getTotalPVSuccess(sysData.data.data)),
        put(actions.getTotalPunkSuccess(myPVData.data.data)),
        put(actions.getUsjInvestSuccess(UsjInvestData.data.data)),
      ]);
    }
  } catch (error) {}
}

function* getUsjInvest({ payload }) {
  try {
    const { data } = yield call(getUsjInvestFromApi, payload);
    if (get(data, "status_code") === 200) {
      yield put(actions.getUsjInvestSuccess(data.data));
    }
  } catch (error) {}
}

function* getHistoryInvest({ payload }) {
  try {
    const { data } = yield call(getHistoryInvestFromApi, payload);
    if (get(data, "status_code") === 200) {
      const result = {
        data: data?.data?.data || [],
        total: data?.data?.pagination?.total || 0,
      };
      yield put(actions.getInvestHistoryDashboardSuccess(result));
    }
  } catch (error) {}
}

function* getSettingAll({ payload }) {
  try {
    const { data } = yield call(getSettingAllFromApi, payload);
    if (get(data, "status_code") === 200) {
      yield put(actions.getSettingAllSuccess(get(data, "data", {})));
    }
  } catch (error) {}
}

function* getListTradeExchange({ payload }) {
  try {
    const { data } = yield call(getListTradeExchangeFromApi, payload);
    if (get(data, "status_code") === 200) {
      yield put(actions.getListTradeExchangeSuccess(get(data, "data", null)));
    }
  } catch (error) {}
}

function* onActiveAgent({ payload, callbackSuccess }) {
  try {
    const { data } = yield call(activeAgentFromApi, payload);
    if (get(data, "status_code") === 200) {
      callbackSuccess();
      return;
    }
  } catch (error) {}
}

function* getListStake({ payload, callbackSuccess }) {
  try {
    const { data } = yield call(getListStakeApi, payload);
    if (get(data, "status_code") === 200) {
      callbackSuccess(data?.data[0]);
      return;
    }
  } catch (error) {}
}

function* getRate() {
  // try {
  //   const responses = yield all({
  //     // [UG]: call(getRateFromApi, { from_currency: UG, to_currency: VND }),
  //     [USDTTRON]: call(getRateFromApi, {
  //       from_currency: USDTTRON,
  //       to_currency: VND,
  //     }),
  //     // [VND]: call(getRateFromApi, { from_currency: UG, to_currency: VND }),
  //   });
  //   yield put(actions.getRateSuccess(responses));
  // } catch (error) {}
  try {
    const { data } = yield call(getRateFromApi);
    if (get(data, "status_code") === 200) {
      yield put(actions.getRateSuccess(data.data));
      return;
    }
  } catch (error) {}
}

function* getTotalInfo(payload) {
  try {
    const { data } = yield call(getTotalInfoFromApi, payload);
    if (data.status_code === 200) {
      yield put(actions.getTotalInfoSuccess(data.data));
    }
  } catch (error) {}
}

export default function* rootSaga() {
  yield all([
    takeLatest(CONFIRM_CURRENCY, onConfirmSubscribe),
    takeLatest(GET_ALL_DETAIL_CHART, getAllDetailChart),
    takeLatest(GET_USJ_INVEST, getUsjInvest),
    takeLatest(GET_HISTORY_INVEST, getHistoryInvest),
    takeLatest(GET_SETTING_ALL, getSettingAll),
    takeLatest(GET_LIST_TRADE_EXCHANGE, getListTradeExchange),
    takeLatest(ACTIVE_AGENT, onActiveAgent),
    takeLatest(GET_LIST_STAKE_DASHBOARD, getListStake),
    takeLatest(GET_RATE, getRate),
    takeLatest(GET_TOTAL_INFO, getTotalInfo),
  ]);
}

function getTotalInfoFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/dashboard/info-total`, {
      method: "GET",
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}

function getListStakeApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/product/list-stake`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function activeAgentFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/lock-stake`, {
      method: "post",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function getListTradeExchangeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/list-trade-exchanges`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function getSettingAllFromApi(qString) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/setting/all`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function getUsjInvestFromApi(qString) {
  const qStr = qs.stringify(qString);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/report/total-usj-invest?${qStr}`, {
      method: "GET",
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function getTotalPVFromApi(qString) {
  const qStr = qs.stringify(qString);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/report/total-pv?${qStr}`, {
      method: "GET",
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}

function getTotalPunkFromApi(qString) {
  const qStr = qs.stringify(qString);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/report/total-punk?${qStr}`, {
      method: "GET",
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}

function confirmSubscribe(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/buy/pocket-active`, {
      method: "post",
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}

function getHistoryInvestFromApi(payload) {
  const qStr = qs.stringify(payload);
  return fetchHelper
    .fetch(`${ROOT_API_URL}/get-history-invest?${qStr}`, {
      method: "GET",
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}

function getRateFromApi() {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/dashboard/rate-price`, {
      method: "GET",
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }));
}
