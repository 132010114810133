import { combineReducers } from "redux";

import LoadingStateReducer from "./status/reducer";
import { ApplicationReducer } from "modules/application";
import { AuthReducer } from "modules/auth";
import { DashboardReducer } from "modules/dashboard";
import { WalletReducer } from "modules/wallets";
import { NetworksReducer } from "modules/networks";
import { PackageReducer } from "modules/package";
import { TransactionReducer } from "modules/transactions";
import { SettingReducer } from "modules/settings";
import { ProfileReducer } from "modules/profile";
import { CommissionReducer } from "modules/commissions";
import { StakingReducer } from "modules/staking";

export default combineReducers({
  application: ApplicationReducer,
  auth: AuthReducer,
  dashboard: DashboardReducer,
  wallets: WalletReducer,
  loadingState: LoadingStateReducer,
  networks: NetworksReducer,
  package: PackageReducer,
  transactions: TransactionReducer,
  settings: SettingReducer,
  profile: ProfileReducer,
  commission: CommissionReducer,
  staking: StakingReducer,
});
