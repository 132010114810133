import React, { useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Row, Col } from "antd";
import "./styles.scss";
import HeaderPage from "commons/components/HeaderPage";
import ActiveCode from "commons/components/ActiveCode";
import MyPackageTransaction from "../components/MyPackageTransaction";
import PackageCarousel from "../components/PackageCarousel";
import ListPackageActive from "../components/ListPackageActive";
import { CACHING_DATA, USDTTRON } from "commons/constants";
import ModalActiveCode from "commons/components/ModalCommons/ModalActiveCode";
import ModalGeneratePackageCode from "../components/ModalGeneratePackageCode";
import ModalSharePackageCode from "../components/ModalSharePackageCode";
import ErrorModal from "commons/components/ModalCommons/ErrorModal";
import SuccessModal from "commons/components/ModalCommons/SuccessModal";

const TOOGLE_CALLBACK_GET_LIST = "TOOGLE_CALLBACK_GET_LIST";
CACHING_DATA[TOOGLE_CALLBACK_GET_LIST] = false;
const setToogleCallBack = () => {
  return (CACHING_DATA[TOOGLE_CALLBACK_GET_LIST] = !CACHING_DATA[TOOGLE_CALLBACK_GET_LIST]);
};

const Packages = () => {
  let toggleCallBackGetList = CACHING_DATA[TOOGLE_CALLBACK_GET_LIST];

  const [currencyData] = useState(USDTTRON);

  return (
    <div className="package-container">
      <HeaderPage title={<FormattedMessage id="Packages" />} rightNode={<ActiveCode theme="yellow" />} />
      <Row gutter={[0, 40]}>
        <Col span={24}>
          <PackageCarousel onReLoadData={setToogleCallBack} />
        </Col>
        <Col span={24}>
          <ListPackageActive
            showFilter={false}
            showActiveCode={true}
            currency={currencyData}
            isCall={toggleCallBackGetList}
            theme="yellow"
          />
        </Col>
        <Col span={24}>
          <MyPackageTransaction showFilter={false} currency={currencyData} isCall={toggleCallBackGetList} />
        </Col>
      </Row>
      <ModalActiveCode onLoadListPackagesActive={setToogleCallBack} />
      <ModalGeneratePackageCode />
      <ModalSharePackageCode onReLoadData={setToogleCallBack} />
      <SuccessModal />
      <ErrorModal />
    </div>
  );
};

export default injectIntl(Packages);
