import * as types from "./constants";
import { DEFAULT_LANGUAGE } from "language/config";

export const setLanguage = ({ language = DEFAULT_LANGUAGE, reload = false }) => ({
  type: types.SET_LANGUAGE,
  payload: language,
  reload: reload,
});

export const openSuccessModal = (payload) => ({
  type: types.OPEN_SUCCESS_MODAL,
  payload,
});
export const openErrorModal = (payload) => ({
  type: types.OPEN_ERROR_MODAL,
  payload,
});

export const openActiveCodeModal = (payload) => ({
  type: types.OPEN_ACTIVE_CODE_MODAL,
  payload,
});
export const submitActiveCode = (payload, CBSuccess, CBError) => ({
  type: types.SUBMIT_ACTIVE_CODE,
  payload,
  CBSuccess,
  CBError,
});

export const setRateFromFirebase = (payload) => ({
  type: types.SET_RATE_FROM_FIREBASE,
  payload,
});
