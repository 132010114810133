import React from "react";
import { useDispatch } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import "./styles.scss";
import { openActiveCodeModal } from "modules/application/redux/actions";

const ActiveCode = ({ intl, onClick, ...props }) => {
  const dispatch = useDispatch();
  const handleActiveCode = () => {
    dispatch(openActiveCodeModal({ toggle: true, data: null }));
  };
  return (
    <div className="active-code-wrapper" {...props}>
      <span className="active-code-btn" onClick={handleActiveCode}>
        <FormattedMessage id="transaction.title.right" />
      </span>
    </div>
  );
};

export default injectIntl(ActiveCode);
