import { all, call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";
import * as types from "./constants";
import fetchHelper from "helpers/FetchHelper";
import { ROOT_API_URL } from "commons/constants";
import { openErrorModal, openSuccessModal } from "./actions";

function submitActiveCodeFromApi(payload) {
  return fetchHelper
    .fetch(
      `${ROOT_API_URL}/order/active-invest`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
      {
        isToastSuccess: false,
        isToastFailed: false,
      }
    )
    .then(([resp, status]) => {
      return {
        data: resp,
        status,
      };
    });
}
function* submitActiveCode({ payload, CBSuccess, CBError }) {
  const { data } = yield call(submitActiveCodeFromApi, payload);
  if (get(data, "status_code") === 200) {
    CBSuccess && CBSuccess(data);
    yield put(openSuccessModal({ toggle: true, data }));
    return;
  }
  CBError && CBError(data);
  yield put(openErrorModal({ toggle: true, data }));
  return;
}

export default function* root() {
  yield all([takeLatest(types.SUBMIT_ACTIVE_CODE, submitActiveCode)]);
}
