import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { roundNumber } from "helpers/numbers";
import CurrencyFormat from "react-currency-format";
// import FirebaseService from "services/firebase";

const BOX_1 = "EUR";

const TokenBalance = () => {
  const { walletListFilter } = useSelector((state) => state.profile.profileData);
  const [tokenFilter, setTokenFilter] = useState();

  useEffect(() => {
    if (walletListFilter && walletListFilter.length > 0) {
      const wallet1 = walletListFilter.find((ele) => ele.unit === BOX_1);
      // const usdtWallet = walletListFilter.find((ele) => ele.unit === BOX_2);
      setTokenFilter({
        [BOX_1]: wallet1,
      });
    }
  }, [walletListFilter]);

  return (
    <>
      <div className="token-block">
        <div className="token-item mr-15">
          <img
            style={{ marginRight: "5px" }}
            className="token-logo"
            src={require("assets/images/icon/wallet.svg")}
            height={22}
            alt=""
          />
          &nbsp;
          <span className="token-balance">
            <CurrencyFormat
              value={roundNumber(get(tokenFilter, `[${BOX_1}].amount`, 0), 8)}
              displayType="text"
              thousandSeparator
            />
          </span>
          &nbsp;
          <span className="token-currency">{get(tokenFilter, `[${BOX_1}].unit`, "")}</span>
        </div>
        {/* <div className="token-item mr-10">
          <img className="token-logo" src={require("assets/images/icon/icon-wallets.png")} height={22} alt="" />
          &nbsp;
          <span className="token-balance">{fixedNumber(tokenFilter?.[USDTTRON].amount || 0)}</span>
          &nbsp;
          <span className="token-currency">{tokenFilter?.[USDTTRON].unit.split("").slice(0, 3).join("") || ""}</span>
        </div> */}
      </div>
    </>
  );
};

export default TokenBalance;
