import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Drawer } from "antd";
import { FormattedMessage } from "react-intl";
import cn from "classnames";

import "./styles.scss";
import Language from "commons/components/Language";
import ProfileAccount from "commons/components/ProfileAccount";
import { ROUTE } from "commons/constants";
const { Sider } = Layout;

const rootSubMenuKeys = ["_myTransaction"];
const CLASS_ACTIVE_MENU = "ant-menu-item-selected";

const itemMenu = [
  {
    id: 1,
    icon: require("assets/images/iconMenu/dashboard.svg"),
    title: <FormattedMessage id={"menu.dashboard"} />,
    link: ROUTE.DASHBOARD,
    subMenu: [],
  },
  {
    id: 2,
    icon: require("assets/images/iconMenu/wallet.svg"),
    title: <FormattedMessage id={"menu.wallets"} />,
    link: ROUTE.WALLETS,
    subMenu: [],
  },
  {
    id: 3,
    icon: require("assets/images/iconMenu/packages.svg"),
    title: <FormattedMessage id={"menu.packages"} />,
    link: ROUTE.PACKAGES,
    subMenu: [],
  },
  {
    id: 4,
    icon: require("assets/images/iconMenu/referral.svg"),
    title: <FormattedMessage id={"menu.referral"} />,
    link: `${ROUTE.NETWORKS}${ROUTE.NETWORKS_REFERRALS}`,
    subMenu: [],
  },
  {
    id: 5,
    icon: require("assets/images/iconMenu/setting.svg"),
    title: <FormattedMessage id={"menu.setting"} />,
    link: `${ROUTE.SETTING}${ROUTE.SETTING_PERSONAL}`,
    subMenu: [],
  },
];

const MainSideBar = ({ visible, openKeys, current, setOpenKeys, setCurrentTabSidebar, isMobile, showDrawer, setShowDrawer }) => {
  const { pathname } = useLocation();

  const handleClickMenu = (e) => {
    setCurrentTabSidebar(e.key);
    setShowDrawer(false);
  };

  const renderClassActiveMenu = (value) => {
    if (pathname.indexOf(value) !== -1 && value !== "/") {
      return CLASS_ACTIVE_MENU;
    }
    return "";
  };

  const handleOpenChangeMenu = (openKeysList) => {
    const latestOpenKey = openKeysList.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
      return;
    }

    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const renderMenu = (showContent) => {
    return (
      <>
        {isMobile && (
          <>
            <Link to={ROUTE.HOME}>
              <img
                className="mobile-logo"
                width={150}
                src={require("assets/images/logo/logo-full.svg")}
                alt={process.env.REACT_APP_NAME}
              />
            </Link>
            <div className="mt-30 mb-20" style={{ paddingLeft: "18px" }}>
              <ProfileAccount className="custom-account" isMobile={isMobile} />
            </div>
            <hr className="line-sidebar" />
          </>
        )}
        {!isMobile && (
          <div>
            <Link to={ROUTE.DASHBOARD} style={{ cursor: "pointer" }}>
              <img
                className="desktop-logo"
                width={150}
                src={require("assets/images/logo/logo-full.svg")}
                alt={process.env.REACT_APP_NAME}
              />
            </Link>
          </div>
        )}
        <Menu
          className="sidebar-menu-custom"
          mode="inline"
          onClick={handleClickMenu}
          onOpenChange={handleOpenChangeMenu}
          openKeys={openKeys}
          selectedKeys={[current]}
          style={{ marginTop: isMobile ? 20 : "55px" }}
        >
          {itemMenu.map((ele) => {
            const isHttpLink = ele.link?.startsWith("http");
            const TagLink = isHttpLink ? "a" : Link;
            const propsLink = isHttpLink ? { href: ele.link } : { to: ele.link };
            return (
              <Menu.Item
                className={`${cn("menu-item-custom", showContent && "menu-item-hide", renderClassActiveMenu(ele.link))} ${
                  ele.isBottom ? "bottom-menu" : ""
                }`}
                key={ele.link}
              >
                <div className="menu-sub_title">
                  <TagLink className="link-sidebar" {...propsLink}>
                    {!showContent && (
                      <div className="d-flex-center">
                        {ele.icon && (
                          <div className="icon-menu">
                            <img src={ele.icon} alt={ele.link} width={24} />
                          </div>
                        )}
                        {ele.title && <div className="menu-text ml-10">{ele.title}</div>}
                      </div>
                    )}
                  </TagLink>
                </div>
              </Menu.Item>
            );
          })}
        </Menu>
        <Language className="mt-50" />
      </>
    );
  };
  if (isMobile) {
    return (
      <Drawer visible={showDrawer} placement="left" width={230} className="mode-drawer-menu" onClose={() => setShowDrawer(false)}>
        {renderMenu(false)}
      </Drawer>
    );
  }
  return (
    <>
      <Sider className="sidebar-custom" collapsed={visible} collapsible trigger={null} width={230}>
        {renderMenu(false)}
      </Sider>
    </>
  );
};

export default MainSideBar;
