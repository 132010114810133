import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import "./styles.scss";
import FilterCustom from "commons/components/FilterCustom";
import ItemListing from "commons/components/ItemListing";
import ActiveCode from "commons/components/ActiveCode";
import DrawerHistoryProfit from "../DrawerHistoryProfit";
import { fixedNumber } from "helpers/numbers";
import { pushDataPackage, getListPackagesActive, onOpenDrawerProfit, getContractOrder } from "../../redux/actions";
import { FILTER_HISTORY_DEFAULT, FILTER_HISTORY_MAPPING } from "modules/staking/const";
import { Link } from "react-router-dom";

export const PENDING = "PENDING";
export const VERIFIED = "VERIFIED";

const renderFilter = (value) => {
  const getFilterMapping = FILTER_HISTORY_MAPPING[value];
  if (getFilterMapping) {
    let result = [...FILTER_HISTORY_DEFAULT, ...getFilterMapping];

    return result;
  }
  return [...FILTER_HISTORY_DEFAULT];
};

const TransactionList = ({ intl, isCall, showFilter = true, showActiveCode, currency, theme = "blue" }) => {
  const dispatch = useDispatch();
  const { listPackageActive } = useSelector((state) => state.package);
  const { dataContractOder } = useSelector((state) => state.package);

  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(getListPackagesActive(searchParams));
  }, [dispatch, isCall, searchParams]);

  const pagination = useMemo(
    () => ({
      total: get(listPackageActive, "pagination.total", 0),
      current: searchParams.pageIndex,
      onChange: (page, pageRecord) => {
        setSearchParams({
          ...searchParams,
          page,
          limit: pageRecord,
        });
      },
    }),
    [searchParams, listPackageActive]
  );

  const handleChange = (value) => {
    setSearchParams({
      ...searchParams,
      type: value,
      pageIndex: 1,
      pageSize: 10,
    });
  };

  const handelViewContract = (value) => {
    dispatch(
      getContractOrder({ order_id: value?.id }, (result) => {
        window.open(result.path_contract, "_blank");
      })
    );
  };

  const renderStatus = (value, record) => {
    switch (value) {
      case 1:
      case "1":
        return (
          <span className="share-code-tag pointer">
            <FormattedMessage id="transaction.list.shareCode" />
          </span>
        );
      case 2:
      case "2":
        return (
          <div className="box-activated-tag">
            <span className="activated-tag pointer" onClick={() => dispatch(onOpenDrawerProfit({ toggle: true, data: record }))}>
              <FormattedMessage id="transaction.list.activated" />
            </span>
            <img
              className="download-contract"
              onClick={() => handelViewContract(record)}
              src={require("assets/images/icon/download-pdf.png")}
            />
          </div>
        );
      default:
        break;
    }
  };
  const columns = [
    {
      title: <FormattedMessage id="Package" />,
      key: "public_code",
      dataIndex: "public_code",
      width: 130,
    },
    {
      title: <FormattedMessage id="Status" />,
      key: "status",
      dataIndex: "status",
      width: 130,
      render: (text, data) => renderStatus(text, data),
    },
    {
      title: <FormattedMessage id="Value" />,
      key: "total_pay",
      dataIndex: "total_pay",
      width: 130,
      render: (text, record) => (
        <span>
          {get(record, "action") !== "OUT" ? "+" : "-"}
          {fixedNumber(text)}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="Mining" />,
      key: "mining",
      dataIndex: "mining",
      width: 130,
      render: (text) => fixedNumber(text, "", 3),
    },
    {
      title: <FormattedMessage id="Date" />,
      key: "date",
      dataIndex: "date",
      width: 130,
      render: (text) => `${text || 0}/1200 days`,
    },
    {
      title: <FormattedMessage id="Time" />,
      key: "created_at",
      dataIndex: "created_at",
      width: 130,
      align: "right",
      // render: (text) => (text ? formatDate(text) : ""),
    },
  ];

  return (
    <div className="transaction-list-active">
      {showFilter && <FilterCustom data={renderFilter(currency)} defaultValue="ALL" handleChange={handleChange} intl={intl} />}
      <ItemListing
        id="tran-his"
        theme={theme}
        title={
          <>
            <span className="transaction-title">
              <FormattedMessage id="transaction.title.myActivePackages" />
            </span>
            {showActiveCode && <ActiveCode />}
          </>
        }
        showHeader={true}
        scroll={{ x: 800 }}
        columns={columns}
        // onRow={(record, rowIndex) => ({
        //   onclick: event =>
        // })}
        customPagination={pagination}
        dataSource={get(listPackageActive, "data")}
      />
      <DrawerHistoryProfit />
    </div>
  );
};

export default injectIntl(TransactionList);
