import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import ItemListing from "commons/components/ItemListing";
import { getTransactionList } from "modules/transactions/redux/actions";
import "./styles.scss";
import FilterCustom from "commons/components/FilterCustom";
import ActiveCode from "commons/components/ActiveCode";
import { fixedNumber } from "helpers/numbers";
import { getListMyStake, openGenerateCodeModal, onOpenDrawerProfit } from "modules/package/redux/actions";
import { FILTER_HISTORY_DEFAULT, FILTER_HISTORY_MAPPING } from "modules/staking/const";

export const PENDING = "PENDING";
export const VERIFIED = "VERIFIED";

const renderFilter = (value) => {
  const getFilterMapping = FILTER_HISTORY_MAPPING[value];
  if (getFilterMapping) {
    let result = [...FILTER_HISTORY_DEFAULT, ...getFilterMapping];

    return result;
  }
  return [...FILTER_HISTORY_DEFAULT];
};

const TransactionList = ({ intl, isCall, showFilter = true, showActiveCode, currency }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.profile.profileData);
  const { listMyStake } = useSelector((state) => state.package);

  const [searchParams, setSearchParams] = useState({
    limit: 10,
    page: 1,
    customer_id: userInfo?.id,
  });

  useEffect(() => {
    if (searchParams.customer_id) {
      dispatch(getListMyStake(searchParams));
    }
  }, [dispatch, isCall, searchParams]);

  useEffect(() => {
    if (userInfo.id) {
      setSearchParams((prev) => ({ ...prev, customer_id: userInfo?.id }));
    }
  }, [userInfo]);

  useEffect(() => {
    setSearchParams(searchParams);
    dispatch(getTransactionList(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    const data = {
      page: 1,
      currency,
    };
    setSearchParams((prev) => ({ ...prev, ...data }));
    dispatch(getTransactionList(data));
  }, [dispatch, isCall, currency]);

  const handleChange = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      page: 1,
      limit: 10,
      type: value,
    }));
  };

  const pagination = useMemo(
    () => ({
      total: get(listMyStake, "pagination.total", 0),
      current: searchParams.page,
      onChange: (page, limit) => {
        setSearchParams((prev) => ({
          ...prev,
          page,
          limit,
        }));
      },
    }),
    [searchParams, listMyStake]
  );

  const renderStatus = (value, record) => {
    switch (value) {
      case 1:
      case "1":
        return (
          <span
            className="share-code-tag pointer"
            onClick={() => dispatch(openGenerateCodeModal({ toggle: true, data: record }))}
          >
            <FormattedMessage id="transaction.list.shareCode" />
          </span>
        );
      case 2:
      case "2":
        return (
          <span className="activated-tag pointer" onClick={() => dispatch(onOpenDrawerProfit({ toggle: true, data: record }))}>
            <FormattedMessage id="transaction.list.activated" />
          </span>
        );
      default:
        break;
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="Package" />,
      key: "public_code",
      dataIndex: "public_code",
      width: 130,
    },
    // {
    //   title: "",
    //   dataIndex: "action",
    //   key: "action",
    //   width: 30,
    //   render: (text) => (
    //     <span>{text === "OUT" ? <span className="amount-out">OUT</span> : <span className="amount-in">IN</span>}</span>
    //   ),
    // },
    {
      title: <FormattedMessage id="Status" />,
      key: "status",
      dataIndex: "status",
      width: 130,
      render: (text, data) => renderStatus(text, data),
    },
    {
      title: <FormattedMessage id="Value" />,
      key: "total_pay",
      dataIndex: "total_pay",
      width: 130,
      render: (text, record) => (
        <span>
          {get(record, "action") !== "OUT" ? "+" : "-"}
          {fixedNumber(text)}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="Mining" />,
      key: "mining",
      dataIndex: "mining",
      width: 130,
      render: (text) => fixedNumber(text, "", 3),
    },
    {
      title: <FormattedMessage id="Date" />,
      key: "date",
      dataIndex: "date",
      width: 130,
      render: (text) => `${text || 0}/1200 days`,
    },
    {
      title: <FormattedMessage id="Time" />,
      key: "created_at",
      dataIndex: "created_at",
      width: 130,
      align: "right",
      // render: (text) => (text ? formatDate(text) : ""),
    },
  ];

  return (
    <div className="transaction-list-package">
      {showFilter && <FilterCustom data={renderFilter(currency)} defaultValue="ALL" handleChange={handleChange} intl={intl} />}
      <ItemListing
        id="tran-his"
        title={
          <>
            <span className="transaction-title">
              <FormattedMessage id="transaction.title.myPackages" />
            </span>
            {showActiveCode && <ActiveCode />}
          </>
        }
        showHeader={true}
        customTableClassName={"transaction-table"}
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={get(listMyStake, "data", [])}
        customPagination={pagination}
      />
    </div>
  );
};

export default injectIntl(TransactionList);
