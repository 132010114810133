import produce from "immer";
import { OPTIONS_LANG } from "language/config";
import { CACHE_LANGUAGE, DEFAULT_LANGUAGE } from "language/config";
import * as types from "./constants";

// const languageBrowser = navigator.language.split(/[-_]/)[0];
const languageLocal = localStorage.getItem(CACHE_LANGUAGE);
const language = languageLocal || DEFAULT_LANGUAGE || "en";
const selectLanguage = OPTIONS_LANG.find(({ id }) => id === language);

const initialState = {
  activeLanguage: selectLanguage ? selectLanguage.id : DEFAULT_LANGUAGE,
  OPTIONS_LANG: OPTIONS_LANG,
  modalSuccess: {
    toggle: false,
    data: null,
  },
  modalError: {
    toggle: false,
    data: null,
  },
  modalActiveCode: {
    toggle: false,
    data: null,
  },
  rateFromFirebase: [],
};

export default (state = initialState, action) => {
  const { payload } = action;
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SET_LANGUAGE: {
        localStorage.setItem(CACHE_LANGUAGE, payload || DEFAULT_LANGUAGE);
        draft.activeLanguage = payload || DEFAULT_LANGUAGE;
        break;
      }
      case types.OPEN_SUCCESS_MODAL:
        draft.modalSuccess = payload;
        break;
      case types.OPEN_ERROR_MODAL:
        draft.modalError = payload;
        break;
      case types.OPEN_ACTIVE_CODE_MODAL:
        draft.modalActiveCode = payload;
        break;
      case types.SET_RATE_FROM_FIREBASE:
        draft.rateFromFirebase = payload;
        break;
      default:
        return { ...state };
    }
  });
};
