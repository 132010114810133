import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import CurrencyFormat from "react-currency-format";

import ItemListing from "commons/components/ItemListing";
import { INVEST_HISTORY_CURRENCY_PARAMS, INVEST_HISTORY_TYPE_PARAMS } from "commons/constants";
import { roundNumber } from "helpers/numbers";
import { Status } from "modules/transactions/components";
import "./styles.scss";
import FilterCustom from "commons/components/FilterCustom";
import { FILTER_HISTORY_DEFAULT, FILTER_HISTORY_MAPPING } from "modules/staking/const";

const PAGE_SIZE = 10;

const renderFilter = (value) => {
  const getFilterMapping = FILTER_HISTORY_MAPPING[value];
  if (getFilterMapping) {
    return [...FILTER_HISTORY_DEFAULT, ...getFilterMapping];
  }
  return [...FILTER_HISTORY_DEFAULT];
};

const Index = ({ type = INVEST_HISTORY_TYPE_PARAMS, currency = INVEST_HISTORY_CURRENCY_PARAMS, isShowFilter = false }) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState({
    pageIndex: 1,
    pageSize: PAGE_SIZE,
    currency: currency,
    type: type,
  });

  useEffect(() => {
    setSearchParams(searchParams);
    // dispatch(getStakeHistory(searchParams));
  }, [dispatch, searchParams]);

  const { stakeHistory } = useSelector((state) => state.staking);
  const pagination = useMemo(
    () => ({
      total: get(stakeHistory, "pagination.total", 0),
      current: searchParams.pageIndex,
      onChange: (page, pageRecord) => {
        setSearchParams({
          ...searchParams,
          pageIndex: page,
          pageSize: pageRecord,
        });
      },
    }),
    [searchParams, stakeHistory]
  );

  const handleChange = (value) => {
    setSearchParams({
      ...searchParams,
      type: value,
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: <FormattedMessage id="transaction.type" />,
      dataIndex: "type_buy",
      key: "type_buy",
    },
    {
      title: <FormattedMessage id="transaction.amount" />,
      dataIndex: "total_pay",
      key: "total_pay",
      render: (text, record) => (
        <span>
          {/* {get(record, "type_buy") === "STAKE" ? "+" : "-"} */}
          {text !== null ? <CurrencyFormat value={roundNumber(text || 0)} displayType="text" thousandSeparator /> : 0}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="transaction.status" />,
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div className="text-left">
            <span className="status">
              <Status value={"SUCCESS"} />
              {/* {renderButtonVerifyAuthy(record)} */}
            </span>
            {/* <span className="button-cancel">{renderButtonCancel(record)}</span> */}
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="transaction.createAt" />,
      dataIndex: "created_at",
      key: "created_at",
      width: 30,
      align: "right",
      // render: (text) => (text ? formatDate(text) : ""),
    },
  ];

  return (
    <div className="transaction-list">
      {isShowFilter && <FilterCustom data={renderFilter(currency)} defaultValue={"ALL"} handleChange={handleChange} />}

      <ItemListing
        id="stakee-his"
        showHeader={true}
        title={<FormattedMessage id="Stake.history" />}
        customTableClassName={"transaction-table"}
        columns={columns}
        customPagination={pagination}
        dataSource={get(stakeHistory, "data", [])}
        defaultPageSize={PAGE_SIZE}
      />
    </div>
  );
};

export default injectIntl(Index);
